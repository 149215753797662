import { useNotificationRecipients } from "hooks/useNotificationRecipients";
import { useTenancyData } from "hooks/useTenancyData";
import { useParams } from "react-router-dom";
import { TRecipientFormValues } from "./RecipientFormSchema";
import {
  createNotificationRecipient,
  deleteNotificationRecipient,
  updateNotificationRecipient,
} from "./RecipientFormRequest";
import { RecipientForm } from "./RecipientForm";

function stringInsert(string: string, insertions: Array<{ index: number; value: string }>) {
  insertions.sort((a, b) => b.index - a.index);
  if (!string) return null;
  const stringArray = string.split("");
  insertions.forEach(({ index, value }) => {
    stringArray.splice(index, 0, value);
  });

  return stringArray.join("");
}

// Do all the data manipulation in this layer and pass to form.
export function RecipientFormContainer({ template }: { template: "Create" | "Edit" }) {
  const params = useParams(); // :recipientId

  const { data: tenancyData } = useTenancyData();
  const tenancyId = tenancyData?.[0].tenancyId;
  const { data: recipientData, isLoading: loadingRecipients } = useNotificationRecipients(tenancyId, params.id);
  const recipientId = recipientData?.recipientId;

  // Add spaces back into the mobile number so the form doesn't complain.
  const mobileNormed = stringInsert(recipientData?.mobileNumber, [
    { index: 4, value: " " },
    { index: 7, value: " " },
  ]);

  // Set the default values for the form
  const defaultValues = {
    name: recipientData?.name || "",
    mobileNumber: mobileNormed || "",
    email: recipientData?.email || "",
    confirmEmail: recipientData?.email || "",
    isEmailEnabled: recipientData?.isEmailEnabled || false,
    isSmsEnabled: recipientData?.isSmsEnabled || false,
  };

  const formAction = {
    Create: (formData: TRecipientFormValues) => createNotificationRecipient(formData, tenancyId),
    Edit: (formData: TRecipientFormValues) =>
      updateNotificationRecipient(formData, defaultValues, tenancyId, recipientId),
  }[template];

  return loadingRecipients ? (
    <div>loading</div>
  ) : (
    <RecipientForm
      template={template}
      formAction={formAction}
      defaultValues={defaultValues}
      deleteAction={() => deleteNotificationRecipient(tenancyId, recipientId)}
    />
  );
}
