import React, { ButtonHTMLAttributes } from "react";
import "./styles.scss";

type THyperLinkButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  content: string;
  hidden?: boolean;
  resize?: boolean;
  buttonRef?: React.RefObject<any>;
};

export const HyperLinkButton = ({
  content,
  hidden,
  resize = false,
  buttonRef,
  ...htmlButtonProps
}: THyperLinkButtonProps) => {
  return (
    <button
      type="button"
      ref={buttonRef}
      className={`${!hidden && `hyperlink-button-resize__${resize}`}`}
      hidden={hidden}
      {...htmlButtonProps}
    >
      {content}
    </button>
  );
};
