import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchSubscriptionHistory } from "services/api/observability-service";
import type { SubscriptionHistoryData, TdnErrorResponse } from "types";
import type { ServiceHistoryFormData as FormData } from "./types";

export const useServiceHistory = (subscriptionId: string, active: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<SubscriptionHistoryData>();

  const params = useMemo(
    () => ({
      period: searchParams.get("period") || "fifteenMin",
      dateTimeFrom: searchParams.get("dateTimeFrom") || dayjs().subtract(1, "minute").subtract(1, "day").toISOString(),
      dateTimeTo: searchParams.get("dateTimeTo") || dayjs().subtract(1, "minute").toISOString(),
    }),
    [searchParams]
  );

  const { mutate, error, isPending, submittedAt } = useMutation<
    SubscriptionHistoryData,
    AxiosError<TdnErrorResponse>,
    FormData
  >({
    mutationFn: (formData) => fetchSubscriptionHistory({ ...formData, subscriptionId }),
    onSuccess: (data) => setData(data),
    onError: () => setData(undefined),
  });

  const fetchData = useCallback(
    (formData: FormData) => {
      if (!active) return;
      // Calculate the period here
      const dateX = dayjs(formData.dateTimeFrom);
      const dateY = dayjs(formData.dateTimeTo);
      const dateDiff = dateY.diff(dateX, "days", true);

      let period = "fifteenMin";
      if (dateDiff < 1) period = "fifteenMin";
      if (dateDiff > 2) period = "hourly";
      if (dateDiff > 5) period = "daily";
      //   if (dateDiff > 60) period = "monthly";

      setSearchParams({ ...formData, period });
      mutate({ ...formData, period });
    },
    [mutate, setSearchParams, active]
  );

  useEffect(() => {
    // If subscription is active and subscription id is present.
    if (active && !data?.series && subscriptionId) {
      // If there is no data, and we're not currently fetching.
      if (!data?.series) {
        fetchData(params);
      }
    }
  }, [data?.series, subscriptionId, fetchData, params, active]);

  const lastFetched = submittedAt ? dayjs(submittedAt).format("MMMM D YYYY HH:mm a") : undefined;

  return { data, error, isPending, params, fetchData, lastFetched };
};
