import { useQueries, useQuery } from "@tanstack/react-query";
import { fetchServiceAreaData } from "services/api/account-service";
import { fetchAABUtilizationBySectorID } from "services/api/observability-service";
import { useTenancyData } from "hooks/useTenancyData";
import dayjs from "dayjs";

type Kpi = { timestamp: string; value: number };

type ReducedMetrics = {
  dlThroughput: Kpi[];
  dlVolume: Kpi[];
  ulThroughput: Kpi[];
  ulVolume: Kpi[];
};

type SectorData = {
  sectorId: string;
  threshold: number;
  hasExceededTP: boolean;
} & ReducedMetrics;

type DataKey = keyof ReducedMetrics & {};

// Data fetching layer comes first. This fetches the raw data and returns it!
export const useSectorMetrics = () => {
  const { data: tenantData } = useTenancyData();
  const serviceArea = tenantData ? tenantData[0].serviceArea[0].serviceAreaId : undefined; // extract the first Service Area from tenancy.

  // Fetch the service area data containing the sectors.
  const { data: serviceAreaData } = useQuery({
    queryKey: ["service-area", serviceArea],
    queryFn: () => fetchServiceAreaData(serviceArea),
    enabled: !!serviceArea,
    refetchOnWindowFocus: false,
  });

  // Define a query array for all sectors, to be passed into useQueries hook
  const dateTimeFrom = dayjs.utc().subtract(28, "days").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const dateTimeTo = dayjs.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const queries = serviceAreaData
    ? serviceAreaData.sectors.map((sector) => ({
        queryKey: ["post", sector.sectorName],
        queryFn: () => fetchAABUtilizationBySectorID(sector.sectorName, dateTimeFrom, dateTimeTo),
        staleTime: Infinity,
        enabled: !!serviceAreaData,
      }))
    : [];

  // Execute the queries.
  const results = useQueries({ queries: queries });
  const isPending = results.some(({ isPending }) => isPending);
  // const hasErrors = results.some(({ isError }) => isError);

  // We need to refine the data here because we need service area MBPS
  // Once all the queries have completed, process the results.

  let sectorArray: SectorData[] = [];
  let hasExceededTP = false;

  if (!isPending) {
    // Loop over all the responses.
    for (const response of results) {
      // Figure out how to handle errors correctly.
      if (response.error) {
        continue; // GOTO next loop
      }

      if (response.data && serviceAreaData) {
        const sector = serviceAreaData.sectors.find(({ sectorName }) => sectorName === response.data.sectorId);

        if (!sector) continue; // skip this if there is no sectorThreshold.

        const threshold = sector.AAB_Mbps;

        // Series here may be empty!
        const reducedMetrics = response.data.series.reduce(
          (acc, curr) => {
            const { timestamp, throughput, volume } = curr;

            const downLinkThroughputMbps = throughput.downLink;
            const downLinkVolumeMbps = volume.downLink;
            const upLinkThroughputMbps = throughput.upLink;
            const upLinkVolumeMbps = volume.upLink;
            if (threshold <= downLinkThroughputMbps || threshold <= upLinkThroughputMbps) hasExceededTP = true;

            acc.dlThroughput.push({ timestamp, value: downLinkThroughputMbps });
            acc.dlVolume.push({ timestamp, value: downLinkVolumeMbps });
            acc.ulThroughput.push({ timestamp, value: upLinkThroughputMbps });
            acc.ulVolume.push({ timestamp, value: upLinkVolumeMbps });

            return acc;
          },
          { dlThroughput: [], dlVolume: [], ulThroughput: [], ulVolume: [] } as ReducedMetrics
        );

        sectorArray.push({
          sectorId: response.data.sectorId,
          threshold: sector.AAB_Mbps,
          hasExceededTP,
          ...reducedMetrics,
        });
      }
    }
  }

  // Sort the results
  sectorArray.sort((a, b) => a.sectorId.localeCompare(b.sectorId));

  return sectorArray;
};

// Need a function to create the chart series.
export function generateMultiBarWithScatterSeries(sectorsData: SectorData[], dataKey: DataKey) {
  const plotType = dataKey.includes("ul") ? "Uplink" : "Downlink";

  const mostRecentMetricForSector = sectorsData.map((sector) => {
    const sectorMetrics = sector[dataKey];
    if (sectorMetrics.length === 0)
      return { threshold: sector.threshold, value: 0, timestamp: new Date().toISOString() };
    return {
      threshold: sector.threshold,
      value: sectorMetrics[sectorMetrics.length - 1].value,
      timestamp: sectorMetrics[sectorMetrics.length - 1].timestamp,
    };
  });

  const mappa = mostRecentMetricForSector.reduce(
    (acc, { threshold, value, timestamp }) => {
      const exceeded = value >= threshold;
      acc.available.push(exceeded ? 0 : Number((threshold - value).toFixed(2)));
      acc.used.push(exceeded ? threshold : value);
      acc.exceeded.push(exceeded ? Number((value - threshold).toFixed(2)) : 0);
      acc.timestamps.push(new Date(timestamp).getTime());
      return acc;
    },
    { available: [], used: [], exceeded: [], timestamps: [] } as {
      available: number[];
      used: number[];
      exceeded: number[];
      timestamps: number[];
    }
  );

  const seriesData: Highcharts.SeriesOptionsType[] = [
    {
      type: "bar",
      name: `${plotType} bandwidth used`,
      color: "#0064d2",
      pointWidth: 12,
      legendIndex: -2,
      legendSymbol: "lineMarker",
      data: mappa.used,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "bar",
      name: `${plotType} bandwidth available`,
      color: "#b2e0fd",
      pointWidth: 12,
      legendIndex: -3,
      legendSymbol: "lineMarker",
      data: mappa.available,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "bar",
      name: `${plotType} throughput exceeding subscription`,
      color: "#d93923",
      pointWidth: 12,
      legendIndex: -1,
      legendSymbol: "lineMarker",
      data: mappa.exceeded,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "scatter",
      name: "Subscribed amount",
      legendIndex: -4,
      legendSymbol: "rectangle",
      color: "black",
      marker: {
        symbol: "barmarks",
        lineWidth: 3,
        lineColor: "black",
      },
      data: sectorsData.map(({ threshold }) => threshold),
    },
    {
      type: "bar",
      visible: false,
      data: mappa.timestamps,
      showInLegend: false,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
  ];

  return seriesData;
}

export function generatePercentageMultiBarSeries(sectorsData: SectorData[], dataKey: DataKey) {
  const plotType = dataKey.includes("ul") ? "Uplink" : "Downlink";

  const mostRecentMetricForSector = sectorsData.map((sector) => {
    const sectorMetrics = sector[dataKey];
    if (sectorMetrics.length === 0)
      return { threshold: sector.threshold, value: 0, timestamp: new Date().toISOString() };
    return {
      threshold: sector.threshold,
      value: sectorMetrics[sectorMetrics.length - 1].value,
      timestamp: sectorMetrics[sectorMetrics.length - 1].timestamp,
    };
  });

  const percentages = mostRecentMetricForSector.reduce(
    (acc, { threshold, value, timestamp }) => {
      const percentage = Math.round((value / threshold) * 100);
      const exceeded = percentage >= 100;
      acc.available.push(exceeded ? 0 : Number((100 - percentage).toFixed(2)));
      acc.used.push(exceeded ? 100 : percentage);
      acc.exceeded.push(exceeded ? Number((percentage - 100).toFixed(2)) : 0);
      acc.timestamps.push(new Date(timestamp).getTime());
      return acc;
    },
    { available: [], used: [], exceeded: [], timestamps: [] } as {
      available: number[];
      used: number[];
      exceeded: number[];
      timestamps: number[];
    }
  );

  const seriesData: Highcharts.SeriesOptionsType[] = [
    {
      type: "bar",
      name: `${plotType} bandwidth used`,
      color: "#0064d2",
      pointWidth: 12,
      legendIndex: -2,
      legendSymbol: "lineMarker",
      data: percentages.used,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "bar",
      name: `${plotType} bandwidth available`,
      color: "#b2e0fd",
      pointWidth: 12,
      legendIndex: -3,
      legendSymbol: "lineMarker",
      data: percentages.available,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "bar",
      name: `${plotType} throughput exceeding subscription`,
      color: "#d93923",
      pointWidth: 12,
      legendIndex: -1,
      legendSymbol: "lineMarker",
      data: percentages.exceeded,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "scatter",
      name: "Subscribed amount",
      legendIndex: -4,
      legendSymbol: "rectangle",
      color: "black",
      marker: {
        symbol: "barmarks",
        lineWidth: 3,
        lineColor: "black",
      },
      tooltip: { pointFormat: "{point.y}%" },
      data: sectorsData.map(() => 100),
    },
    {
      type: "bar",
      visible: false,
      data: percentages.timestamps,
      showInLegend: false,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
  ];

  return seriesData;
}
