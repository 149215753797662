import { ModalDialogCriticalDecision } from "@able/react";
import { updateSubscriptionStateById } from "services/api/inventory-service";
import { HyperLinkButton } from "components/ui/Buttons";

import { InfoTooltip } from "components/InfoTooltip";
import { ableUrl } from "utils/constants";
import { getProperty } from "utils/configHelper";
import { isAuthorised } from "utils/tokens";
import { IFilteredInventory } from "pages/services/types";
import { useRef, useState } from "react";
import { StatusMessage } from "./StatusMessage";
import "./styles.scss";
import { queryClient } from "index";

export const ServiceStatus = ({ subscription }: { subscription: IFilteredInventory }) => {
  const focusTargetRef = useRef<HTMLHeadingElement>(null);
  const [openBlockDialogue, setOpenBlockDialogue] = useState(false);
  const [openUnblockDialogue, setOpenUnblockDialogue] = useState(false);
  const [openDeactivateDialogue, setOpenDeactivateDialogue] = useState(false);

  const authorised = isAuthorised("ADMIN");
  const blockEnabled = getProperty("BLOCK_FEATURE_ENABLED", false);
  const unblockEnabled = getProperty("UNBLOCK_FEATURE_ENABLED", false);

  const isServiceTransitioning = subscription.targetState !== "";

  function onStatusChange(statusType: string) {
    updateSubscriptionStateById(subscription, statusType)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["subscriptions"] });

        // Focus the heading of this section after completion.
        if (focusTargetRef.current) {
          focusTargetRef.current.focus();
        }
      })
      .catch((e) => console.error(e));
  }

  return (
    <div>
      {/* Header/Title */}
      <div className="ss-header">
        <h3 ref={focusTargetRef} tabIndex={-1} style={{ outlineColor: "transparent", margin: "1rem 0" }}>
          Service status
        </h3>
        <InfoTooltip
          id="service-status-tooltip"
          label="Service Status Information"
          description="The state of your current service, can be Active, Blocked or Processing."
        />
        {subscription.subscriptionType === "TDN_HYBRID" && (
          <p
            style={{
              margin: 0,
              padding: "0.35rem 0.5rem",
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              backgroundColor: "#a2bdf8",
            }}
          >
            <i>TDN HYBRID</i>
          </p>
        )}
        {/* Button Section */}
        <div className="ss-button-group">
          {!isServiceTransitioning && subscription.state === "active" && (
            <>
              <HyperLinkButton
                content="Deactivate"
                onClick={() => setOpenDeactivateDialogue(true)}
                disabled={!authorised}
              />
              <HyperLinkButton
                content="Block"
                onClick={() => setOpenBlockDialogue(true)}
                disabled={!blockEnabled || !authorised}
              />
            </>
          )}
          {!isServiceTransitioning && subscription.state === "blocked" && (
            <>
              <HyperLinkButton
                content="Deactivate"
                onClick={() => setOpenDeactivateDialogue(true)}
                disabled={!authorised}
              />
              <HyperLinkButton
                content="Unblock"
                onClick={() => setOpenUnblockDialogue(true)}
                disabled={!unblockEnabled || !authorised}
              />
            </>
          )}
        </div>
      </div>

      {/* Service Message / Info */}
      <StatusMessage state={subscription.state} targetState={subscription.targetState} />

      <ModalDialogCriticalDecision
        title="Block Service?"
        description="Are you sure you want to block this service? Changes may take up to 24 hours and cannot be reversed until completed."
        preferredButtonLabel="Confirm"
        secondaryButtonLabel="Cancel"
        isShowing={openBlockDialogue}
        setHideDialog={() => setOpenBlockDialogue(false)}
        secondaryButtonEvents={{}}
        stackButtonOnVXS={true}
        preferredButtonEvents={{ onClick: () => onStatusChange("BAR") }}
        developmentUrl={ableUrl}
      />

      <ModalDialogCriticalDecision
        title="Unblock Service?"
        description="Are you sure you want to unblock this service? Changes may take up to 24 hours and cannot be reversed until completed."
        preferredButtonLabel="Confirm"
        secondaryButtonLabel="Cancel"
        isShowing={openUnblockDialogue}
        setHideDialog={() => setOpenUnblockDialogue(false)}
        preferredButtonEvents={{ onClick: () => onStatusChange("UNBAR") }}
        developmentUrl={ableUrl}
        secondaryButtonEvents={{}}
        stackButtonOnVXS={true}
      />

      <ModalDialogCriticalDecision
        title="Deactivate Service?"
        description="Are you sure you want to deactivate this service? Changes may take up to 24 hours and cannot be reversed until completed."
        preferredButtonLabel="Confirm"
        secondaryButtonLabel="Cancel"
        isShowing={openDeactivateDialogue}
        setHideDialog={() => setOpenDeactivateDialogue(false)}
        preferredButtonEvents={{ onClick: () => onStatusChange("TERMINATE") }}
        developmentUrl={ableUrl}
        secondaryButtonEvents={{}}
        stackButtonOnVXS={true}
      />
    </div>
  );
};
