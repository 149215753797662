import { MessageInline } from "@able/react";
import { MessageInlineVariants } from "@able/react/dist/MessageInline";
import { AxiosError } from "axios";
import { TdnErrorResponse } from "types";
import { ableUrl } from "utils/constants";

type ErrorMessageProps = {
  error: AxiosError<TdnErrorResponse> | null;
};

export function ErrorMessage({ error }: ErrorMessageProps) {
  if (!error) return null;
  const statusCode = error.response?.status || 0;

  const tdnErrorMsg = error.response?.data?.meta?.message || "Bad request, check parameters and try again.";
  const errorMap: Record<number, { variant: MessageInlineVariants; msg: string }> = {
    400: { variant: "Error", msg: tdnErrorMsg },
    404: { variant: "Information", msg: "No data available for this selection." },
    0: { variant: "Error", msg: "Could not determine error type, please check network tab for more information." },
  };

  const mapValue = errorMap[statusCode];

  return <MessageInline variant={mapValue.variant} description={mapValue.msg} developmentUrl={ableUrl} />;
}
