import { nanoid } from "nanoid";
import { getProperty } from "./configHelper";

export function callAuthService() {
  // Create state and nonce codes, storing them in sessionStorage for idenity login later.
  const state = nanoid();
  const nonce = nanoid();
  sessionStorage.setItem("state", state);
  sessionStorage.setItem("nonce", nonce);

  console.log("Redirecting to Auth service login.");
  const caimenURL = getProperty("CAIMEN_URL", "https://fn1.myid-nonprod.telstra.com");
  const redirectURL = getProperty("REDIRECT_URL", "http://localhost:3000");

  window.location.href =
    caimenURL +
    "/identity/as/authorization.oauth2?response_type=code&client_id=b2b-dedicated-networks&redirect_uri=" +
    redirectURL +
    "/oauthCallback&scope=openid%20b2b%20username%20profile&state=" +
    state +
    "&nonce=" +
    nonce;
}

export function callAuthServiceLogout() {
  sessionStorage.removeItem("id_token");
  sessionStorage.removeItem("refresh_token");

  const caimenUrl = getProperty("CAIMEN_URL", "https://fn1.myid-nonprod.telstra.com");
  const redirectUrl = getProperty("REDIRECT_URL", "http://localhost:3000");
  window.location.href = `${caimenUrl}/identity/idp/startSLO.ping?TargetResource=${redirectUrl}/`;
}
