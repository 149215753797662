import styles from "./styles/loader.module.scss";

type Props = {
  size: number;
};

function Loader({ size }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.loader} style={{ height: size, width: size }} />
    </div>
  );
}

export default Loader;
