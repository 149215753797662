type TPaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const Pagination = ({ currentPage, totalPages, onPageChange }: TPaginationProps) => {
  const firstPage = 1;
  const lastPage = totalPages;
  const pageLimit = 1;

  const getPageNumbers = (): (number | string)[] => {
    let numbers: (number | string)[] = [];

    for (let i = firstPage; i <= lastPage; i++) {
      // Always show first and last page.
      if (i === firstPage || i === lastPage) {
        numbers.push(i);
        continue;
      }

      // Show page numbers near current page.
      if (i >= currentPage - pageLimit && i <= currentPage + pageLimit) {
        numbers.push(i);
        continue;
      }

      // Replace numbers with ... if they're outside the current range.
      if (numbers[numbers.length - 1] !== "...") {
        numbers.push("...");
      }
    }

    return numbers;
  };

  return (
    <nav aria-label="pagination">
      {getPageNumbers().map((value, idx) => (
        <li key={idx} style={{ listStyle: "none", margin: 0, display: "inline-block" }}>
          {typeof value === "number" ? (
            <button
              id={`page-${value}`}
              aria-current={currentPage === value && "page"}
              style={{
                padding: "0.5rem 1rem",
                borderRadius: "4px",
                backgroundColor: currentPage === value ? "#005fcca6" : undefined,
                color: currentPage === value ? "white" : "black",
              }}
              onClick={() => onPageChange(value)}
            >
              <span className="visually-hidden">page</span>
              {value}
            </button>
          ) : (
            <span>{value}</span>
          )}
        </li>
      ))}
    </nav>
  );
};
