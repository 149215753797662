import React, { useEffect, useRef } from "react";
import { useAppStore } from "services/state/store/app-store";

type PageheadingProps = {
  content: string;
} & React.HTMLAttributes<HTMLHeadingElement>;

/**
 * Page heading component used to define a page.
 * It is good practice to add a header to each page for accessibility,
 * as well as add the ability to skip to this content using a skip-link.
 *
 * A PageHeader should only be used once per page layout!
 *
 * @param {string} content content nested within the h1 tag (required).
 * @param {string} className class overrides for the h1 tag (avoid if possible).
 * @param {*} others Additional props for h1 tag (you shouldn't need these).
 * @returns h1 page header (and skip link target).
 */
export const PageHeading = ({ content, ...h1Props }: PageheadingProps) => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const autoFocus = useAppStore((state) => state.autoFocus);

  useEffect(() => {
    if (autoFocus && headingRef.current) {
      headingRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <h1 id="content" className={"skip-link-target" + h1Props.className} tabIndex={-1} ref={headingRef} {...h1Props}>
      {content}
    </h1>
  );
};
