import { MessageInline, Select, Spacing } from "@able/react";
import { SyntheticEvent } from "react";
import { Helmet } from "react-helmet-async";
import { ableUrl } from "utils/constants";
import UtilizationChart from "./SingleSectorUtilizationChart";
import { generateAABChartData, generateHighchartSeries, useSectorMetrics } from "./functions";

export function SingleSectorContainer() {
  const { params, sectorsOptions, series, sectorMbps, isLoading, onSelect } = useSectorMetrics();

  // Generate the series data and / or the highChart series.
  const downlinkSeriesData = generateAABChartData(series?.downlink);
  const uplinkSeriesData = generateAABChartData(series?.uplink);
  const downLinkSeries = generateHighchartSeries(downlinkSeriesData, "Downlink");
  const upLinkSeries = generateHighchartSeries(uplinkSeriesData, "Uplink");

  const exceeded =
    !!downlinkSeriesData.exceeded.reduce((s, a) => s + a[1], 0) ||
    !!uplinkSeriesData.exceeded.reduce((s, a) => s + a[1], 0);

  return (
    <div>
      <Helmet>
        <title>Network Utilisation - Telstra Dedicated Network</title>
      </Helmet>
      <h2>Single sector</h2>

      <Spacing bottom="spacing2x">
        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <MessageInline
            variant="Information"
            developmentUrl={ableUrl}
            description={`AAB utilisation of sectors is calculated as a sustained rolling average over 28 days.`}
          />

          {exceeded ? (
            <MessageInline
              variant="Warning"
              developmentUrl={ableUrl}
              description={`This sector is exceeding the subscribed AAB capacity, which may impact mobile service operation. \n
             Contact us for options to increase your AAB subscription rate for the affected sector.`}
            />
          ) : null}
        </div>
      </Spacing>

      <Select
        id="sector-selector"
        size="Short"
        label="Sector"
        helpText="Select a sector"
        value={params.sector}
        options={sectorsOptions}
        events={{
          onChange: (event: SyntheticEvent<HTMLSelectElement>) => {
            const value = event.currentTarget.value;
            const params = value ? { sector: value } : undefined;
            onSelect(params);
          },
        }}
      />

      <Spacing bottom="spacing3x" />
      {params.sector ? (
        <>
          <h2>{params.sector}</h2>
          <Spacing bottom="spacing1x" />
          <UtilizationChart
            plotType="Downlink"
            chartOptions={{
              loading: isLoading,
              yPlotLine: sectorMbps,
              xAxis: {
                min: new Date(params.dateTimeFrom).getTime(),
                max: new Date(params.dateTimeTo).getTime(),
              },
              title: {
                text: `Total daily Downlink AAB bandwidth used and available for use over the past 28 days`,
              },
              series: downLinkSeries,
              exportData: function (event) {
                event.dataRows.forEach((_row, index, arr) => {
                  if (index === 0) return (arr[index] = ["Date Time", "Used Mbps", "Subscribed Mbps"]);
                  if (series?.downlink[index - 1]) {
                    arr[index][1] = series.downlink[index - 1].value.toString();
                    arr[index][2] = series.downlink[index - 1].threshold.toString();
                  }
                });
              },
            }}
          />
          <UtilizationChart
            plotType="Uplink"
            chartOptions={{
              loading: isLoading,
              yPlotLine: sectorMbps,
              xAxis: {
                min: new Date(params.dateTimeFrom).getTime(),
                max: new Date(params.dateTimeTo).getTime(),
              },
              title: {
                text: `Total daily Uplink AAB bandwidth used and available for use over the past 28 days`,
              },
              series: upLinkSeries,
              exportData: function (event) {
                event.dataRows.forEach((_row, index, arr) => {
                  if (index === 0) return (arr[index] = ["Date Time", "Used Mbps", "Subscribed Mbps"]);
                  if (series?.uplink[index - 1]) {
                    arr[index][1] = series.uplink[index - 1].value.toString();
                    arr[index][2] = series.uplink[index - 1].threshold.toString();
                  }
                });
              },
            }}
          />
        </>
      ) : (
        <p>Select a sector to view data.</p>
      )}
    </div>
  );
}
