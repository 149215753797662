import axios, { AxiosRequestConfig } from "axios";
import { getBasePath } from "services/api/common";
import { Subscription, SubscriptionResponse } from "types";
import { getTokenFromSessionStorage } from "utils";

interface IValidateHybridServiceNumber {
  serviceNumber: string;
  body: { cidn: string };
  options?: AxiosRequestConfig;
}

interface IActivateHybridService {
  body: {
    tenancyId: string;
    subscriptionRef: string;
    serviceTierTo: string;
    serviceNumber: string;
  };
  options?: AxiosRequestConfig;
}

export async function validateHybridServiceNumber({
  serviceNumber,
  body,
  options,
}: IValidateHybridServiceNumber): Promise<SubscriptionResponse> {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  // We need to strip the whitespaces from the service number
  const spacelessServiceNumber = serviceNumber.replace(/\s/g, "");

  const response = await axios({
    method: "POST",
    url: `${basePath}/api/tdn/mica-adapter/v1/hybrid/subscriptions/validate/${spacelessServiceNumber}`,
    data: { ...body },
    headers: {
      authorization: `Bearer ${token}`,
    },
    ...options,
  });
  const data = response.data;
  return data;
}

export async function activateHybridService({ body, options }: IActivateHybridService): Promise<SubscriptionResponse> {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios({
    method: "POST",
    url: `${basePath}/api/tdn/mica-adapter/v1/hybrid/subscriptions/activate`,
    data: { ...body },
    headers: {
      authorization: `Bearer ${token}`,
    },
    ...options,
  });
  const data = response.data;
  return data;
}

export async function deactivateHybridService(
  subscription: Subscription,
  options?: AxiosRequestConfig
): Promise<SubscriptionResponse> {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const body = {
    tenancyId: subscription.tenancyId,
    serviceNumber: subscription.serviceNumber,
    subscriptionRef: subscription.id,
    serviceTier: subscription.serviceTier.value,
  };

  const response = await axios({
    method: "POST",
    url: `${basePath}/api/tdn/mica-adapter/v1/hybrid/subscriptions/deactivate`,
    data: { ...body },
    headers: { authorization: `Bearer ${token}` },
    ...options,
  });
  const data = response.data;
  return data;
}
