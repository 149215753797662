import { useQuery } from "@tanstack/react-query";
import { fetchNotificationRecipients } from "services/api/account-service";

type TErrorResponse = {
  response: {
    status: number;
  };
};

export const useNotificationRecipients = (tenantId: string, notificationRecipientId?: string) => {
  let queryKey = ["tenants", tenantId, "notificationRecipients"];
  if (notificationRecipientId) {
    queryKey.push(notificationRecipientId);
  }

  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetchNotificationRecipients(tenantId, notificationRecipientId),
    retry: (_failureCount, error: TErrorResponse) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!tenantId,
  });
};
