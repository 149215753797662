import { Column, Grid, MessageInline, Spacing } from "@able/react";
import { Chart } from "components/Charts/Chart";
import { tooltipFormatter } from "components/Charts/Tooltips/single-series";
import { NativeDatePicker } from "components/DatePicker/NativeDatePicker";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { MinMaxInfo } from "components/MinMaxInfo";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useOutletContext } from "react-router-dom";
import { Subscription } from "types";
import { ableUrl } from "utils/constants";
import { timeRangeToReadable } from "utils/formatters/date-formatters";
import { formatUnits } from "utils/formatters/network-units";
import PerformanceInformation from "./PerformanceInformation";
import { useServiceHistory } from "./functions";
import type { ServiceHistoryFormData as FormData } from "./types";

type OutletProps = { subscription: Subscription };

function calculateAnalytics(data: number[]) {
  const max = data.reduce((a, b) => Math.max(a, b), -Infinity);
  const average = data.reduce((a, b) => a + b, 0) / data.length;

  return { max, average };
}

export const PerformanceContainer = () => {
  const { subscription } = useOutletContext<OutletProps>();
  const activated = subscription.state !== "pendingActivation"; // This sub has been activated at some time.

  const { data, error, lastFetched, params, isPending, fetchData } = useServiceHistory(
    subscription.subscriptionId,
    activated
  );

  const form = useForm<FormData>({ defaultValues: params });

  // Derived state.
  const duration = data && data?.series.length >= 1 && timeRangeToReadable(params.dateTimeFrom, params.dateTimeTo);
  const period = params.period === "fifteenMin" ? "15 min" : params.period;

  return (
    <div>
      <Helmet>
        <title>Service Performance - Telstra Dedicated Networks</title>
      </Helmet>

      <PerformanceInformation subscription={subscription} />

      <Spacing top="spacing2x" bottom="spacing4x">
        <MessageInline
          variant="Information"
          description="Performance analytics include the activity of this service and may not reflect the actual network capability."
          iconScreenReaderContent="Important service performance information"
          developmentUrl={ableUrl}
        />
      </Spacing>

      {activated ? (
        <form>
          <NativeDatePicker
            callback={(data) => {
              form.setValue("dateTimeFrom", data.dateTimeFrom);
              form.setValue("dateTimeTo", data.dateTimeTo);
              fetchData(form.getValues());
            }}
          />
        </form>
      ) : (
        <MessageInline variant="Warning" developmentUrl={ableUrl}>
          <span>
            Service is not active! Please activate service <Link to="../configuration">here</Link>.
          </span>
        </MessageInline>
      )}

      <Spacing bottom="spacing3x" />
      <ErrorMessage error={error} />
      {data && data.series.length === 0 && (
        <MessageInline
          variant="Attention"
          description={`No data was returned for service ${subscription.serviceNumber}`}
          developmentUrl={ableUrl}
        />
      )}

      <Spacing top="spacing2x" bottom="spacing2x">
        {data && data.series.length > 0 && (
          <Grid>
            <Column cols={3} vmd={4} vsm={12} vxs={12}>
              <h3>Average Downlink Throughput</h3>
              <p>Last fetched at {lastFetched}</p>
              <MinMaxInfo
                data={calculateAnalytics(data.series.map(({ throughput }) => throughput.downLink))}
                units={data.series[0].throughput.units || "kbps"}
                descriptionAvg={`Average downlink throughput in a ${period} period over the past ${duration}`}
                descriptionMax={`Highest downlink throughput in a ${period} period over the past ${duration}`}
              />
            </Column>
            <Column cols={9} vmd={8} vsm={12} vxs={12}>
              <Chart
                chartOptions={{
                  title: { text: undefined, align: "left" },
                  paraTitle: `Average downlink throughput in a ${period} period over the past ${duration}`,
                  isLoading: isPending,
                  series: [
                    {
                      type: "column",
                      name: "Downlink throughput",
                      color: "#0364d2",
                      legendSymbol: "lineMarker",
                      pointWidth: 8,
                      data: data.series.map(({ timestamp, throughput }) => [
                        Date.parse(timestamp),
                        Number(throughput.downLink.toFixed(2)),
                      ]),
                    },
                  ],
                  xAxis: {
                    type: "datetime",
                    min: Date.parse(params.dateTimeFrom),
                    max: Date.parse(params.dateTimeTo),
                  },
                  yAxis: {
                    title: { text: "Throughput" },
                    lineWidth: 1,
                    lineColor: "#757575",
                    gridLineDashStyle: "Dash",
                    gridLineColor: "#707070",
                    labels: {
                      formatter(ctx) {
                        return formatUnits(ctx.value, data.series[0].throughput.units);
                      },
                    },
                  },
                  tooltip: {
                    useHTML: true,
                    formatter(this) {
                      return tooltipFormatter({ ctx: this, units: data.series[0].throughput.units });
                    },
                  },
                  legend: { symbolWidth: 24 },
                }}
              />
            </Column>
            <Column cols={3} vmd={4} vsm={12} vxs={12}>
              <h3>Average Downlink volume</h3>
              <p>Last fetched at {lastFetched}</p>
              <MinMaxInfo
                data={calculateAnalytics(data.series.map(({ volume }) => volume.downLink))}
                units={data.series[0].volume.units || "bytes"}
                descriptionAvg={`Average downlink volume in a ${period} period over the past ${duration}`}
                descriptionMax={`Highest downlink volume in a ${period} period over the past ${duration}`}
              />
            </Column>
            <Column cols={9} vmd={8} vsm={12} vxs={12}>
              <Chart
                chartOptions={{
                  title: { text: undefined, align: "left" },
                  paraTitle: `Average downlink volume in a ${period} period over the past ${duration}`,
                  isLoading: isPending,
                  series: [
                    {
                      type: "column",
                      name: "Downlink volume",
                      color: "#0364d2",
                      legendSymbol: "lineMarker",
                      pointWidth: 8,
                      data: data.series.map(({ timestamp, volume }) => [
                        Date.parse(timestamp),
                        Number(volume.downLink.toFixed(2)),
                      ]),
                    },
                  ],
                  xAxis: {
                    type: "datetime",
                    min: Date.parse(params.dateTimeFrom),
                    max: Date.parse(params.dateTimeTo),
                  },
                  yAxis: {
                    title: { text: "Volume" },
                    lineWidth: 1,
                    lineColor: "#757575",
                    gridLineDashStyle: "Dash",
                    gridLineColor: "#707070",
                    labels: {
                      formatter(ctx) {
                        return formatUnits(ctx.value, data.series[0].volume.units);
                      },
                    },
                  },
                  tooltip: {
                    useHTML: true,
                    formatter(this) {
                      return tooltipFormatter({ ctx: this, units: data.series[0].volume.units });
                    },
                  },
                  legend: { symbolWidth: 24 },
                }}
              />
            </Column>
          </Grid>
        )}
      </Spacing>
    </div>
  );
};
