import { z } from "zod";

export const configurableFieldsSchema = z.object({
  name: z
    .string({ required_error: "Please enter a reference." })
    .min(3, "Your Reference muse contain at least 3 characters.")
    .max(32)
    .regex(new RegExp("^[A-Za-z0-9_#&@ -]*$"), "Valid characters are 'A-Za-z0-9#&@_-'"),
  description: z
    .string({ required_error: "Please enter a description." })
    .min(3, "Your Reference muse contain at least 3 characters.")
    .max(32)
    .regex(new RegExp("^[A-Za-z0-9_#&@ -]*$"), "Valid characters are 'A-Za-z0-9#&@_-'"),
});

export type TConfigFieldsValues = z.infer<typeof configurableFieldsSchema>