import { TFilterValues } from "pages/services/services-container";
import { useRef, useState } from "react";
import "./styles.scss";

type TSearchCombobox = {
  title: string;
  helperText: string;
  onFieldChange: (fieldValue: string) => void;
} & TFilterValues;

// Future improvements
// We can remove the fieldValue and keep the component uncontrolled.
// We can use the inputRef current value instead.
export const SearchCombobox = ({ title, matches, fieldValue, helperText, onFieldChange }: TSearchCombobox) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const clearSearch = () => {
    onFieldChange("");
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  };

  const inputOnChange = (value: string) => {
    if (value !== "") setOpen(true);
    onFieldChange(value);
  };

  return (
    <div className="container">
      <label htmlFor="search-input">{title}</label>
      {helperText && <span id="search-helper-text">{helperText}</span>}
      <div className="combobox">
        <div className="group">
          <input
            id="search-input"
            ref={inputRef}
            role="combobox"
            aria-describedby="search-helper-text"
            aria-autocomplete="list"
            aria-expanded={matches.length > 0 ? true : false}
            aria-controls="search-options"
            placeholder="Search"
            onChange={(e) => inputOnChange(e.target.value)}
            onFocus={() => fieldValue && setOpen(true)}
            onKeyDown={({ key }) => {
              /**
               * Core functionality
               * ArrowDown once opens menu, additional moves focus into UL.
               */
              if (key === "Escape") {
                inputRef.current?.focus();
                setOpen(false);
                setCurrentIndex(-1);
              }

              // If tabbing away close and reset index.
              if (key === "Tab" && fieldValue === "") {
                setOpen(false);
                setCurrentIndex(-1);
              }

              if (key === "ArrowDown") {
                // If menu is closed open it
                if (!open) setOpen(true);

                // Move focus down into list, selecting first item.
                if (open && listRef.current) {
                  const li = listRef.current.children[0] as HTMLLIElement;
                  li.focus();
                  setCurrentIndex(0);
                }
              }
            }}
          />
          {fieldValue !== "" && (
            <button
              className={`${fieldValue !== "" ? "show" : "hide"}`}
              onClick={clearSearch}
              onKeyDown={({ key }) => {
                if (key === "Enter") clearSearch();
                if (key === "Tab") {
                  setOpen(false);
                  setCurrentIndex(-1);
                }
              }}
            >
              <svg
                className={`able-icon able-icon--24`}
                role="img"
                aria-hidden="false"
                aria-label="Clear search"
                focusable="false"
              >
                <use href="/assets/able-sprites.svg#Close"></use>
              </svg>
            </button>
          )}
          <div className="search-icon-container">
            <svg
              className="able-icon able-icon--24"
              role="img"
              aria-hidden="false"
              aria-label="Search"
              focusable="false"
            >
              <use href="/assets/able-sprites.svg#Search"></use>
            </svg>
          </div>
        </div>
        {open && (
          <ul ref={listRef} id="search-options" role="listbox" aria-label="Services">
            {matches.map(({ value }, idx) => (
              <li
                role="option"
                key={idx}
                tabIndex={idx === currentIndex ? 0 : -1}
                aria-selected={idx === currentIndex}
                onClick={() => {
                  onFieldChange(value);
                  if (inputRef.current) {
                    inputRef.current.value = value;
                    inputRef.current.focus();
                  }
                }}
                onKeyDown={({ key, currentTarget }) => {
                  /**
                   * Core functionality
                   * ArrowUp moves focus up, at currentIndex 0, focus moves to input.
                   * ArrowDown moves focus down, wraps back to 0 from bottom.
                   */

                  if (key === "Escape") {
                    inputRef.current?.focus();
                    setOpen(false);
                    setCurrentIndex(-1);
                  }

                  if (key === "ArrowDown") {
                    // Move the focus down through the list. We have to have an currentIndex if focus is on LI.
                    if (currentIndex >= 0 && listRef.current) {
                      const newIndex = currentIndex + 1 >= listRef.current.children.length ? 0 : currentIndex + 1;
                      const li = listRef.current.children[newIndex] as HTMLLIElement;
                      li.focus();
                      setCurrentIndex(newIndex);
                    }
                  }
                  if (key === "ArrowUp") {
                    // ArrowUp from top of list, move focus to input, set index -1.
                    if (currentIndex === 0 && inputRef.current) {
                      inputRef.current.focus();
                      setCurrentIndex(-1);
                    }

                    // Move focus up the list.
                    if (currentIndex > 0 && listRef.current) {
                      const newIndex = currentIndex - 1 < 0 ? listRef.current.children.length - 1 : currentIndex - 1;
                      const li = listRef.current.children[newIndex] as HTMLLIElement;
                      li.focus();
                      setCurrentIndex(newIndex);
                    }
                  }

                  // Tab focus should move away, close and reset index.
                  if (key === "Tab") {
                    setOpen(false);
                    setCurrentIndex(-1);
                  }

                  // Set the input value to selected value. Focus input.
                  if (key === "Enter") {
                    if (inputRef.current) {
                      inputRef.current.value = value;
                      inputRef.current.focus();
                    }
                    onFieldChange(value);
                  }
                }}
              >
                {value}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
