import { ModalDialogCriticalDecision } from "@able/react";
import { InfoTooltip } from "components/InfoTooltip";
import { HyperLinkButton } from "components/ui/Buttons";
import { SectionHeading } from "components/ui/SectionHeading";
import { queryClient } from "index";
import { MutableRefObject, useRef, useState } from "react";
import { updateSubscriptionStateById } from "services/api/inventory-service";
import { deactivateHybridService } from "services/api/mica-service";
import { Subscription } from "types";
import { ableUrl } from "utils/constants";

type TServiceTypeProps = {
  type: "Hybrid" | "Standard";
  subscription: Subscription;
};

export const ServiceType = ({ subscription, type }: TServiceTypeProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState<{
    title: string;
    content: string;
    confirmAction: () => void;
    ref: MutableRefObject<null> | undefined;
  }>({ title: "", content: "", confirmAction: () => {}, ref: undefined });
  const blockRef = useRef(null);
  const unblockRef = useRef(null);
  const removeRef = useRef(null);

  const { state, targetState, id } = subscription;

  return (
    <>
      <SectionHeading headingType="h3" headingContent="Service Type">
        <InfoTooltip
          id="service-type-information"
          description="A Hybrid service can access your Telstra Dedicated Network using your Adaptive Mobility service as a base service."
          label="Service Type Information."
        />
        <div style={{ marginLeft: "auto", display: "flex", gap: "1rem" }}>
          {targetState ? null : (
            <>
              <HyperLinkButton
                id="remove-hybrid"
                buttonRef={removeRef}
                content="Remove"
                onClick={() => {
                  setDialogOpen(true);
                  setDialogOptions({
                    confirmAction: () =>
                      deactivateHybridService(subscription).then(() =>
                        queryClient.invalidateQueries({ queryKey: ["subscription", id] })
                      ),
                    ref: removeRef,
                    title: "Remove Hybrid Service",
                    content:
                      "Are you sure you want to remove this service from your Telstra Dedicated Network? Changes may take up to 24 hours to be complete.",
                  });
                }}
              />
              {state === "active" && (
                <HyperLinkButton
                  id="block-hybrid"
                  buttonRef={blockRef}
                  content="Block"
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogOptions({
                      confirmAction: () =>
                        updateSubscriptionStateById(subscription, "BAR").then(() =>
                          queryClient.invalidateQueries({ queryKey: ["subscription", id] })
                        ),
                      ref: blockRef,
                      title: "Block Hybrid Service",
                      content:
                        "Are you sure you want to block this service from your Telstra Dedicated Network? Changes may take up to 24 hours to be complete.",
                    });
                  }}
                />
              )}
              {state === "blocked" && (
                <HyperLinkButton
                  id="unblock-hybrid"
                  buttonRef={unblockRef}
                  content="Unblock"
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogOptions({
                      confirmAction: () =>
                        updateSubscriptionStateById(subscription, "UNBAR").then(() =>
                          queryClient.invalidateQueries({ queryKey: ["subscription", id] })
                        ),
                      ref: unblockRef,
                      title: "Unblock Hybrid Service",
                      content:
                        "Are you sure you want to unblock this service from your Telstra Dedicated Network? Changes may take up to 24 hours to be complete.",
                    });
                  }}
                />
              )}
            </>
          )}
        </div>
      </SectionHeading>

      <p style={{ marginTop: 0, fontSize: 20 }}>{type}</p>
      <p>
        Selecting 'block' will block the Telstra Dedicated Network add-on from your Adaptive Mobility service. Selecting
        'remove' will remove the Telstra Dedicated Network add-on from your Adaptive Mobility service. Your Adaptive
        Mobility service will not be affected.
      </p>
      <ModalDialogCriticalDecision
        isShowing={dialogOpen}
        preferredButtonEvents={{ onClick: dialogOptions.confirmAction }}
        preferredButtonLabel="Confirm"
        secondaryButtonEvents={{ onClick: () => setDialogOpen(false) }}
        secondaryButtonLabel="Cancel"
        setHideDialog={() => setDialogOpen(false)}
        stackButtonOnVXS={true}
        title={dialogOptions.title}
        description={dialogOptions.content}
        focusOnCloseRef={dialogOptions.ref}
        developmentUrl={ableUrl}
      />
    </>
  );
};
