import dayjs, { ManipulateType, UnitType } from "dayjs";
import { Period, Ranges } from "./types";

export function getPeriodFromDates(startDate: string, endDate: string): Period {
  let unit: UnitType = "day";
  const difference = dayjs(endDate).diff(startDate, unit);
  let period = "fifteenMin" as Period;
  if (unit === "day") {
    if (difference >= 4 && difference <= 7) period = "hourly";
    if (difference > 8) period = "daily";
  }

  return period;
}

export function getPeriodFromRange(range: Ranges) {
  const [duration, unit] = range.split("-") as ManipulateType[];
  const length = parseInt(duration);

  const periodInfo: { period: Period; length: number; unit: ManipulateType } = { period: "fifteenMin", length, unit };

  if (length >= 4 && length <= 7) periodInfo.period = "hourly";
  if (length > 8) periodInfo.period = "daily";
  return periodInfo;
}
