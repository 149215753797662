import { getProperty } from "utils/configHelper";

export const ableUrl = getProperty("ABLE_DEVELOPMENT_URL", "/assets/able-sprites.svg");

export const serviceTierSelectOptions = [
  {
    label: "High (5Mbps/5Mbps)",
    value: "high",
  },
  {
    label: "Medium (1Mbps/1Mbps)",
    value: "medium",
  },
  {
    label: "Low (0.5Mbps/0.25Mbps)",
    value: "low",
  },
  {
    label: "Default (0.2Mbps/best effort)",
    value: "default",
  },
];
