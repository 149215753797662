import axios from "axios";
import { useAppStore } from "services/state/store/app-store";
import { getProperty } from "utils/configHelper";
import { TRecipientFormValues } from "./RecipientFormSchema";
import { compare } from "fast-json-patch";


// POST request to create a new notification recipient
export async function createNotificationRecipient(formData: TRecipientFormValues, tenancyId: string) {
  const token = useAppStore.getState().idToken;
  const basePath = getProperty("BASE_PATH", "https://tdn-portal-dev.dedicatednetworks.telstra.com/api");

  // Remove whitespace from phone number before sending.
  formData.mobileNumber = formData.mobileNumber.replace(/\s/g, "");

  const response = await axios({
    method: "POST",
    url: `${basePath}/api/tdn/account/v1/tenants/${tenancyId}/notification-recipients`,
    headers: { authorization: `Bearer ${token}` },
    data: {
      email: formData.email,
      name: formData.name,
      mobileNumber: formData.mobileNumber,
      isEmailEnabled: formData.isEmailEnabled,
      isSmsEnabled: formData.isSmsEnabled,
    },
  });

  const data = response.data?.data;

  return data;
}

// PATCH request to update an existing notification recipient, uses fast-json-patch.
export async function updateNotificationRecipient(
  formData: TRecipientFormValues,
  defaultValues: TRecipientFormValues,
  tenancyId: string,
  recipientId: string
) {
  const token = useAppStore.getState().idToken;
  const basePath = getProperty("BASE_PATH", "https://tdn-portal-dev.dedicatednetworks.telstra.com/api");

  // Transform the mobileNumber strings, because it's easier to do it here then after.
  defaultValues.mobileNumber = defaultValues.mobileNumber.replace(/\s/g, "")
  formData.mobileNumber = formData.mobileNumber.replace(/\s/g, "")
  // Compare the two data sets
  const operations = compare(defaultValues, formData);
  // Filter out confirmEmail field
  const filteredOps = operations.filter(op => op.path !== "/confirmEmail")

  const response = await axios({
    method: "PATCH",
    url: `${basePath}/api/tdn/account/v1/tenants/${tenancyId}/notification-recipients/${recipientId}`,
    headers: { authorization: `Bearer ${token}` },
    data: filteredOps,
  });

  const data = response.data?.data;

  return data;
}

export async function deleteNotificationRecipient(tenantId: string, recipientId: string) {
  const token = useAppStore.getState().idToken;
  const basePath = getProperty("BASE_PATH", "https://tdn-portal-dev.dedicatednetworks.telstra.com/api");

  const response = await axios({
    method: "DELETE",
    url: `${basePath}/api/tdn/account/v1/tenants/${tenantId}/notification-recipients/${recipientId}`,
    headers: { authorization: `Bearer ${token}` },
  });

  const data = response.data?.data;

  return data;
}
