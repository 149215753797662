import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchTenancyData } from "services/api/account-service";
import { fetchServiceAreaHistory } from "services/api/observability-service";
import { ServiceAreaHistoryData, TdnErrorResponse } from "types";
import { ServiceAreaHistoryFormData as FormData } from "./types";

export const useServiceArea = () => {
  const { data: serviceAreaId, isLoading } = useQuery({
    queryKey: ["tenant"],
    queryFn: fetchTenancyData,
    select: (tenantArray) => tenantArray[0]?.serviceArea?.[0]?.serviceAreaId, // Refine to get serviceArea
  });

  return { serviceAreaId, isLoading };
};

export const useServiceAreaHistory = () => {
  const { serviceAreaId } = useServiceArea();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<ServiceAreaHistoryData>();

  const params = useMemo(
    () => ({
      serviceTier: searchParams.get("serviceTier") || "default",
      dateTimeFrom: searchParams.get("dateTimeFrom") || dayjs().subtract(1, "minute").subtract(1, "day").toISOString(),
      dateTimeTo: searchParams.get("dateTimeTo") || dayjs().subtract(1, "minute").toISOString(),
      period: searchParams.get("period") || "fifteenMin",
    }),
    [searchParams]
  );

  const { mutate, error, isPending, submittedAt } = useMutation<
    ServiceAreaHistoryData,
    AxiosError<TdnErrorResponse>,
    FormData
  >({
    mutationFn: (formData: FormData) => fetchServiceAreaHistory(serviceAreaId, { params: { ...formData } }),
    onSuccess: (data) => setData(data),
    onError: () => setData(undefined),
  });

  const fetchData = useCallback(
    (formData: FormData) => {
      // Calculate the period here
      const dateX = dayjs(formData.dateTimeFrom);
      const dateY = dayjs(formData.dateTimeTo);
      const dateDiff = dateY.diff(dateX, "days", true);

      let period = "fifteenMin";
      if (dateDiff < 1) period = "fifteenMin";
      if (dateDiff > 2) period = "hourly";
      if (dateDiff > 5) period = "daily";
      // if (dateDiff > 8) period = "monthly";

      setSearchParams({ ...formData, period });
      mutate({ ...formData, period });
    },
    [mutate, setSearchParams]
  );

  useEffect(() => {
    if (!data?.series && serviceAreaId) {
      fetchData(params);
    }
  }, [data?.series, serviceAreaId, fetchData, params]);

  const lastFetched = submittedAt ? dayjs(submittedAt).format("MMMM D YYYY HH:mm a") : undefined;

  return { data, error, isPending, params, fetchData, lastFetched };
};
