import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

type Callback = () => void;

export const useOnRouteChange = (callback: Callback): void => {
  const pathRef = useRef<string | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathRef.current !== null && pathRef.current !== pathname) {
      callback();
    }

    pathRef.current = pathname;
  }, [pathname, callback]);
};
