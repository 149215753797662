import * as Highcharts from "highcharts";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

// Add extensions to higcharts here.
Highcharts.SVGRenderer.prototype.symbols.barmarks = function (x: any, y: any, w: any, h: any) {
  return ["M", x + h / 2, y + 1.5 * w, "L", x + h / 2, y - 0.5 * w, "Z"];
};

export default Highcharts;
