import { fetchNewToken } from "services/api/identity-service";
import { decodeToken } from "utils/tokens";

export const createTokenSlice = (set, get) => ({
  tokenInfo: null,
  idToken: null,
  refreshToken: null,
  addTokensToStorage: (idToken, refreshToken) => {
    sessionStorage.setItem("id_token", idToken);
    sessionStorage.setItem("refresh_token", refreshToken);
    get().setTokenInfo();
  },
  setTokenInfo: () => {
    const idToken = sessionStorage.getItem("id_token");
    const refreshToken = sessionStorage.getItem("refresh_token");
    const tokenInfo = decodeToken(idToken);
    set((state) => ({
      ...state,
      tokenInfo,
      idToken,
      refreshToken,
    }));

    // Set the autoRefresh running
    get().setAutoRefresh();
  },
  setAutoRefresh: () => {
    // Remove any old timeouts
    clearTimeout(sessionStorage.getItem("refresh_timeoutId"));
    sessionStorage.removeItem("refresh_timeoutId");

    // Setup new timeouts
    const token = get().idToken;
    const tokenInfo = get().tokenInfo;

    if (token && tokenInfo) {
      const expiry = new Date(tokenInfo.exp * 1000);
      const timeDiff = expiry - Date.now();
      const timeoutId = setTimeout(get().refreshIdToken, timeDiff);
      sessionStorage.setItem("refresh_timeoutId", timeoutId);
    }
  },
  refreshIdToken: async () => {
    const token = get().idToken;
    const refreshToken = get().refreshToken;

    if (!token || !refreshToken) throw new Error("Tokens are missing.");

    try {
      const tokenData = await fetchNewToken();
      const { token } = tokenData;

      // Update the session storage with the new token value.
      get().addTokensToStorage(token, refreshToken);
    } catch (e) {
      console.error(e);
      console.error("Could not refresh token, logging out.");
      get().logout();
    }
  },
});
