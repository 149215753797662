import { ActionButton } from "@able/react";
import { Drawer } from "components/Drawer";
import { Dropdown } from "components/Dropdown";
import { Logo } from "components/Logo";
import { BurgerButton } from "components/ui/Buttons";
import { useOnNavigateOutside } from "hooks/useOnNavigateOutside";
import { useState } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { ableUrl } from "utils/constants";
import "./styles.scss";

function NetworkDropdown() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useOnNavigateOutside<HTMLLIElement>({
    isOpen: menuOpen,
    onNavigateOutside: () => setMenuOpen((s) => !s),
  });

  return (
    <li ref={navRef}>
      <button
        className={`
          hover
          ${menuOpen ? "highlighted-background" : ""}
          ${useMatch("/network/*") ? "nav-item-selected" : "nav-item-unselected"}
        `}
        style={{ height: "100%", padding: "0 0.5rem" }}
        onClick={() => setMenuOpen(true)}
        onFocus={() => setMenuOpen(true)}
      >
        Network
      </button>
      <div className="ol-container" hidden={!menuOpen}>
        <ol>
          <li className={`${useMatch("/network/throughput/*") ? "active-link" : "nav-item-unselected"}`}>
            <NavLink to={"/network/throughput/service-area"} onClick={() => setMenuOpen(false)}>
              Throughput
            </NavLink>
          </li>
          <li className={`${useMatch("/network/utilisation/*") ? "active-link" : "nav-item-unselected"}`}>
            <NavLink to={"/network/utilisation/single-sector"} onClick={() => setMenuOpen(false)}>
              Utilisation
            </NavLink>
          </li>
        </ol>
      </div>
    </li>
  );
}

export function Navigation() {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  function toggleDrawer() {
    setIsDrawerOpen((prevState) => !prevState);
  }

  return (
    <>
      <Drawer isOpen={isDrawerOpen} drawerHandler={toggleDrawer} />
      <header className="navigation-bar">
        <BurgerButton isOpen={isDrawerOpen} onClickHandler={toggleDrawer} />
        <Logo />
        <nav className="nav-container">
          <ol className="nav-items">
            <li>
              <NavLink
                to="/services"
                className={({ isActive }) => `nav-item ${isActive ? `nav-item-selected` : `nav-item-unselected`}`}
              >
                Services
              </NavLink>
            </li>
            <NetworkDropdown />
            <li>
              <ActionButton
                developmentUrl={ableUrl}
                element="Link"
                icon="Help"
                label=""
                to="/help"
                variant="LowEmphasisBig"
              />
            </li>
            <li>
              <Dropdown />
            </li>
          </ol>
        </nav>
      </header>
    </>
  );
}
