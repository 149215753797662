import { InfoTooltip } from "components/InfoTooltip";
import { Subscription, TenantData } from "types";
import "./ServiceInformation.scss";

type ServiceInformationProps = {
  tenancyInfo: TenantData;
  subscription: Subscription;
};

export const ServiceInformation = ({ tenancyInfo, subscription }: ServiceInformationProps) => {
  const { isAssigned, device, serviceNumber, simId, subscriptionId } = subscription;
  const saName = tenancyInfo.serviceArea?.[0]?.serviceAreaName || "Unknown";

  return (
    <>
      <h2>Service Information</h2>
      <dl className="info-list" aria-label="Service Information list">
        <div>
          <dt>Subscription ID</dt>
          <dd>{subscriptionId}</dd>
        </div>
        <div>
          <dt>Tenancy</dt>
          <dd>{tenancyInfo.tenancyName}</dd>
        </div>
        <div>
          <dt>Service area</dt>
          <dd>{saName}</dd>
        </div>
        <div className="with-tooltip">
          <dt style={{ display: "flex", alignItems: "center" }}>
            <span>SIM serial</span>
            <InfoTooltip
              id="sim-serial-tooltip"
              label="SIM Serial Information"
              description="The serial number located on the SIM card."
            />
          </dt>
          <dd>{simId}</dd>
        </div>
        <div>
          <dt>Service number</dt>
          <dd>{serviceNumber || "No service number set"}</dd>
        </div>
      </dl>

      {isAssigned && (
        <>
          <h2>Device Information</h2>
          <dl className="info-list" aria-label="Device Information list">
            <div className="tooltip-grouping">
              <dt>
                <span>IMEI</span>
                <InfoTooltip
                  id="imei-serial-tooltip"
                  label="IMEI Information"
                  description="This is the devices' unique International Mobile Equipment Identity."
                />
              </dt>
              <dd>{device?.equipmentId}</dd>
            </div>

            <div>
              <dt>Make</dt>
              <dd>{device?.make}</dd>
            </div>
            <div>
              <dt>Model</dt>
              <dd>{device?.model}</dd>
            </div>
          </dl>
        </>
      )}
    </>
  );
};
