import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useTenancyData } from "hooks";
import { useSearchParams } from "react-router-dom";
import { fetchServiceAreaData } from "services/api/account-service";
import { fetchAABUtilizationBySectorID } from "services/api/observability-service";
import { TKpi } from "types";

export function useSectorMetrics() {
  const { data: tenantData } = useTenancyData();
  const [searchParams, setSearchParams] = useSearchParams();
  const sector = searchParams.get("sector") || "";

  // Get the first service area from the Tenant data
  const serviceArea = tenantData ? tenantData[0].serviceArea[0].serviceAreaId : undefined;

  // Fetch the service area data to get sectors.
  const { data: serviceAreaData } = useQuery({
    queryKey: ["service-area", serviceArea],
    queryFn: () => fetchServiceAreaData(serviceArea),
    enabled: !!serviceArea,
    refetchOnWindowFocus: false,
  });

  const sectorOptions = serviceAreaData
    ? serviceAreaData?.sectors
        ?.map(({ sectorName }) => ({ value: sectorName, label: sectorName }))
        .toSorted((a, b) => a.label.localeCompare(b.label))
    : [];

  // 28 Day date time range for data, and Sector information variables.
  const dateTimeFrom = dayjs.utc().subtract(28, "days").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const dateTimeTo = dayjs.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const sectorInformation = serviceAreaData?.sectors?.find(({ sectorName }) => sectorName === sector);
  const sectorMbps = sectorInformation?.AAB_Mbps;

  const { data: series, isLoading } = useQuery({
    queryKey: ["utilization", sector],
    queryFn: () => fetchAABUtilizationBySectorID(sector, dateTimeFrom, dateTimeTo),
    enabled: !!sector,
    refetchOnWindowFocus: true,
    select(data) {
      return formatUtilisationMetrics(data.series, sectorMbps);
    },
  });

  return {
    sectorsOptions: sectorOptions,
    series,
    isLoading,
    sectorMbps,
    params: { sector, dateTimeFrom, dateTimeTo },
    onSelect: setSearchParams,
  };
}

type FormattedSeriesData = {
  downlink: Array<{ timestamp: string; value: number; threshold: number }>;
  uplink: Array<{ timestamp: string; value: number; threshold: number }>;
};

export function formatUtilisationMetrics(kpis: TKpi[], aabMbps: number | undefined): FormattedSeriesData {
  // This case should never really happen
  if (!aabMbps) {
    return { downlink: [], uplink: [] };
  }

  const downlink = kpis.map(({ timestamp, throughput: { downLink } }) => ({
    timestamp,
    value: downLink,
    threshold: aabMbps,
  }));

  const uplink = kpis.map(({ timestamp, throughput: { upLink } }) => ({
    timestamp,
    value: upLink,
    threshold: aabMbps,
  }));

  return { downlink, uplink };
}

type ChartSeriesData = { used: number[][]; available: number[][]; exceeded: number[][] };

export function generateAABChartData(
  seriesData: Array<{ timestamp: string; value: number; threshold: number }> | undefined
) {
  const initData: ChartSeriesData = { used: [], available: [], exceeded: [] };

  if (!seriesData) return initData;

  return seriesData.reduce((prev, curr) => {
    // value and threshold are in Mbps
    const { timestamp, value, threshold } = curr;

    const epochTime = new Date(timestamp).getTime();

    // If we have exceeded
    if (value >= threshold) {
      prev.used.push([epochTime, threshold]);
      prev.available.push([epochTime, 0]);
      prev.exceeded.push([epochTime, value - threshold]);
    } else {
      prev.used.push([epochTime, value]);
      prev.available.push([epochTime, threshold - value]);
      prev.exceeded.push([epochTime, 0]);
    }
    return prev;
  }, initData);
}

export function generateHighchartSeries(series: ChartSeriesData, plotType: "Downlink" | "Uplink") {
  if (!series) return [];

  const seriesData: Highcharts.SeriesOptionsType[] = [
    {
      type: "column",
      name: `${plotType} bandwidth used`,
      color: "#0064d2",
      pointWidth: 8,
      legendIndex: -2,
      legendSymbol: "lineMarker",
      data: series.used,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "column",
      name: `${plotType} bandwidth available`,
      color: "#b2e0fd",
      pointWidth: 8,
      legendIndex: -3,
      legendSymbol: "lineMarker",
      data: series.available,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "column",
      name: `${plotType} throughput exceededing subscription`,
      color: "#d93923",
      pointWidth: 8,
      legendIndex: -1,
      legendSymbol: "lineMarker",
      data: series.exceeded,
      events: { legendItemClick: (e) => e.preventDefault() },
    },
    {
      type: "column",
      // visible: false,
      legendIndex: -4,
      legendSymbol: "lineMarker",
      name: `Subscribed amount`,
      color: "black",
      events: { legendItemClick: (e) => e.preventDefault() },
    },
  ];

  return seriesData;
}
