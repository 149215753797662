import { jwtDecode } from "jwt-decode";
import { AuthLevels, UserToken } from "types";
import { callAuthServiceLogout } from "./authService";
import dayjs from "./dayjs";

export function getTokenFromSessionStorage(sessionStorageKey: string) {
  const token = sessionStorage.getItem(sessionStorageKey);
  if (token !== null) {
    return token;
  } else {
    callAuthServiceLogout();
    return null;
  }
}

export function getTokenData() {
  const token = getTokenFromSessionStorage("id_token");
  if (token !== null) {
    const data = jwtDecode<UserToken>(token);
    return data;
  } else {
    callAuthServiceLogout();
    return null;
  }
}

export const decodeToken = () => {
  const tokenData = getTokenData();

  if (tokenData !== null) {
    const cidnAuthString = tokenData.roles[0].value.split(":") as [string, AuthLevels];
    const [cidn, authLevel] = cidnAuthString;
    return { ...tokenData, cidn, authLevel };
  }

  return null;
};

export const isAuthorised = (requiredAuthLevel: AuthLevels) => {
  const authorisationValues = {
    READ: 100,
    USER: 200,
    ADMIN: 300,
    WRITE: 400,
    SYS_ADMIN: 500,
  } as const;

  const decodedToken = decodeToken();

  if (decodedToken !== null) {
    const { authLevel } = decodedToken;
    const authWeight = authorisationValues[authLevel];
    const requiredAuthWeight = authorisationValues[requiredAuthLevel];

    if (authWeight >= requiredAuthWeight) return true;
  }

  return false;
};

export const isExpired = () => {
  const tokenData = getTokenData();

  if (tokenData !== null) {
    const { exp } = tokenData;
    let d = new Date(0).setUTCSeconds(exp); // The 0 there is the key, which sets the date to the epoch
    let now = dayjs.utc();
    let expiresAt = dayjs.utc(d);
    if (now.isSameOrAfter(expiresAt)) return true;
  }

  return false;
};

export function validateToken(token: string) {
  try {
    let { exp } = jwtDecode<UserToken>(token);
    let d = new Date(0).setUTCSeconds(exp); // The 0 there is the key, which sets the date to the epoch
    let now = dayjs.utc();
    let expiration = dayjs.utc(d);
    return now.isSameOrBefore(expiration);
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function checkTokens() {
  const idToken = sessionStorage.getItem("id_token");
  const refreshToken = sessionStorage.getItem("refresh_token");

  if (!idToken || !refreshToken) {
    return false;
  }

  const validIdToken = validateToken(idToken);
  const validRefeshToken = validateToken(refreshToken);

  if (validIdToken && validRefeshToken) {
    return true;
  } else return false;
  // You can only refresh when you have the id_token and refresh_token
  // Both of these must not be expired.
}
