import { ReactEventHandler } from "react";
import "./styles.scss";

type RoundedActionProps = {
  onClick: ReactEventHandler<HTMLButtonElement>;
  isActive: boolean;
  content: string;
};

export function RoundedAction({ onClick, isActive, content }: RoundedActionProps) {
  return (
    <button className={`chart-type-button ${isActive && "active-button-styles"}`} onClick={onClick}>
      {content}
    </button>
  );
}
