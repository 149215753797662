import { DownloadCSVButton } from "components/ui/Buttons";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";

const chartTitleStyles: Highcharts.CSSObject = {
  fontSize: "14px",
};

const yAxisStyles: Highcharts.CSSObject = {
  fontSize: "16px",
  fontFamily: "Telstra Text Variable",
};

type ChartOptions = Highcharts.Options & { isLoading?: boolean; paraTitle?: string };

Highcharts.setOptions({
  accessibility: { enabled: true },
  lang: { noData: "No data to display." },
  title: { text: undefined, style: chartTitleStyles },
  credits: { enabled: false },
  yAxis: { title: { style: yAxisStyles } },
  legend: { itemStyle: { fontSize: "14px", fontFamily: "Telstra Text Variable" } },
  exporting: {
    accessibility: { enabled: true },
    buttons: { contextButton: { enabled: false } },
  },
  tooltip: { shared: true },
  time: { timezone: "Australia/Melbourne" },
});

export function Chart({ chartOptions }: { chartOptions: ChartOptions }) {
  const [options, setOptions] = useState<Highcharts.Options>(chartOptions);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const chart = chartRef.current?.chart;
  const showDownloadButton = chartOptions.series?.length;

  // Display chart loading when fetching data.
  useEffect(() => {
    if (chart) {
      if (chartOptions.isLoading) chart.showLoading("Loading");
      else chart.hideLoading();
    }
  }, [chart, chartOptions.isLoading]);

  // Re-generate chart option whenever data changes.
  useEffect(() => {
    setOptions(chartOptions);
  }, [chartOptions]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {showDownloadButton ? (
          <>
            <p>{chartOptions.paraTitle}</p>
            <DownloadCSVButton
              onClick={() =>
                //@ts-ignore
                chart?.downloadCSV()
              }
            />
          </>
        ) : null}
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
    </>
  );
}
