import axios, { AxiosRequestConfig } from "axios";
import type {
  AABSectorUtilizationResp,
  SectorThroughputResp,
  ServiceAreaHistoryResp,
  SubscriptionHistoryResp,
} from "types";
import { getTokenFromSessionStorage } from "utils";
import { getBasePath } from "./common";

type FetchServiceHistory = {
  subscriptionId: string;
  period: string;
  dateTimeFrom: string;
  dateTimeTo: string;
};

export async function fetchSubscriptionHistory({
  subscriptionId,
  period,
  dateTimeFrom,
  dateTimeTo,
}: FetchServiceHistory) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios.get<SubscriptionHistoryResp>(
    `${basePath}/api/tdn/observability/v1/subscriptions/${subscriptionId}/history`,
    {
      headers: { authorization: `Bearer ${token}` },
      params: { period, dateTimeFrom, dateTimeTo },
    }
  );

  const data = response.data.data;
  return data;
}

// TODO: add correct return type
export async function fetchServiceAreaHistory(serviceAreaId: string, options: AxiosRequestConfig) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios.get<ServiceAreaHistoryResp>(
    `${basePath}/api/tdn/observability/v1/service-areas/${serviceAreaId}`,
    { headers: { authorization: `Bearer ${token}` }, ...options }
  );

  const data = response.data.data;
  return data;
}

export async function fetchAABUtilizationBySectorID(sectorId: string, dateTimeFrom: string, dateTimeTo: string) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios.get<AABSectorUtilizationResp>(
    `${basePath}/api/tdn/observability/v1/sectors/${sectorId}/aab`,
    {
      headers: { authorization: `Bearer ${token}` },
      params: {
        dateTimeFrom,
        dateTimeTo,
      },
    }
  );

  const data = response.data.data;
  return data;
}

type SectorThroughputParams = {
  sectorId: string;
  serviceTier: string; // refine later
  dateTimeFrom: string;
  dateTimeTo: string;
  period: string;
};

export async function fetchThroughputBySectorID({
  sectorId,
  serviceTier,
  dateTimeFrom,
  dateTimeTo,
  period,
}: SectorThroughputParams) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios.get<SectorThroughputResp>(`${basePath}/api/tdn/observability/v1/sectors/${sectorId}`, {
    headers: { authorization: `Bearer ${token}` },
    params: {
      serviceTier,
      dateTimeFrom,
      dateTimeTo,
      period,
    },
  });

  const data = response.data.data;
  return data;
}

export async function fetchAABUtilizationByServiceArea(
  serviceAreaId: string,
  dateTimeFrom: string,
  dateTimeTo: string
) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios.get<AABSectorUtilizationResp>(
    `${basePath}/api/tdn/observability/v1/service-areas/${serviceAreaId}/aab`,
    {
      headers: { authorization: `Bearer ${token}` },
      params: {
        serviceTier: "low",
        dateTimeFrom,
        dateTimeTo,
      },
    }
  );

  const data = response.data.data;
  return data;
}
