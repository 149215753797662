const MorningSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="custom-svg-class">
      <use href="/assets/telstra-ui-sprites.svg#illus-greetings-morning" />
    </svg>
  );
};

const AfternoonSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="custom-svg-class">
      <use href="/assets/telstra-ui-sprites.svg#illus-greetings-afternoon" />
    </svg>
  );
};

const EveningSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="custom-svg-class">
      <use href="/assets/telstra-ui-sprites.svg#illus-greetings-evening" />
    </svg>
  );
};

const CustomIllustration = () => {
  let curHr = new Date().getHours();

  if (curHr < 12) return <MorningSVG />;
  if (curHr < 18) return <AfternoonSVG />;
  if (curHr <= 23) return <EveningSVG />;

  return null;
};

export default CustomIllustration;
