import { ReactNode } from "react";
import styles from "./styles/subheading.module.scss";

type Props = {
  title: string;
  description?: string;
  children?: ReactNode;
  alignItems?: "flex-start" | "flex-end" | "center" | "normal";
  justifyContent?: "space-between" | "flex-end" | "flex-start" | "normal";
};

function Subheading({ title, description, children, alignItems = "normal", justifyContent = "normal" }: Props) {
  return (
    <>
      <div className={styles.subheading} style={{ alignItems: alignItems, justifyContent: justifyContent }}>
        <h2>{title}</h2>
        {children ? children : <></>}
      </div>
      {description && <p className={styles.description}>{description}</p>}
    </>
  );
}

export default Subheading;
