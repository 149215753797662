import { ActionButton } from "@able/react";
import { ableUrl } from "utils/constants";
import "./styles.scss";

export function DownloadCSVButton({ onClick, classNames = [""] }: { onClick: Function; classNames?: string[] }) {
  const classes = ["download-csv-button", ...classNames].join(" ");
  return (
    <ActionButton
      className={classes}
      element="button"
      variant="LowEmphasis"
      label="Download CSV"
      icon="FileDownload"
      developmentUrl={ableUrl}
      events={{ onClick: onClick }}
    />
  );
}
