import { useQuery } from "@tanstack/react-query";
import { fetchTenancyData } from "services/api/account-service";

type TErrorResponse = {
  response: { status: number };
};

export const useTenancyData = () =>
  useQuery({
    queryKey: ["tenant"],
    queryFn: () => fetchTenancyData(),
    retry: (_failureCount, error: TErrorResponse) => (error?.response?.status === 404 ? false : true),
    refetchOnWindowFocus: false,
  });
