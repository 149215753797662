import { ActionButton, ModalContent } from "@able/react";
import { ableUrl } from "utils/constants";
import React, { MutableRefObject } from "react";

type TModalProps = {
  template: "Create" | "Edit";
  focusRef: MutableRefObject<HTMLButtonElement | null>;
  open: boolean;
  onConfirm: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NrNoSubWarningModal = ({ template, focusRef, onConfirm, open, setOpen }: TModalProps) => {
  function handleClose() {
    setOpen(false);
    focusRef.current?.focus();
  }

  return (
    <ModalContent
      variant="Comfortable"
      // @ts-ignore
      focusOnCloseRef={focusRef}
      bodyContent={
        <p>
          Are you sure you want to {template.toLowerCase()} this recipient without subscribing them to receive email or
          SMS notifications? Recipients can be subscribed later under ‘Settings’.
        </p>
      }
      footerContent={
        <>
          <ActionButton
            element="button"
            label="Cancel"
            variant="MediumEmphasis"
            developmentUrl={ableUrl}
            events={{ onClick: handleClose }}
          />
          <ActionButton
            id="modal-confirmation-button"
            element="button"
            label={template === "Create" ? "Submit" : "Update"}
            variant="HighEmphasisDestructive"
            developmentUrl={ableUrl}
            events={{ onClick: onConfirm }}
          />
        </>
      }
      isShowing={open}
      setHideDialog={() => setOpen(false)}
      title={`${template} recipient without subscribing them to notifications?`}
      developmentUrl={ableUrl}
    />
  );
};
