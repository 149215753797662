import { z } from "zod";

export const createHybridServiceFormSchema = z.object({
  adaptiveMobilityNumber: z.string().transform(
    (val, ctx) => {
      const txf = val.replace(/\s/g, "");
      const regex = new RegExp(/^04\d{8}$/gm);
      if (!regex.test(txf)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Service numer must start with 04 and contains 10 digits.",
        });
      }

      return txf;
    }
    // .regex(/^04\d{2}\s\d{3}\s\d{3}$/gm)
  ),
  serviceReference: z.string().max(64, "Hybrid service reference name must not exceed 64 characters."),
  serviceLocation: z.string().max(64, "Hybrid service location name must not exceed 64 characters."),
  serviceTierSpeed: z
    .string()
    .regex(new RegExp("^default|low|medium|high$"), "Service tier speed is an incorrect type."),
});
