export function formatUnits(value: string | number | undefined, units: string, decimal?: number) {
  let numericalValue;
  if (typeof value === "string") numericalValue = parseFloat(value);
  else if (value === undefined) {
    return "";
  } else numericalValue = value;

  switch (units) {
    case "kbps":
      return formatKbps(numericalValue, decimal);
    case "bytes":
      return formatBytes(numericalValue, decimal);
    default:
      return "";
  }
}

export function formatBytes(bytes: number, decimals = 0) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatKbps(kbps: number, decimals = 0) {
  if (kbps === 0) return "0 Kbps";
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Kbps", "Mbps", "Gbps"];
  const i = Math.floor(Math.log(kbps) / Math.log(k));
  return parseFloat((kbps / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
