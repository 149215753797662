import { Helmet } from "react-helmet-async";
import "./NotFound.scss";

export const NotFound = () => {
  return (
    <div className="not-found-container">
      <Helmet>
        <title>Page Not Found - Telstra Dedicated Network</title>
      </Helmet>
      Not Found!
    </div>
  );
};
