import { tdnLogout } from "services/api/identity-service";
import { callAuthServiceLogout } from "utils/authService";

export const createLoginSlice = (set, get) => ({
  logout: async () => {
    const refreshToken = get().refreshToken;
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("refresh_token");

    try {
      await tdnLogout(refreshToken);
      callAuthServiceLogout();
    } catch (e) {
      console.error("Error occurred while logging out: ", e);
    }
  },
});
