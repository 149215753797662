import * as Highcharts from "highcharts";
import { formatTimestamp } from "utils/formatters/date-formatters";

type FormatterArgs = {
  context: Highcharts.TooltipFormatterContextObject;
  plotType: string;
};

export function tooltipFormatter({ context, plotType }: FormatterArgs) {
  let tooltip;

  if (context.points && context.points.length >= 3) {
    const used = context.points[0];
    const avail = context.points[1];
    const exceeded = context.points[2];

    if (typeof used.y === "number" && typeof avail.y === "number" && typeof exceeded.y === "number") {
      // Calculating the used and subscribed amounts.
      const subscribedMbps = Number((exceeded.y === 0 ? used.y + avail.y : used.y).toFixed(2));
      const totalUsed = Number((exceeded.y === 0 ? used.y : used.y + exceeded.y).toFixed(2));
      const color = exceeded.y ? exceeded.color : used.color;
      const available = Number(avail.y.toFixed(2));

      tooltip = `
        <div style="padding:0.25rem;font-family:'Telstra Text Variable'">
          <div style="margin-bottom:0.35rem">${plotType} AAB bandwidth utilisation</div>
          <div style="display:flex;flex-direction:column;gap:0.5rem">
            <div style="display:flex;align-items:center;gap:0.6rem">
              <span style="background-color:${color};width:30px;height:2px"></span>
              <span style="display:inline-block">${totalUsed} Mbps Used</span>
            </div>
            <div style="display:flex;align-items:center;gap:0.6rem">
              <span style="background-color:${avail.color};width:30px;height:2px"></span>
              <span style="display:inline-block">${available} Mbps Available</span>
            </div>
            <div style="display:flex;align-items:center;gap:0.6rem;margin-bottom:0.6rem">
              <span style="background-color:black;width:30px;height:2px"></span>
              <span style="display:inline-block">${subscribedMbps} Mbps Subscribed</span>
            </div>
          </div>
          <span>${formatTimestamp(context.x as number, "MMM DD")}</span>
        </div>
        `;
    }
  }

  return tooltip;
}
