import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onNavigateOutside: () => void;
};

export function useOnNavigateOutside<T extends HTMLElement>({ isOpen, onNavigateOutside }: Props) {
  const location = useLocation();
  const currentLocation = useRef(location);
  const ref = useRef<T>(null);

  // Close drawer on route change
  useEffect(() => {
    if (isOpen && location.pathname !== currentLocation.current.pathname) {
      onNavigateOutside();
    }
    currentLocation.current = location;
  }, [isOpen, location, onNavigateOutside]);

  useEffect(() => {
    // Close menu when escape key is pressed
    const handleKeyDown = function (event: any) {
      if (event.key === "Escape") {
        onNavigateOutside();
      }
    };

    // Close menu when clicking outside the menu
    const handleClickOutside = function (event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onNavigateOutside();
      }
    };

    // Close menu when tabbing outside the menu
    const handleTabOutside = function (event: FocusEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onNavigateOutside();
      }
    };

    // Add event listeners when menu is open
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("focusin", handleTabOutside);
    }
    // Clean up event listeners on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("focusin", handleTabOutside);
    };
  }, [isOpen, onNavigateOutside]);

  return ref;
}
