import { Divider } from "@able/react";
import { Subscription } from "types";
import { ConfigurableFields } from "./Common/ConfigurableFields";
import { HybridConfiguration } from "./Hybrid/HybridConfiguration";
import { HyrbidActivation } from "./Hybrid/HyrbidActivation";
import { ServiceTier } from "./ServiceTier";
import { ServiceStatus } from "./Standard/ServiceStatus";
import { StandardActivation } from "./Standard/StandardActivation";

export const ServiceConfiguration = ({ subscription }: { subscription: Subscription }) => {
  const { state } = subscription;
  const hasBeenActivated = state !== "pendingActivation";

  return (
    <>
      <h2>Configuration settings</h2>
      <ConfigurableFields subscription={subscription} />
      <Divider />

      {/* STANDARD service */}
      {subscription.subscriptionType === "TDN_STANDARD" ? (
        hasBeenActivated ? (
          <>
            <ServiceStatus subscription={subscription} />
            <Divider />
            <ServiceTier subscription={subscription} />
          </>
        ) : (
          <StandardActivation subscription={subscription} />
        )
      ) : null}

      {/* HYBRID service */}
      {subscription.subscriptionType === "TDN_HYBRID" ? (
        hasBeenActivated ? (
          <HybridConfiguration subscription={subscription} />
        ) : (
          <HyrbidActivation subscription={subscription} />
        )
      ) : null}
    </>
  );
};
