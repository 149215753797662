import { ActionButton } from "@able/react";
import SearchCombobox2 from "components/SearchCombobox2";
import { PageHeading } from "components/ui/PageHeading";
import Fuse, { Expression, IFuseOptions } from "fuse.js";
import { useNotificationRecipients } from "hooks/useNotificationRecipients";
import { useTenancyData } from "hooks/useTenancyData";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { useDebouncedCallback } from "use-debounce";
import { NotificationRecipientTable } from "./NotificationRecipientTable";
import "./styles.scss";

interface IRecipientData {
  name: string;
  email: string;
  isEmailEnabled: boolean;
  mobileNumber: string;
  isSmsEnabled: boolean;
  recipientId: string;
  tenancyId: string;
}

function filterData<T>(data: object[], searchPattern: string | Expression, fuseOptions?: IFuseOptions<object>): T {
  const fuse = new Fuse([], fuseOptions);
  fuse.setCollection(data);
  const results = fuse.search(searchPattern).map((data) => data.item);
  return results as T;
}

function NotificationManagement() {
  const { data: tenancyData } = useTenancyData();
  const { data: recipientData, isLoading: loadingRecipients } = useNotificationRecipients(tenancyData?.[0].tenancyId);
  const [filteredData, setFilteredData] = useState<IRecipientData[] | []>([]);

  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);

  const onSearchHandler = useDebouncedCallback((value) => {
    if (!value || value === "") {
      const data = recipientData;
      return setFilteredData(data);
    }

    const fuseOptions: IFuseOptions<object> = {
      includeScore: true,
      useExtendedSearch: true,
      keys: ["name", "email", "mobileNumber"],
      threshold: 0.1,
    };

    const searchResults = filterData<IRecipientData[]>(recipientData, value, fuseOptions);
    return setFilteredData(searchResults);
  }, 200);

  useEffect(() => {
    if (!loadingRecipients && recipientData?.length) {
      const data = recipientData as Array<IRecipientData>;
      setFilteredData(data);
    }
  }, [recipientData, loadingRecipients]);

  return (
    <main>
      <Helmet>
        <title>Notification Management - Telstra Dedicated Network</title>
      </Helmet>
      <PageHeading content="Notification Management" />
      <div className="table-controls">
        <SearchCombobox2
          title="Filter Recipients"
          data={recipientData}
          onFieldChange={onSearchHandler}
          helperText="The table will be filtered as you type."
        />
        <ActionButton
          className="recipient-button"
          developmentUrl="/able-sprites.svg"
          element="Link"
          label="Create a new recipient"
          to="/notification-management/create"
          variant="MediumEmphasis"
        />
      </div>
      <NotificationRecipientTable isLoading={loadingRecipients} data={filteredData} />
    </main>
  );
}

export default NotificationManagement;
