import axios, { AxiosRequestConfig } from "axios";
import { getBasePath } from "./common";

type TLoginParams = {
  code: string;
  nonce: string;
};

export async function tdnLogin(params: TLoginParams, options?: AxiosRequestConfig) {
  const basePath = getBasePath();
  const response = await axios({
    method: "POST",
    url: `${basePath}/api/tdn/identity/v1/login/external`,
    params: params,
    headers: { "Access-Control-Allow-Origin": "*" },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

export async function tdnLogout(refreshTokenId: string, options?: AxiosRequestConfig) {
  const basePath = getBasePath();
  const response = await axios({
    method: "POST",
    url: `${basePath}/api/tdn/identity/v1/logout`,
    data: { refreshTokenId },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

export async function fetchNewToken(options?: AxiosRequestConfig) {
  const token = sessionStorage.getItem("id_token");
  const refreshToken = sessionStorage.getItem("refresh_token");
  const basePath = getBasePath();

  const response = await axios({
    method: "POST",
    url: `${basePath}/api/tdn/identity/v1/token/refresh`,
    data: { token, refreshToken },
    ...options,
  });

  const data = response.data?.data;
  return data;
}
