import dayjs from "dayjs";
import { formatUnits } from "utils/formatters/network-units";
import { TooltipFormatterContextObject } from "highcharts";

type FormatterProps = {
  ctx: TooltipFormatterContextObject;
  units: string;
};

export function tooltipFormatter({ ctx, units }: FormatterProps) {

  const formattedTime = dayjs(ctx.x).utc().tz("Australia/Melbourne").format("dddd D MMM, HH:mm");
  const yValue = ctx.y!; // assert that this exists
  const seriesName = ctx.point.series.name;

  const formattedUnit = formatUnits(yValue, units, 2);

  const template = `
        <div style="display:flex;flex-direction:column;padding:0.25rem;gap:0.5rem">
          <div>${formattedTime}</div>
          <div style="display:flex;align-items:center;gap:0.5rem">
            <div style="background-color:${ctx.color};width:30px;height:2px"></div>
            <div>${seriesName}: ${formattedUnit} Used</div>
          </div>
        </div>
  `;

  return template;
}
