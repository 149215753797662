import { useQuery } from "@tanstack/react-query";
import { fetchSubscriptionById } from "services/api/inventory-service";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import CreateHybridServiceForm from "./CreateHybridServiceForm";

export function CreateHybridServiceContainer() {
  const [searchParams] = useSearchParams();
  const subId = searchParams.get("subId");
  const { success } = z
    .string()
    .regex(/^[a-z0-9]{24}$/gm)
    .safeParse(subId);
  const hasSubId = searchParams.has("subId");

  const { data, isLoading } = useQuery({
    queryKey: ["subscriptions", subId],
    queryFn: () => fetchSubscriptionById(subId),
    enabled: hasSubId && success, // Don't hit the service if subId is invalid.
  });

  // Subscription ID is invalid.
  if (hasSubId && !success) {
    return <div>Invalid subscription ID</div>;
  }

  // Subscription ID was valid, but data is still loading.
  if (success && isLoading) {
    return <div>Loading susbcription data</div>;
  }

  return <CreateHybridServiceForm subData={data ? data : null} initialStep={data ? 1 : 0} />;
}
