import { Icon, Spacing } from "@able/react";
import { Pagination } from "components/Table/Pagination";
import { Table } from "components/Table/Table";
import { TableBody } from "components/Table/TableBody";
import { TableDataCell } from "components/Table/TableDataCell";
import { TableFooter } from "components/Table/TableFooter";
import { TableHeader } from "components/Table/TableHeader";
import { TableHeaderCell } from "components/Table/TableHeaderCell";
import { TableRow } from "components/Table/TableRow";
import { ableUrl } from "utils/constants";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

type TFormattedData = {
  id: string;
  name: string;
  email: string;
  isEmailEnabled: string;
  mobileNumber: string;
  isSmsEnabled: string;
};

type TTableContainer = {
  data: IRecipientData[];
  isLoading: Boolean;
};

type TSortValues = {
  column: keyof TFormattedData | null;
  order: "none" | "ascending" | "descending";
};

interface IRecipientData {
  name: string;
  email: string;
  isEmailEnabled: boolean;
  mobileNumber: string;
  isSmsEnabled: boolean;
  recipientId: string;
  tenancyId: string;
}

function IconSelector(content: string) {
  if (content === "Subscribed") return "Success";
  if (content === "Unsubscribed") return "ServiceDown";
  return "DeviceUnknown";
}

export const NotificationRecipientTable = ({ data, isLoading }: TTableContainer) => {
  const navigate = useNavigate();
  const [sort, setSort] = useState<TSortValues>({ column: null, order: "none" });
  const [pagination, setPagination] = useState({ currentPage: 1, rowsPerPage: 5 });
  const [formattedRows, setFormattedRows] = useState<TFormattedData[]>([]);

  // Pagination starts at the current page - 1 (normalize array) multiplied by number of rows allowed.
  const sliceStart = (pagination.currentPage - 1) * pagination.rowsPerPage;

  // Pagination end value is either sliceStart + rowsPerPage, or the end of (length of) the array, depends which is lesser.
  const sliceEnd =
    sliceStart + pagination.rowsPerPage < formattedRows.length
      ? sliceStart + pagination.rowsPerPage
      : formattedRows.length;

  const totalPages = Math.ceil(formattedRows.length / pagination.rowsPerPage);

  const tableMessage =
    formattedRows.length > 0
      ? `Showing results ${sliceStart + 1} - ${sliceEnd} of ${formattedRows.length}`
      : "No results to display.";

  // Sets the sorting options, triggers useEffect update.
  function handleSortClick(sortColumn: keyof TFormattedData) {
    setSort({
      column: sortColumn,
      order:
        sort.order === "none" || sort.column !== sortColumn
          ? "ascending"
          : sort.order === "ascending"
          ? "descending"
          : "ascending",
    });
  }

  // The actual sorting function. Wrapped in useCallback to avoid re-renders.
  const handleSort = useCallback(
    (formattedRows: TFormattedData[], column: keyof TFormattedData | null) => {
      if (column) {
        const sorted = [...formattedRows].sort((a: any, b: any) => {
          // This will return either a string value or undefined.
          const valA = a[column]?.toString().toLocaleLowerCase();
          const valB = b[column]?.toString().toLocaleLowerCase();

          if (valA === undefined || valA > valB) return sort.order === "ascending" ? 1 : -1;
          if (valB === undefined || valA < valB) return sort.order === "ascending" ? -1 : 1;
          return 0;
        });

        return sorted;
      }
      return formattedRows;
    },
    [sort.order]
  );

  /**
   * The data must be formatted whenever sorting is required. This is especially
   * true if the raw data contains nested keys that are required to display.
   * This useEffect will trigger whenever the data changes (refetch, refilter), or
   * whenever the sort properties change. Sorting is performed on FORMATTED DATA!
   */
  useEffect(() => {
    if (!isLoading && data) {
      const formattedRows: TFormattedData[] = data.map((item) => {
        return {
          id: item.recipientId,
          name: item.name,
          email: item.email,
          isEmailEnabled: item.isEmailEnabled ? "Subscribed" : "Unsubscribed",
          mobileNumber: item.mobileNumber,
          isSmsEnabled: item.isSmsEnabled ? "Subscribed" : "Unsubscribed",
        };
      });

      const sorted = handleSort(formattedRows, sort.column);
      setFormattedRows(sorted);
      // Reset pagination current Page to 1 to avoid incorrect slicing.
      setPagination((s) => ({ ...s, currentPage: 1 }));
    }
  }, [isLoading, data, sort, handleSort]);

  return (
    <Spacing bottom="spacing10x">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "name" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("name")}
            >
              Name
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "email" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("email")}
            >
              Email address
            </TableHeaderCell>
            <TableHeaderCell>Email status</TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "mobileNumber" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("mobileNumber")}
            >
              Mobile number
            </TableHeaderCell>
            <TableHeaderCell>SMS status</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!isLoading &&
            formattedRows.slice(sliceStart, sliceEnd).map((data, idx) => (
              <TableRow key={`row-${idx}`} onClick={() => navigate(`/notification-management/${data.id}/edit`)}>
                <TableDataCell>
                  <Link style={{ outline: "none" }} to={`/notification-management/${data.id}/edit`}>
                    {data.name}
                  </Link>
                </TableDataCell>
                <TableDataCell>{data.email}</TableDataCell>
                <TableDataCell>
                  <Icon icon={IconSelector(data.isEmailEnabled)} developmentUrl={ableUrl} />
                  {data.isEmailEnabled}
                </TableDataCell>
                <TableDataCell>{data.mobileNumber}</TableDataCell>
                <TableDataCell>
                  <Icon icon={IconSelector(data.isSmsEnabled)} developmentUrl={ableUrl} />
                  {data.isSmsEnabled}
                </TableDataCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TableFooter>
        <p>{tableMessage}</p>
        {totalPages > 1 && (
          <Pagination
            currentPage={pagination.currentPage}
            totalPages={totalPages}
            onPageChange={(page: number) => setPagination((s) => ({ ...s, currentPage: page }))}
          />
        )}
      </TableFooter>
    </Spacing>
  );
};
