import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import { fetchMicaData, fetchTenancyData } from "services/api/account-service";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { getTokenData } from "utils/tokens";
import Dashboard from "./Dashboard";

export const DashboardContainer = () => {
  let decodedToken = getTokenData();
  const { data: TD, isLoading, error } = useQuery({ queryKey: ["tenant"], queryFn: fetchTenancyData });
  const { data: micaData } = useQuery({
    queryKey: ["mica"],
    queryFn: () => fetchMicaData(TD[0].tenancyId),
    enabled: !!TD?.[0],
  });

  useBreadCrumbs([]);

  return (
    <main>
      <Helmet>
        <title>Telstra Dedicated Network</title>
      </Helmet>
      <Dashboard
        isLoading={isLoading}
        error={error}
        tenancyData={TD}
        micaData={micaData}
        firstName={decodedToken?.firstName || "NO TOKEN TO DECODE"}
      />
    </main>
  );
};
