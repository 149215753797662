import { Icon } from "@able/react";
import { ableUrl } from "utils/constants";
import { ReactNode } from "react";

type TTableHeaderCellProps = {
  isSortable?: boolean;
  sortOrder?: "none" | "ascending" | "descending";
  sortingCallback?: () => void;
  children: ReactNode;
};

export const TableHeaderCell = ({ isSortable, sortOrder, sortingCallback, children }: TTableHeaderCellProps) => {
  const icon = sortOrder === "none" ? "Sort" : sortOrder === "ascending" ? "ChevronUp" : "ChevronDown";

  return (
    <th scope="col" aria-sort={sortOrder}>
      {isSortable ? (
        <>
          <button
            aria-roledescription={`When pressed, Sorts the table data by ${children}.`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              whiteSpace: "nowrap",
            }}
            onClick={sortingCallback}
          >
            {children}
            <Icon icon={icon} developmentUrl={ableUrl} />
          </button>
        </>
      ) : (
        children
      )}
    </th>
  );
};
