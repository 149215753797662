import { IconButton } from "@able/react";
import { SignOutButton } from "components/ui/Buttons";
import { useOnNavigateOutside } from "hooks/useOnNavigateOutside";
import { ReactNode, useState } from "react";
import { ableUrl } from "utils/constants";

function DropDownItem({ children }: { children: ReactNode }) {
  return <li className="nav-item nav-item-unselected">{children}</li>;
}

function DropDownMenu({ closeDropdown, isOpen }: { closeDropdown: () => void; isOpen: boolean }) {
  const dropdownRef = useOnNavigateOutside<HTMLOListElement>({ isOpen, onNavigateOutside: closeDropdown });

  return (
    <ol
      ref={dropdownRef}
      aria-label="Account Menu"
      id="accountMenu"
      className={`dropdown ${isOpen && "dropdown-visible"}`}
    >
      <DropDownItem>
        <SignOutButton />
      </DropDownItem>
    </ol>
  );
}

export function Dropdown() {
  const [open, setOpen] = useState(false);

  const closeDropdown = () => setOpen(false);

  return (
    <div>
      <IconButton
        aria-expanded={open}
        aria-label="Account"
        aria-controls="accountMenu"
        // @ts-expect-error
        icon="Profile"
        size="Default"
        developmentUrl={ableUrl}
        events={{ onClick: () => setOpen(!open) }}
      />
      <DropDownMenu closeDropdown={closeDropdown} isOpen={open} />
    </div>
  );
}
