import { IconButton } from "@able/react";
import { ableUrl } from "utils/constants";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import "./InfoTooltip.scss";

type IntoTooltipProps = {
  description: string;
  label: string;
  id: string;
};

export const InfoTooltip = ({ description, label, id }: IntoTooltipProps) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const tooltipRef = useRef<HTMLParagraphElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  function calcTooltipLocation() {
    if (buttonRef.current && tooltipRef.current) {
      const boundingBox = buttonRef.current.getBoundingClientRect();
      const { top, right } = boundingBox;

      const tooltipHeight = tooltipRef.current.clientHeight;
      const buttonHeight = buttonRef.current.clientHeight;

      const offset = (tooltipHeight - buttonHeight) / 2;

      tooltipRef.current.style.left = `${right + 8}px`;
      tooltipRef.current.style.top = `${top - offset}px`;
      tooltipRef.current.style.bottom = "auto";
    }
  }

  // On mount set aria-hidden to true on svg, this perhaps should be brought up with Able.
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.children[0].ariaHidden = "true";
    }
  }, []);

  // Handle resize and scroll events for tooltips
  useEffect(() => {
    window.addEventListener("resize", calcTooltipLocation);
    window.addEventListener("scroll", calcTooltipLocation);
    return () => {
      window.removeEventListener("resize", calcTooltipLocation);
      window.removeEventListener("scroll", calcTooltipLocation);
    };
  }, []);

  // Handle click events outside of tooltip + button.
  useEffect(() => {
    function handleClickEvent(event: any) {
      if (tooltipRef.current && buttonRef.current) {
        const isTooltipClick = tooltipRef.current.contains(event.target);
        const isButtonClick = buttonRef.current.contains(event.target);

        if (!isButtonClick && !isTooltipClick) {
          setOpenTooltip(false);
        }
      }
    }

    document.addEventListener("click", handleClickEvent, true);
    return () => {
      document.removeEventListener("click", handleClickEvent, true);
    };
  }, []);

  // TODO: Change the Info icon to InformationAlt when Able resolve the rendering issue.
  // Currently the InformationAlt icon does not render correctly with the IconButton component.
  return (
    <>
      <IconButton
        className="able-icon-button-overrides"
        ref={buttonRef}
        aria-describedby={id}
        aria-expanded={openTooltip}
        aria-label={`${label} tooltip`}
        //@ts-ignore
        icon="Info"
        developmentUrl={ableUrl}
        events={{
          onClick: () => {
            calcTooltipLocation();
            setOpenTooltip(true);
          },
          onFocus: () => {
            calcTooltipLocation();
            setOpenTooltip(true);
          },
          onBlur: () => {
            setOpenTooltip(false);
          },
          onKeyDown: (event: KeyboardEvent) => {
            if (event.key === "Escape") {
              setOpenTooltip(false);
            }
          },
        }}
      />

      <p
        id={id}
        aria-live="polite"
        aria-hidden={openTooltip ? false : true}
        ref={tooltipRef}
        role="tooltip"
        className={`tooltip tooltip-text ${openTooltip && `show-tooltip`}`}
      >
        {description}
      </p>
    </>
  );
};
