import { ActionButton, ModalContent } from "@able/react";
import { ableUrl } from "utils/constants";
import { MutableRefObject } from "react";

type TModalProps = {
  focusRef: MutableRefObject<HTMLButtonElement | null>;
  open: boolean;
  onConfirm: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NrDeleteWarningModal = ({ focusRef, onConfirm, open, setOpen }: TModalProps) => {
  function handleClose() {
    setOpen(false);
    focusRef.current?.focus();
  }

  return (
    <ModalContent
      variant="Comfortable"
      // @ts-ignore
      focusOnCloseRef={focusRef}
      bodyContent={
        <div className="modal-body-content">
          <p>You are about to delete this notification recipient.</p>
          <p>
            <strong>
              <i>You will not be able to retrieve the recipient after deletion.</i>
            </strong>{" "}
          </p>
          <p>Are you sure you wish to continue?</p>
        </div>
      }
      footerContent={
        <>
          <ActionButton
            element="button"
            label="Cancel"
            variant="MediumEmphasis"
            developmentUrl={ableUrl}
            events={{ onClick: handleClose }}
          />
          <ActionButton
            id="modal-confirmation-button"
            element="button"
            label="Confirm"
            variant="HighEmphasisDestructive"
            developmentUrl={ableUrl}
            events={{ onClick: onConfirm }}
          />
        </>
      }
      isShowing={open}
      setHideDialog={handleClose}
      onClose={handleClose}
      title="Delete notification recipient?"
      developmentUrl={ableUrl}
    />
  );
};
