import { ProtectedRoutes } from "pages/ProtectedRoutes";
import { DashboardContainer } from "pages/dashboard/DashboardContainer";
import { HelpContainer } from "pages/help/HelpContainer";
import {
  MultiSectorContainer,
  SectorThroughput,
  ServiceAreaThroughput,
  SingleSectorContainer,
  ThroughputContainer,
  UtilisationContainer,
} from "pages/network";
import { NotFound } from "pages/not-found/NotFound";
import NotificationManagement, { ConfirmationScreen } from "pages/notification-management";
import { RecipientFormContainer } from "pages/notification-management/RecipientForm";
import { ConfigurationContainer, PerformanceContainer, ServiceContainer } from "pages/service";
import { CreateHybridServiceContainer } from "pages/services/components/CreateHybridServiceForm/CreateHybridServiceContainer";
import ServicesContainer from "pages/services/services-container";
import { Navigate, createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutes />,
    children: [
      {
        index: true,
        element: <DashboardContainer />,
      },
      {
        path: "oauthCallback",
        element: <Navigate to={"/"} />,
      },
      {
        path: "help",
        element: <HelpContainer />,
      },
      {
        path: "network",
        children: [
          {
            path: "throughput",
            element: <ThroughputContainer />,
            children: [
              {
                path: "service-area",
                element: <ServiceAreaThroughput />,
              },
              {
                path: "sectors",
                element: <SectorThroughput />,
              },
            ],
          },
          {
            path: "utilisation",
            element: <UtilisationContainer />,
            children: [
              {
                path: "single-sector",
                element: <SingleSectorContainer />,
              },
              {
                path: "multi-sector",
                element: <MultiSectorContainer />,
              },
            ],
          },
        ],
      },
      {
        path: "services",
        element: <ServicesContainer />,
      },
      {
        path: "services/create",
        element: <CreateHybridServiceContainer />,
      },
      {
        path: "services/:id",
        element: <ServiceContainer />,
        children: [
          {
            path: "configuration",
            element: <ConfigurationContainer />,
          },
          {
            path: "performance",
            element: <PerformanceContainer />,
          },
        ],
      },
      {
        path: "notification-management",
        element: <NotificationManagement />,
      },
      {
        path: "notification-management/create",
        element: <RecipientFormContainer template="Create" />,
      },
      {
        path: "notification-management/confirmation",
        element: <ConfirmationScreen />,
      },
      {
        path: "notification-management/:id",
        element: <div />,
      },
      {
        path: "notification-management/:id/edit",
        element: <RecipientFormContainer template="Edit" />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
