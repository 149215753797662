import { SubServiceTier } from "types";

function stateFormatter(state: string, targetState: string) {
  if (!targetState) {
    const stateOutput = {
      inactive: "Inactive",
      pendingActivation: "Inactive",
      active: "Active",
      blocked: "Blocked",
      deactivated: "Deactivated",
    }[state];

    return stateOutput || "Error";
  }

  if (state === "pendingActivation" && targetState === "active") {
    return "Pending Activation";
  }

  if (state === "active" && targetState === "blocked") {
    return "Pending Block";
  }

  if (state === "blocked" && targetState === "active") {
    return "Pending Unblock";
  }

  if ((state === "active" || state === "blocked") && targetState === "deactivated") {
    return "Pending Deactivation";
  }

  return "Error";
}

type TDeviceFormatterProps = { isAssigned: boolean; device: { make: string; model: string } };

function deviceFormatter({ isAssigned, device }: TDeviceFormatterProps) {
  const divText = isAssigned ? `${device.make} ${device.model}` : "Unassigned";
  return divText;
}

function descriptionFormatter(description: string) {
  const divText = description ? description : "Add a description...";
  return divText;
}

function serviceTierFormatter(serviceTier: SubServiceTier) {
  const serviceTierValue = serviceTier.value;

  const output = {
    default: {
      value: "Default",
      unit: "(0.2 Mbps DL / best effort UL)",
    },
    low: {
      value: "Low",
      unit: "(0.5 Mbps DL/UL)",
    },
    medium: {
      value: "Medium",
      unit: "(1 Mbps DL/UL)",
    },
    high: {
      value: "High",
      unit: "(5 Mbps DL/UL)",
    },
    processing: {
      value: "Processing",
      unit: "",
    },
  }[serviceTierValue];

  return output || { value: "Error", unit: "Error" };
}

export const formatters = {
  state: stateFormatter,
  device: deviceFormatter,
  description: descriptionFormatter,
  serviceTier: serviceTierFormatter,
};
