import { Column, Grid } from "@able/react";
import { useTenancyData } from "hooks";
import { Helmet } from "react-helmet-async";
import { useOutletContext } from "react-router-dom";
import { Subscription } from "types";
import { ServiceInformation } from "./Common/ServiceInformation";
import "./ConfigurationContainer.scss";
import { ServiceConfiguration } from "./ServiceConfiguration";

export const ConfigurationContainer = () => {
  const { subscription } = useOutletContext<{ subscription: Subscription }>();
  const { data: tenancies, isLoading } = useTenancyData();
  const tenantData = tenancies && tenancies.length > 0 ? tenancies[0] : undefined;

  if (isLoading) {
    return <div className="loading">Loading Data</div>;
  }

  if (!tenantData) return <div>No Tenant data to display.</div>;

  return (
    <Grid>
      <Helmet>
        <title>Service Configuration - Telstra Dedicated Networks</title>
      </Helmet>
      <Column cols={4} vxs={12} vsm={5} vmd={4} blg={5}>
        <ServiceInformation tenancyInfo={tenantData} subscription={subscription} />
      </Column>
      <Column className="config-col" cols={6} vxs={12} vsm={7} vmd={7} blg={7}>
        <ServiceConfiguration subscription={subscription} />
      </Column>
    </Grid>
  );
};
