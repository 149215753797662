import { Icon } from "@able/react";
import { ableUrl } from "utils/constants";
import { formatters } from "utils/formatters";
import { Subscription } from "types";
import "./styles/performance-information.scss";

const PerformanceInformation = ({ subscription }: { subscription: Subscription }) => {
  const { serviceNumber, subscriptionId, serviceTier } = subscription;

  return (
    <dl className="flex-dl" aria-label="subscription information">
      <div className="information-container">
        <dt>
          <Icon className="information-icon" size="32" icon="Mobile" developmentUrl={ableUrl} />
          Subscription ID
        </dt>
        <dd>{subscriptionId || "Pending Allocation"}</dd>
      </div>
      <div className="information-container">
        <dt>
          <Icon className="information-icon" size="32" icon="Mobile" developmentUrl={ableUrl} />
          Service number
        </dt>
        <dd>{serviceNumber || "Pending Allocation"}</dd>
      </div>
      <div className="information-container">
        <dt>
          <Icon className="information-icon" size="32" icon="SIM" developmentUrl={ableUrl} />
          Service tier
        </dt>
        <dd>{formatters.serviceTier(serviceTier).value || "Pending Allocation"}</dd>
      </div>
      <div className="information-container">
        <dt>
          <Icon className="information-icon" size="32" icon="MobilePostpaid" developmentUrl={ableUrl} />
          Activation date
        </dt>
        <dd>This field does not exist.</dd>
      </div>
    </dl>
  );
};

export default PerformanceInformation;
