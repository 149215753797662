import { ActionButton, Icon, Select } from "@able/react";
import { HyperLinkButton } from "components/ui/Buttons";
import dayjs from "dayjs";
import { useState } from "react";
import { ableUrl } from "utils/constants";
import { getPeriodFromDates, getPeriodFromRange } from "./functions";
import { CallbackData, Ranges } from "./types";

import "./NativeDatePicker.scss";

class DateError extends Error {
  public readonly type;
  constructor({ type, message }: { type: "start-date" | "end-date" | ""; message: string }) {
    super(message);
    this.type = type;
  }
}

const defaultError = new DateError({ type: "", message: "" });

function validateTimeRange(startDate: string, endDate: string) {
  // If start date was not selected.
  if (!startDate) {
    document.getElementById("native-start-date")?.focus();
    throw new DateError({ type: "start-date", message: "Please select valid start date." });
  }

  // If end date is not present or invalid date.
  if (!endDate) {
    document.getElementById("native-end-date")?.focus();
    throw new DateError({ type: "end-date", message: "Please select valid end date." });
  }

  // If start date is greater than end date.
  if (dayjs(startDate) >= dayjs(endDate)) {
    document.getElementById("native-start-date")?.focus();
    throw new DateError({ type: "start-date", message: "Start date must be before end date." });
  }

  if (dayjs(endDate) >= dayjs()) {
    document.getElementById("native-end-date")?.focus();
    throw new DateError({ type: "end-date", message: "Max end date is today." });
  }
}

type NativeDatePickerType = {
  callback: (data: CallbackData) => void;
};

export const NativeDatePicker = ({ callback }: NativeDatePickerType) => {
  // This is the current date FOR THE USER. This is not UTC time.
  const today = dayjs().format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>(today);
  const [selectValue, setSelectValue] = useState<Ranges>("1-day");
  const [error, setError] = useState<DateError>(defaultError);

  function onSelectChange(e: any) {
    e.preventDefault();
    setError(defaultError);
    setSelectValue(e.target.value);
  }

  function clearFilters() {
    setError(defaultError);
    setStartDate("");
    setEndDate(today);
    setSelectValue("1-day");
  }

  function submitFilters() {
    // We need some validation in here.
    if (selectValue === "custom") {
      try {
        validateTimeRange(startDate, endDate);
        const period = getPeriodFromDates(startDate, endDate);
        const dateTimeFrom = dayjs(startDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        const dateTimeTo = dayjs(endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

        callback({ period: period, dateTimeFrom, dateTimeTo });
      } catch (e: any) {
        setError(e);
      }
    } else {
      const { period, length, unit } = getPeriodFromRange(selectValue);
      const timeTo = dayjs().utc().subtract(1, "minute");
      callback({
        period: period,
        dateTimeFrom: timeTo.subtract(length, unit).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        dateTimeTo: timeTo.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
    }
  }
  const startMin = dayjs("01/01/2024").format("YYYY-MM-DD");
  const startMax = dayjs(endDate).subtract(1, "day").format("YYYY-MM-DD");
  const endMin = dayjs("01/02/2024").format("YYYY-MM-DD");
  const endMax = today;

  return (
    <div className="top-row">
      <Select
        className="select"
        id="date-selector"
        label="Date range"
        size="Auto"
        options={[
          { value: "1-day", label: "Last 24 Hours" },
          { value: "3-day", label: "Last 3 Days" },
          { value: "7-day", label: "Last 7 Days" },
          { value: "30-day", label: "Last 30 Days" },
          { value: "custom", label: "Custom range" },
        ]}
        events={{ onChange: onSelectChange }}
        value={selectValue}
        developmentUrl={ableUrl}
        allowEmpty={false}
      />
      {selectValue === "custom" ? (
        <>
          <div className="able-TextField select">
            <label>Start date</label>
            <input
              aria-invalid={error.type === "start-date"}
              id="native-start-date"
              type="date"
              min={startMin}
              max={startMax}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                setError(defaultError);
              }}
            />
            {error.type === "start-date" ? (
              <p>
                <Icon icon="Error" developmentUrl={ableUrl} />
                {error.message}
              </p>
            ) : null}
          </div>
          <div className="able-TextField select">
            <label>End date</label>
            <input
              aria-invalid={error.type === "end-date"}
              id="native-end-date"
              type="date"
              min={endMin}
              max={endMax}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                setError(defaultError);
              }}
            />
            {error.type === "end-date" ? (
              <p>
                <Icon icon="Error" developmentUrl={ableUrl} />
                {error.message}
              </p>
            ) : null}
          </div>
        </>
      ) : null}

      <div className="button-container" style={{ marginTop: !error.type ? "1.5rem" : "" }}>
        <div>
          <HyperLinkButton
            id="clear-filters"
            type="button"
            about="clears filter values"
            resize={true}
            onClick={clearFilters}
            content="Clear"
          />
        </div>
        <div>
          <ActionButton
            id="apply-filters"
            label="Apply"
            variant="LowEmphasis"
            element="button"
            events={{ onClick: submitFilters }}
          />
        </div>
      </div>
    </div>
  );
};
