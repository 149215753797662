import { Tabs } from "components/Tabs";
import { PageHeading } from "components/ui/PageHeading";
import { Outlet, useMatch } from "react-router-dom";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";

export function UtilisationContainer() {
  const matchesSingleSector = useMatch("/network/utilisation/single-sector");

  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);

  return (
    <div>
      <PageHeading content="AAB Sector Utilisation" />
      <Tabs
        defaultSelected={matchesSingleSector ? 0 : 1}
        tabs={[
          {
            label: "Single sector",
            to: "single-sector",
            content: <Outlet />,
          },
          {
            label: "Multiple sectors",
            to: "multi-sector",
            content: <Outlet />,
          },
        ]}
      />
    </div>
  );
}
