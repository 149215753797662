import { Column, Grid, MessageInline, Select, Spacing } from "@able/react";
import { Chart } from "components/Charts/Chart";
import { NativeDatePicker } from "components/DatePicker";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { MinMaxInfo } from "components/MinMaxInfo";
import { ChangeEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { ableUrl, serviceTierSelectOptions } from "utils/constants";
import { timeRangeToReadable } from "utils/formatters/date-formatters";
import { formatUnits } from "utils/formatters/network-units";
import { tooltipFormatter } from "../tooltip-formatter";
import { useSectorInfo, useSectors } from "./function";
import "./SectorThroughput.scss";
import { SectorThroughputFormData as FormData } from "./types";

function calculateAnalytics(data: number[]) {
  const max = data.reduce((a, b) => Math.max(a, b), -Infinity);
  const average = data.reduce((a, b) => a + b, 0) / data.length;

  return { max, average };
}

export function SectorThroughput() {
  const { sectors, isLoading: sectorsLoading } = useSectors();
  const { data, error, isPending, params, fetchSectorData, lastFetched } = useSectorInfo();

  // The form needs to be pre-filled with the query params if they exist. Or use default values.
  const form = useForm<FormData>({ defaultValues: params });

  const sectorOptions = sectors
    ? sectors
        ?.map((sector) => ({ label: sector.sectorName, value: sector.sectorName }))
        .toSorted((a, b) => a.label.localeCompare(b.label))
    : [];

  // Calculate Analytics for display.
  const duration = data?.length >= 1 && timeRangeToReadable(params.dateTimeFrom, params.dateTimeTo);
  const throughputStats = data?.length >= 1 && calculateAnalytics(data.map(({ throughput }) => throughput.downLink));
  const volumeStats = data?.length >= 1 && calculateAnalytics(data.map(({ volume }) => volume.downLink));
  const period = params.period === "fifteenMin" ? "15 min" : params.period;

  return (
    <>
      <Spacing top="spacing3x" bottom="spacing3x">
        <MessageInline variant="Information" developmentUrl={ableUrl}>
          {!params.sectorId ? (
            "No query parameters set."
          ) : (
            <span>
              Currently viewing Sector <strong>{params.sectorId}</strong> with Service tier:{" "}
              <strong>{params.serviceTier}</strong>, between <strong>{params.dateTimeFrom}</strong> and{" "}
              <strong>{params.dateTimeTo}</strong>.
            </span>
          )}
        </MessageInline>
      </Spacing>

      <form>
        <div className="top-row">
          {sectorsLoading ? (
            <p>Loading sectors</p>
          ) : (
            <Controller
              name="sectorId"
              control={form.control}
              render={({ field: { onChange, ...rest } }) => (
                <Select
                  className="select"
                  id="sector-select"
                  label="Sector"
                  options={sectorOptions}
                  events={{ onChange: (e: ChangeEvent<HTMLSelectElement>) => onChange(e) }}
                  {...rest}
                />
              )}
            />
          )}
          <Controller
            name="serviceTier"
            control={form.control}
            render={({ field: { onChange, ...rest } }) => (
              <Select
                className="select"
                id="service-tier-select"
                label="Service tier"
                options={serviceTierSelectOptions}
                allowEmpty={false}
                events={{ onChange: (e: ChangeEvent<HTMLSelectElement>) => onChange(e) }}
                {...rest}
              />
            )}
          />
        </div>
        <Spacing bottom="spacing4x" />
        <NativeDatePicker
          callback={(data) => {
            form.setValue("dateTimeFrom", data.dateTimeFrom);
            form.setValue("dateTimeTo", data.dateTimeTo);
            fetchSectorData(form.getValues());
          }}
        />
      </form>

      <Spacing bottom="spacing3x" />
      <ErrorMessage error={error} />

      {data?.length ? (
        <Grid>
          <p style={{ fontSize: "1.25rem", fontWeight: "bold" }}>Downlink throughput</p>
          <Column cols={3} vmd={4} vsm={12} vxs={12}>
            <p>Last fetched {lastFetched}</p>
            <MinMaxInfo
              data={throughputStats}
              units="kbps"
              descriptionAvg={`Average downlink throughput in a ${period} period over the past ${duration}`}
              descriptionMax={`Highest downlink throughput in a ${period} period over the past ${duration}`}
            />
          </Column>
          <Column cols={9} vmd={8} vsm={12} vxs={12}>
            <Chart
              chartOptions={{
                isLoading: isPending,
                paraTitle: `Avg ${period} downlink throughput over the past ${duration}`,
                xAxis: {
                  type: "datetime",
                  min: new Date(params.dateTimeFrom).getTime(),
                  max: new Date(params.dateTimeTo).getTime(),
                },
                yAxis: {
                  title: { text: "Throughput", margin: 18 },
                  lineWidth: 1,
                  lineColor: "#757575",
                  gridLineDashStyle: "Dash",
                  gridLineColor: "#707070",
                  labels: {
                    formatter(ctx) {
                      return formatUnits(ctx.value, data[0].throughput.units);
                    },
                  },
                },
                series: !data
                  ? []
                  : [
                      {
                        type: "column",
                        name: "Downlink throughput",
                        color: "#0364d2",
                        pointWidth: 8,
                        legendSymbol: "lineMarker",
                        data: data.map(({ timestamp, throughput }) => [
                          new Date(timestamp).getTime(),
                          Number(throughput.downLink.toFixed(2)),
                        ]),
                        events: { legendItemClick: (e) => e.preventDefault() },
                      },
                    ],
                tooltip: {
                  useHTML: true,
                  formatter(this) {
                    return tooltipFormatter({ ctx: this, units: data[0].throughput.units });
                  },
                },
              }}
            />
          </Column>
          <p style={{ fontSize: "1.25rem", fontWeight: "bold" }}>Downlink volume</p>
          <Column cols={3} vmd={4} vsm={12} vxs={12}>
            <p>Last fetched {lastFetched}</p>
            <MinMaxInfo
              data={volumeStats}
              units="bytes"
              descriptionAvg={`Average downlink volume in a ${period} period over the past ${duration}`}
              descriptionMax={`Highest downlink volume in a ${period} period over the past ${duration}`}
            />
          </Column>
          <Column cols={9} vmd={8} vsm={12} vxs={12}>
            <Chart
              chartOptions={{
                isLoading: isPending,
                paraTitle: `Avg ${period} downlink volume over the past ${duration}`,
                xAxis: {
                  type: "datetime",
                  min: new Date(params.dateTimeFrom).getTime(),
                  max: new Date(params.dateTimeTo).getTime(),
                },
                yAxis: {
                  title: { text: "Volume", margin: 18 },
                  lineWidth: 1,
                  lineColor: "#757575",
                  gridLineDashStyle: "Dash",
                  gridLineColor: "#707070",
                  labels: {
                    formatter(ctx) {
                      return formatUnits(ctx.value, data[0].volume.units);
                    },
                  },
                },
                series: !data
                  ? []
                  : [
                      {
                        type: "column",
                        name: "Downlink volume",
                        color: "#0364d2",
                        pointWidth: 8,
                        legendSymbol: "lineMarker",
                        data: data.map(({ timestamp, volume }) => [
                          new Date(timestamp).getTime(),
                          Number(volume.downLink.toFixed(2)),
                        ]),
                        events: { legendItemClick: (e) => e.preventDefault() },
                      },
                    ],
                tooltip: {
                  useHTML: true,
                  formatter(this) {
                    return tooltipFormatter({ ctx: this, units: data[0].volume.units });
                  },
                },
              }}
            />
          </Column>
        </Grid>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p>{isPending ? "Loading data" : "Submit a query to view Sector Data"}</p>
        </div>
      )}
    </>
  );
}
