import { SyntheticEvent, useEffect, useState } from "react";
import "./Avatar.scss";

const defaultColors = [
  "#001e82",
  "#0064d2",
  "#02bff2",
  "#35237f",
  "#5e50bf",
  "#a0168c",
  "#f05a00",
  "#00AA55",
  "#009FD4",
  "#B381B3",
  "#939393",
  "#E3BC00",
  "#D47500",
  "#DC2A2A",
];

type AvatorProps = {
  borderRadius?: string;
  className?: string;
  colors?: string[];
  id?: string;
  src?: string;
  mode: "light" | "dark";
  name: string;
  randomColor?: boolean;
  size: string;
  srcset?: string;
  style?: Record<string, any>;
  onClick?: (event: SyntheticEvent) => void;
};

export const Avatar = (props: AvatorProps) => {
  let {
    borderRadius = "100%",
    src,
    srcset,
    name,
    colors = defaultColors,
    size,
    style,
    className,
    randomColor = true,
  } = props;

  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    if (randomColor) {
      setColorIndex(Math.floor(Math.random() * 7));
    } else {
      setColorIndex((name.charCodeAt(0) - 64) % 12);
    }
  }, [name, randomColor]);

  const imageStyle: React.CSSProperties = {
    display: "block",
    borderRadius,
  };

  const innerStyle: React.CSSProperties = {
    textAlign: "center",
    borderRadius,
  };

  if (size) {
    imageStyle.width = innerStyle.width = innerStyle.maxWidth = size + "px";
    imageStyle.height = innerStyle.height = innerStyle.maxHeight = size + "px";
    innerStyle.fontSize = parseInt(size) / 2 + "px";
  }

  let randomHex = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getInitials = (name: string) => {
    const allNames = name.trim().split(" ");
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
    return initials;
  };

  let inner,
    classes = [className, "tl-avatar"];

  if (src || srcset) {
    inner = <img className="avatar--img" style={imageStyle} src={src} srcSet={srcset} alt={name} />;
  } else {
    innerStyle.backgroundColor = colors[colorIndex] || randomHex();
    innerStyle.color = "#fff";
    inner = getInitials(name);
  }

  if (props.mode === "light") {
    innerStyle.color = colors[colorIndex];
    innerStyle.backgroundColor = colors[colorIndex] + "33";
  }

  const handleClick = (e: SyntheticEvent) => {
    if (props?.onClick) {
      props?.onClick(e);
    }
  };

  return (
    <div aria-hidden="true" className={classes.join(" ")} style={style} onClick={handleClick} id={props.id}>
      <div className="tl-avatar-inner" style={innerStyle}>
        <span>{inner}</span>
      </div>
    </div>
  );
};
