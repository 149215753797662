import * as Highcharts from "highcharts";
import { formatTimestamp } from "utils/formatters/date-formatters";

type FormatterArgs = {
  context: Highcharts.TooltipFormatterContextObject;
  plotType: string;
  isPercentage: boolean;
  thresholdArray: number[];
};

export function tooltipFormatter({ context, plotType, isPercentage, thresholdArray }: FormatterArgs) {
  let tooltip;

  // We can get the xAxis value (in this case the name) as follows:
  const sectorName = context.x;
  // We can get the x index position from the context as follows:
  const xIndex = context.point.index;

  // We can then look up the timestamp from the hidden series:
  if (context.series.xAxis.series.length === 5) {
    // @ts-ignore because the yData attribute is hidden by Highcharts but does exist.
    const timestampSeries = context.series.xAxis.series[4].yData as number[]; // 4 is the 4th series added.
    const timestamp = formatTimestamp(timestampSeries[xIndex], "MMM DD");

    if (context.points && context.points.length >= 3) {
      const used = context.points[0];
      const avail = context.points[1];
      const exceeded = context.points[2];

      if (typeof used.y === "number" && typeof avail.y === "number" && typeof exceeded.y === "number") {
        // Calculating the used and subscribed amounts.
        const subscribedMbps = Number((exceeded.y === 0 ? used.y + avail.y : used.y).toFixed(2));
        const totalUsed = Number((exceeded.y === 0 ? used.y : used.y + exceeded.y).toFixed(2));
        const available = Number(avail.y.toFixed(2));
        const color = exceeded.y ? exceeded.color : used.color;

        // Formatting.
        const heading = `${sectorName} ${plotType} AAB bandwidth utilisation`;
        const formattedUsed = `${totalUsed}${isPercentage ? "% of subscribed amount used" : " Mbps used"}`;
        const formattedAvail = `${available}${isPercentage ? "% of subscribed amount available" : " Mbps available"}`;
        const thresholdValue = thresholdArray[xIndex];
        const formattedSubscribed = `${subscribedMbps}${isPercentage ? `% subscribed is ${thresholdValue} Mbps` : " Mbps subscribed"}`;

        tooltip = `
          <div style="padding:0.25rem;font-family:'Telstra Text Variable'">
            <div style="margin-bottom:0.35rem">${heading}</div>
            <div style="display:flex;flex-direction:column;gap:0.5rem">
              <div style="display:flex;align-items:center;gap:0.6rem">
                <span style="background-color:${color};width:30px;height:2px"></span>
                <span style="display:inline-block">${formattedUsed}</span>
              </div>
              <div style="display:flex;align-items:center;gap:0.6rem">
                <span style="background-color:${avail.color};width:30px;height:2px"></span>
                <span style="display:inline-block">${formattedAvail}</span>
              </div>
              <div style="display:flex;align-items:center;gap:0.6rem;margin-bottom:0.6rem">
                <span style="background-color:black;width:30px;height:2px"></span>
                <span style="display:inline-block">${formattedSubscribed}</span>
              </div>
            </div>
            <span>${timestamp}</span>
          </div>
        `;
      }
    }
  }

  return tooltip;
}
