import { Icon } from "@able/react";
import { ableUrl } from "utils/constants";
import styles from "./styles/inputError.module.scss";

type Props = {
  isError: boolean;
  message: string;
  fieldName: string;
};

/**
 *  We use Able Text Field component for inputs in forms, which has a 'inputHelper' prop with predefined types.
 *  Using this prop overrides any specific settings you provide the component, e.g. 'invalidInputText' which
 *  prevents you from using custom error messages for any additional validation logic you are doing.
 *  E.g. validating the input against an API to check if it exists in a database.
 *
 *  This component aims to replicate the invalid input text rendered by Able and apply your own text using React Hook Form.
 *
 *  NOTE: The id on the <p> is required for accessibility and mimics Abls component.
 */
function InputError({ isError, message, fieldName }: Props) {
  return isError ? (
    <p id={`${fieldName}-field__error-text`} className={styles.error}>
      <Icon icon="Error" size="24" developmentUrl={ableUrl} />
      {message}
    </p>
  ) : (
    <></>
  );
}

export default InputError;
