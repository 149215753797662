import { formatUnits } from "utils/formatters/network-units";
import "./MinMaxInfo.scss";

interface Props {
  data: { max: number; average: number } | false;
  units: string;
  descriptionMax: string;
  descriptionAvg: string;
}

export const MinMaxInfo = ({ data, units, descriptionMax, descriptionAvg }: Props) => {
  // This might not be required because MinMaxInfo won't render if there is no data further up the tree.
  if (!data) {
    return (
      <div className="no-data-container">
        <div className="mm-header">Data Unavailable</div>
      </div>
    );
  }

  const { max, average } = data;

  return (
    <dl className="min-max-dl">
      <dt>
        <span>{formatUnits(max, units, 2)}</span>
      </dt>
      <dd>{descriptionMax}</dd>
      <dt>
        <span>{formatUnits(average, units, 2)}</span>
      </dt>
      <dd>{descriptionAvg}</dd>
    </dl>
  );
};
