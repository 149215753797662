import { create } from 'zustand'
import { createAutofocusSlice } from "./autofocus-slice";
import { createInitializeSlice } from "./initialization-slice";
import { createLoginSlice } from "./login-slice";
import { createTokenSlice } from "./token-slice";

export const useAppStore = create((set, get) => ({
  ...createInitializeSlice(set, get),
  ...createLoginSlice(set, get),
  ...createTokenSlice(set, get),
  ...createAutofocusSlice(set, get),
}));
