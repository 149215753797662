import NotificationManagement from "./NotificationManagement";

export default NotificationManagement;
export { ConfirmationScreen } from "./ConfirmationScreen/ConfirmationScreen";
export {
  RecipientForm,
  createNotificationRecipient,
  deleteNotificationRecipient,
  updateNotificationRecipient,
} from "./RecipientForm";
