import { MessageInline, Spacing, TextField } from "@able/react";
import { MessageInlineVariants } from "@able/react/dist/MessageInline";
import { zodResolver } from "@hookform/resolvers/zod";
import { HyperLinkButton } from "components/ui/Buttons";

import { useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { patchSubscriptionById } from "services/api/inventory-service";
import { Subscription } from "types";
import { ableUrl } from "utils/constants";
import { isAuthorised } from "utils/tokens";
import { TConfigFieldsValues, configurableFieldsSchema } from "./formSchema";

import { queryClient } from "index";
import "./ConfigurableFields.scss";

export const ConfigurableFields = ({ subscription }: { subscription: Subscription }) => {
  const authorised = isAuthorised("ADMIN");
  const [editing, setEditing] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<{ variant: MessageInlineVariants; description: string } | null>(null);
  const editButtonRef = useRef<HTMLButtonElement | null>(null);

  const defaultValues = {
    name: subscription?.name || "",
    description: subscription?.description || "",
  };

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<TConfigFieldsValues>({
    resolver: zodResolver(configurableFieldsSchema),
    defaultValues,
  });

  const formSubmit: SubmitHandler<TConfigFieldsValues> = (data, e) => {
    e?.preventDefault();
    const patchFields = Object.entries({
      name: { value: data.name },
      description: { value: data.description },
    });

    // If there are no errors, send the request.
    patchSubscriptionById(subscription, patchFields)
      .then(() => {
        setMessage({ variant: "Success", description: "Subscription successfully updated!" });
        queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
        setEditing(false);
      })
      .catch(() => {
        setMessage({
          variant: "Error",
          description: "An error occurred while updating the subscription. Please try again later.",
        });
      });
  };

  return (
    <>
      {showMessage && message && (
        <>
          <MessageInline variant={message.variant} developmentUrl={ableUrl} description={message.description} />
          <span role="alert" className="visually-hidden">
            {message.description}
          </span>
        </>
      )}
      {editing ? (
        <Spacing bottom="spacing3x">
          <form className="config-container" onSubmit={handleSubmit(formSubmit)}>
            <div>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoFocus={true}
                    id="sub-reference"
                    label="Your Reference"
                    helpText={`Nickname your subscription. Characters remaining: ${32 - getValues("name").length}`}
                    developmentUrl={ableUrl}
                    invalid={!!errors.name}
                    invalidInputText={errors.name?.message}
                    events={{ onKeyDown: (e: KeyboardEvent) => e.key === "Enter" && e.preventDefault() }}
                    {...field}
                  />
                )}
              />
              <Spacing bottom="spacing2x" />
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="sub-description"
                    label="Description / location"
                    helpText={`Describe your subscription. Characters remaining: ${
                      32 - getValues("description").length
                    }`}
                    developmentUrl={ableUrl}
                    invalid={!!errors.description}
                    invalidInputText={errors.description?.message}
                    events={{ onKeyDown: (e: KeyboardEvent) => e.key === "Enter" && e.preventDefault() }}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="config-button-container">
              <HyperLinkButton
                content="Cancel"
                resize={false}
                onClick={() => {
                  setEditing(false);
                  reset();
                  console.log(editButtonRef.current);
                  editButtonRef.current?.focus();
                }}
              />
              <HyperLinkButton
                content="Save"
                resize={false}
                type="submit"
                onClick={() => {
                  editButtonRef.current?.focus();
                }}
              />
            </div>
          </form>
        </Spacing>
      ) : (
        <Spacing bottom="spacing1nHalf">
          <div className="config-container">
            <div>
              <h3 className="config-headers">Your reference</h3>
              <p style={{ marginTop: 0 }}>{subscription?.name}</p>
              <h3 className="config-headers">Description / location</h3>
              <p style={{ marginTop: 0 }}>{subscription?.description}</p>
            </div>
            <div>
              <HyperLinkButton
                content="Edit"
                disabled={!authorised}
                onClick={() => {
                  setEditing(true);
                  setShowMessage(false);
                }}
              />
            </div>
          </div>
        </Spacing>
      )}
    </>
  );
};
