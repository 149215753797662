import React, { ReactNode } from "react";

export type BreadcrumbItem = { to: string; label: string };
type BreadcrumbContext = {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<BreadcrumbItem[]>>;
};

export const Context = React.createContext<BreadcrumbContext>({ breadcrumbs: [], setBreadcrumbs: () => undefined });

export const BreadCrumbProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>([]);

  return <Context.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</Context.Provider>;
};
