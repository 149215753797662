import { useAppStore } from "services/state/store/app-store";
import "./styles.scss";

type Props = {
  className?: string;
};

export const SignOutButton = ({ className }: Props) => {
  const logout = useAppStore((state) => state.logout);
  const signOutHandler = () => logout();

  return (
    <button className={`signout-button ${className}`} onClick={signOutHandler} type="button">
      Sign Out
    </button>
  );
};
