import { useContext, useEffect } from "react";
import { BreadcrumbItem, Context } from "services/state/BreadcrumbProvider";

export const useBreadCrumbs = (crumbs?: BreadcrumbItem[]) => {
  const { breadcrumbs, setBreadcrumbs } = useContext(Context);

  // We only want to update the breadcrumbs if the context has changed.
  // Because objects have a ref in react when created, we can't compare refs.
  useEffect(() => {
    // If lengths are different.
    if (crumbs && breadcrumbs.length !== crumbs.length) {
      setBreadcrumbs(crumbs);
    }

    // If there are any other differences! Lengths are equal.
    if (crumbs) {
      const existing = JSON.stringify(breadcrumbs);
      const curr = JSON.stringify(crumbs);
      if (existing !== curr) setBreadcrumbs(crumbs);
    }
  }, [breadcrumbs, setBreadcrumbs, crumbs]);

  return { breadcrumbs };
};
