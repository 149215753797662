import { ActionButton, Picto, Success104 } from "@able/react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import "./ConfirmationScreen.scss";

export const ConfirmationScreen = () => {
  const location = useLocation();

  useBreadCrumbs([
    { label: "Dashboard", to: "/" },
    { label: "Notification Management", to: "/notification-management" },
  ]);

  // User should only be directed here after a successful submission.
  if (!location.state) {
    return <Navigate to="/" />;
  }

  const { action, recipient, email, mobileNumber, emailSelected, smsSelected } = location.state;

  const getMessage = () => {
    if (["updated", "deleted"].includes(action)) return null;
    if (!emailSelected && !smsSelected) return <p>Notifications have not been enabled for this recipient yet.</p>;
    return (
      <p>
        Notifications will be sent to{" "}
        {emailSelected && !smsSelected ? (
          <strong>{email}</strong>
        ) : !emailSelected && smsSelected ? (
          <strong>{mobileNumber}</strong>
        ) : (
          <>
            <strong>{email}</strong> and <strong>{mobileNumber}</strong>
          </>
        )}
      </p>
    );
  };

  return (
    <div className="flex-col">
      <Picto>
        <Success104 />
      </Picto>
      <h1>
        {recipient.substr(-1) === "s" ? `${recipient}'` : `${recipient}'s`} details have been {action}
      </h1>
      {getMessage()}
      <ActionButton
        className="current-recipients-button"
        element="Link"
        label="View current recipients"
        variant="HighEmphasis"
        to="/notification-management"
      />
      <Link style={{ marginTop: "1em" }} to={"/notification-management/create"}>
        Create another recipient
      </Link>
    </div>
  );
};
