import { Divider, Icon, MessageInline } from "@able/react";
import { InfoTooltip } from "components/InfoTooltip";
import { SectionHeading } from "components/ui/SectionHeading";
import { ableUrl } from "utils/constants";
import { formatters } from "utils";
import { IFilteredInventory } from "pages/services/types";
import { ServiceTier } from "../ServiceTier";
import { ServiceType } from "./ServiceType";

type THybridConfiguration = {
  subscription: IFilteredInventory;
};

function getServiceStatusIcon(state: string) {
  if (state === "active") return "Success";
  if (state === "blocked") return "Blocked";
  if (state === "deactivated") return "ServiceDown";
  else return "DeviceUnknown";
}

export const HybridConfiguration = ({ subscription }: THybridConfiguration) => {
  const { state, targetState } = subscription;
  const formattedState = formatters.state(state, targetState);

  return (
    <>
      <ServiceType subscription={subscription} type="Hybrid" />
      <SectionHeading headingContent="Service Status" headingType="h3">
        <InfoTooltip
          id="service-status"
          label="Service Status"
          description="The state of your current service, can be Active, Blocked or Processing."
        />
      </SectionHeading>
      <div style={{ display: "flex", alignItems: "center", margin: "0 0 18px" }}>
        <Icon icon={targetState ? "InProgress" : getServiceStatusIcon(state)} developmentUrl={ableUrl} />
        <p style={{ fontSize: 20, margin: 0 }}>{formattedState}</p>
      </div>
      {targetState && (
        <MessageInline
          variant="Information"
          description={`Your change from ${state} to ${targetState} is being processed and will take effect within 24 hours. Please contact your Telstra representative for more information.`}
          developmentUrl={ableUrl}
        />
      )}

      <Divider />
      <ServiceTier subscription={subscription} />
    </>
  );
};
