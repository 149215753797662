import axios, { AxiosRequestConfig } from "axios";
import { decodeToken, getTokenFromSessionStorage } from "utils";
import { TdnResponse } from "types";
import { getBasePath } from "./common";

export async function fetchNotificationRecipients(
  tenantId: string,
  notificationRecipientId?: string | undefined,
  options?: AxiosRequestConfig
) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  let url = `${basePath}/api/tdn/account/v1/tenants/${tenantId}/notification-recipients`;
  if (notificationRecipientId) {
    url += `/${notificationRecipientId}`;
  }

  const response = await axios({
    method: "GET",
    url: url,
    headers: { authorization: `Bearer ${token}` },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

export async function fetchTenancyData() {
  const token = getTokenFromSessionStorage("id_token");
  const decodedToken = decodeToken();
  const basePath = getBasePath();

  try {
    // Fix the types here
    const response = await axios.get<any>(`${basePath}/api/tdn/account/v1/tenants`, {
      params: { cidn: decodedToken?.cidn },
      headers: { authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchMicaData(tenancyId: string, options?: AxiosRequestConfig) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios({
    method: "GET",
    url: `${basePath}/api/tdn/account/v1/tenants/${tenancyId}/provisioning/config`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    ...options,
  });

  return response.data;
}

type Sector = {
  sectorName: string;
  AAB_Mbps: number;
  latitude: number;
  longitude: number;
  bearing: number;
  beamwidth: number;
};

type GeographicBoundary = {
  properties: {
    FID: number;
    description: string;
  };
  geometry: {
    type: string;
    coordinates: [];
  };
  type: string;
};
export type ServiceAreaData = {
  serviceAreaName: string;
  serviceAreaId: string;
  supportTier: string;
  stateTerritory: string;
  state: string;
  description: string;
  lastStateChange: string;
  sectors: Sector[];
  geographicBoundary: GeographicBoundary;
  id: string;
};

export async function fetchServiceAreaData(serviceAreaName: string) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios.get<TdnResponse<ServiceAreaData>>(
    `${basePath}/api/tdn/account/v1/service-areas/${serviceAreaName}`,
    { headers: { authorization: `Bearer ${token}` } }
  );

  return response.data.data;
}
