import { IconButton } from "@able/react";
import { ableUrl } from "utils/constants";
import { forwardRef } from "react";

type Props = {
  isOpen: boolean;
  onClickHandler: () => void;
};

type Ref = HTMLButtonElement;

export const BurgerButton = forwardRef<Ref, Props>(({ isOpen, onClickHandler }, ref) => {
  return (
    <IconButton
      ref={ref}
      aria-expanded={isOpen}
      aria-label="Navigation Menu"
      aria-controls="navigationMenu"
      // @ts-expect-error
      icon={isOpen ? "Close" : "Menu"}
      developmentUrl={ableUrl}
      events={{
        onClick: () => {
          onClickHandler();
        },
      }}
    />
  );
});
