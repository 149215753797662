import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { router } from "router";

import { BreadCrumbProvider } from "services/state/BreadcrumbProvider";
import "./global.scss"; // styles
import "./utils/dayjs"; // re-exports dayjs with plugins.
import "./utils/highcharts"; // re-exports highcharts with plugins.

export const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BreadCrumbProvider>
          <RouterProvider router={router} />
        </BreadCrumbProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </StrictMode>
);
