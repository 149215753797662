declare global {
  interface Window {
    _env_: any;
  }
}

export const getProperty = (key: string, defValue: string | boolean) => {
  // Injected variables should take precendence over
  // .env file variables, using OR operator will lazily evaluate
  // with the leftmost statement short circuiting if true.

  let value = window._env_["REACT_APP_" + key] || process.env["REACT_APP_" + key];

  if (typeof value === "undefined") return defValue;

  // Check true / false evaluation
  const normalisedValue = String(value.toUpperCase());
  if (normalisedValue === "TRUE") return true;
  if (normalisedValue === "FALSE") return false;

  return value;
};
