import { ActionButton } from "@able/react";
import { useQuery } from "@tanstack/react-query";
import { SearchCombobox } from "components/SearchCombobox";
import { PageHeading } from "components/ui/PageHeading";
import { useTenancyData } from "hooks";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { fetchSubscriptions } from "services/api/inventory-service";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { Subscription } from "types";
import { useDebouncedCallback } from "use-debounce";
import { TableContainer } from "./components/InventoryTable/TableContainer";
import { filterInventory } from "./components/helpers/filter-inventory";
import "./services-container.scss";

export type TFilterValues = {
  matches: { id: string; value: string }[];
  fieldValue: string;
};

const ServicesContainer = () => {
  // Fetch the subscriptions, and pass them the sort and filter values
  // const { sort, sortValues, filter, filterValues, refinedSubs, isLoading } = useRefinedInventory();
  const [filterValues, setFilterValues] = useState<TFilterValues>({ matches: [], fieldValue: "" });
  const [filteredInventory, setFilteredInventory] = useState<Subscription[]>([]);

  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);

  // Query
  const { data: tenancyArray } = useTenancyData();
  const tenancyId = tenancyArray?.[0].tenancyId;

  const { data: rawData, isLoading } = useQuery({
    queryKey: ["subscriptions", tenancyId],
    queryFn: () =>
      fetchSubscriptions(tenancyId).then((rawData) => {
        const { filteredInventory, matches } = filterInventory(rawData, filterValues.fieldValue);
        setFilterValues({ matches, fieldValue: "" });
        setFilteredInventory(filteredInventory);
        return rawData;
      }),
    enabled: !!tenancyId,
    refetchOnWindowFocus: false,
  });

  const debouncedFilter = useDebouncedCallback((fieldValue: string) => {
    const { filteredInventory, matches } = filterInventory(rawData, fieldValue);
    setFilterValues({ matches, fieldValue });
    setFilteredInventory(filteredInventory);
  }, 200);

  function filter(fieldValue: string) {
    debouncedFilter(fieldValue);
  }

  return (
    <main>
      <Helmet>
        <title>Services - Telstra Dedicated Network</title>
      </Helmet>
      <PageHeading content="Services" />
      <div className="search-container">
        <SearchCombobox
          title="Filter services"
          matches={filterValues.matches}
          fieldValue={filterValues.fieldValue}
          onFieldChange={filter}
          helperText="Services will be filtered as you type."
        />
        <ActionButton
          developmentUrl="/able-sprites.svg"
          element="Link"
          label="Create a Hybrid service"
          to="/services/create"
          variant="MediumEmphasis"
        />
      </div>
      <TableContainer data={filteredInventory} isLoading={isLoading} />
    </main>
  );
};

export default ServicesContainer;
