import { ButtonGroupSequence, MessageInline, RadioButton, RadioGroup, TextField } from "@able/react";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import InputError from "components/Forms/InputError";
import Subheading from "components/Forms/Subheading";
import { InfoTooltip } from "components/InfoTooltip";
import { PageHeading } from "components/ui/PageHeading";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { patchSubscriptionById } from "services/api/inventory-service";
import { activateHybridService, validateHybridServiceNumber } from "services/api/mica-service";
import { Subscription } from "types";
import { ableUrl } from "utils/constants";
import { decodeToken } from "utils/tokens";
import HybridServiceCreated from "./HybridServiceCreated";
import { createHybridServiceFormSchema } from "./schema";
import styles from "./styles/createHybridServiceForm.module.scss";
import { CreateHybridServiceFormValues, CustomError, MicaError } from "./types";

const totalSteps = 2;

function CreateHybridServiceForm({ subData, initialStep = 0 }: { subData: Subscription | null; initialStep?: 0 | 1 }) {
  // check the history.state here for values
  const [formStep, setFormStep] = useState<number>(initialStep);
  const [customErrors, setCustomErrors] = useState<CustomError>({});
  const [serviceNumberData, setServiceNumberData] = useState<Subscription | null>(subData);
  const navigate = useNavigate();
  const decodedToken = decodeToken();

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    clearErrors,
    trigger,
    handleSubmit,
  } = useForm<CreateHybridServiceFormValues>({
    resolver: zodResolver(createHybridServiceFormSchema),
    reValidateMode: "onChange",
    defaultValues: {
      serviceReference: subData?.name || "",
      serviceTierSpeed: subData?.serviceTier.value || "",
      serviceLocation: subData?.description || "",
      adaptiveMobilityNumber: subData?.serviceNumber || "",
    },
  });

  function onBackHandler() {
    if (formStep === 0) navigate("/services");
    else setFormStep((prevStep) => --prevStep);
  }

  async function onNextHandler() {
    clearFormErrors(); // Clear any pervious errors
    // Logic for step 0
    let isValid = false;
    if (formStep === 0) {
      isValid = await trigger(["adaptiveMobilityNumber"]);
      if (isValid && decodedToken?.cidn) {
        try {
          const serviceNumber = getValues("adaptiveMobilityNumber");
          const body = { cidn: decodedToken.cidn };
          const response = await validateHybridServiceNumber({ serviceNumber, body });
          setServiceNumberData(response.data);
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            const {
              meta: { message },
            } = error.response.data as MicaError;
            isValid = false;
            setCustomErrors({ ...customErrors, adaptiveMobilityNumber: { message } });
          } else {
            console.error(error);
          }
        }
      }
    }

    // Logic for step 1
    if (formStep >= 0 && formStep < totalSteps && isValid) {
      setFormStep((prevStep) => ++prevStep);
      clearFormErrors(); // Clear any validation errors from current step on step change
    }

    // Logic for step 2
    if (formStep === totalSteps - 1) {
      handleSubmit(onFormSubmit)();
    }
  }

  function clearFormErrors() {
    clearErrors();
    setCustomErrors({});
  }

  function resetForm() {
    setFormStep(0);
    reset();
    setServiceNumberData(null);
    clearFormErrors();
  }

  const onFormSubmit: SubmitHandler<CreateHybridServiceFormValues> = async (data) => {
    // Null check that should never occur, but just in case prevent API call.
    if (!serviceNumberData) {
      setCustomErrors({
        ...customErrors,
        submitError: { message: "Service number is missing and required." },
      });
      return;
    }

    const { tenancyId, id: subscriptionRef, serviceNumber } = serviceNumberData;

    const body = {
      tenancyId,
      subscriptionRef,
      serviceNumber,
      serviceTierTo: data.serviceTierSpeed,
    };

    try {
      // Entries/fields to be patched
      const patchFields = Object.entries({
        name: { value: data.serviceReference },
        description: { value: data.serviceLocation },
      });
      // If there are no errors, send the request.
      await patchSubscriptionById(serviceNumberData, patchFields);
      await activateHybridService({ body });
      setFormStep(totalSteps);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setCustomErrors({ ...customErrors, submitError: { message: error?.message } }); // Return Axios status code error message
      } else {
        setCustomErrors({
          ...customErrors,
          submitError: { message: "Unable to submit request, something went wrong." },
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form_container} onSubmit={handleSubmit(onFormSubmit)}>
        {formStep !== totalSteps && (
          <>
            <PageHeading content={"Create a Hybrid service"} />
            <Subheading title="Hybrid service details" alignItems="center">
              <InfoTooltip
                id="hybrid-service-details-tooltip"
                label="Hybrid service details Information"
                description="A Hybrid service can access your Telstra Dedicated Network using your Adaptive Mobility service as a base service."
              />
              {formStep === totalSteps - 1 && (
                <div className={styles.cancel}>
                  <Link to={"/services"}>Cancel</Link>
                </div>
              )}
            </Subheading>
          </>
        )}
        {formStep === 0 && (
          <div>
            <Controller
              name="adaptiveMobilityNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  id="adaptiveMobilityNumber-field"
                  label="Enter your Adaptive Mobility number"
                  helpText="This must be an active mobile number on an Adaptive Mobility plan owned by your Enterprise/Industry."
                  inputHelper="AustralianMobileNumber"
                  invalid={!!errors.adaptiveMobilityNumber?.message}
                  developmentUrl={ableUrl}
                  {...field}
                />
              )}
            />
            <InputError
              fieldName="adaptiveMobilityNumber"
              isError={!!customErrors["adaptiveMobilityNumber"]}
              message={customErrors["adaptiveMobilityNumber"]?.message}
            />
          </div>
        )}
        {formStep === 1 && (
          <>
            <Controller
              name="serviceReference"
              control={control}
              render={({ field }) => (
                <TextField
                  id="serviceReference-field"
                  label="Enter a reference for your Hybrid service"
                  helpText="Give this service a reference name, e.g. “My iPad”."
                  required={false}
                  invalid={!!errors.serviceReference}
                  invalidInputText={errors.serviceReference?.message}
                  developmentUrl={ableUrl}
                  {...field}
                />
              )}
            />
            <Controller
              name="serviceLocation"
              control={control}
              render={({ field }) => (
                <TextField
                  id="serviceLocation-field"
                  label="Enter a location or description for your Hybrid service"
                  helpText="Give this service a location or description, e.g. “My location”."
                  required={false}
                  invalid={!!errors.serviceLocation}
                  invalidInputText={errors.serviceLocation?.message}
                  developmentUrl={ableUrl}
                  {...field}
                />
              )}
            />
            <Controller
              name="serviceTierSpeed"
              control={control}
              render={({ field: { ref, value, onChange, ...rest } }) => (
                <RadioGroup
                  variant="Comfortable"
                  groupLabel="Select a service tier speed"
                  helpText="The default service tier speed is 0.2 Mbps download/best effort upload minimum. You can choose to set an optional service tier on top of the default service tier."
                  {...rest}
                >
                  <RadioButton
                    value="default"
                    label="Default"
                    description="0.2 Mbps download / best effort upload minimum"
                    checked={value === "default"}
                    events={{ onChange }}
                  />
                  <RadioButton
                    value="low"
                    label="Low"
                    description="0.5 Mbps download / 0.25 Mbps (default speed minimum)"
                    checked={value === "low"}
                    events={{ onChange }}
                  />
                  <RadioButton
                    value="medium"
                    label="Medium"
                    description="1 Mbps download / 1 Mbps upload (default speed minimum)"
                    checked={value === "medium"}
                    events={{ onChange }}
                  />
                  <RadioButton
                    value="high"
                    label="High"
                    description="5 Mbps download / 5 Mbps upload (default speed minimum)"
                    checked={value === "high"}
                    events={{ onChange }}
                  />
                </RadioGroup>
              )}
            />
          </>
        )}
        {formStep === 2 && (
          <HybridServiceCreated
            serviceNumber={getValues("adaptiveMobilityNumber")?.replace(/\s/g, "")}
            tierType={getValues("serviceTierSpeed")}
            resetSteps={resetForm}
          />
        )}
        {customErrors["submitError"]?.message && (
          <MessageInline variant="Error" developmentUrl={ableUrl} description={customErrors["submitError"]?.message} />
        )}
        {formStep !== 2 && (
          <ButtonGroupSequence
            className={styles.sequence}
            variant="Form"
            state={formStep < totalSteps - 1 ? "Normal" : "End"}
            backwardButtonEvents={{
              onClick: onBackHandler,
            }}
            forwardButtonEvents={{
              onClick: onNextHandler,
            }}
            backwardButtonLabel="Previous"
            forwardButtonLabel={formStep < totalSteps - 1 ? "Next" : "Submit"}
            developmentUrl={ableUrl}
          />
        )}
      </form>
    </div>
  );
}

export default CreateHybridServiceForm;
