import dayjs from "dayjs";

export function formatTimestamp(value: string | number, format: string) {
  return dayjs(value).utc().format(format);
}

export function timeRangeToReadable(startTime: string, endTime: string) {
  const start = dayjs(startTime);
  const end = dayjs(endTime);
  const msDiff = end.diff(start); // Difference in milliseconds.
  const hourlyDiff = Math.round(msDiff / (1000 * 60 * 60)); // Difference in hours.

  /**
   * Users can only select specific days whilst using a custom range, therefore the minimum unit is a day.
   * Therefore we can have 24hrs, 2 days, 3 days etc...
   */

  let difference = hourlyDiff;
  let unit = "hrs";

  if (hourlyDiff > 24) {
    const dailyDiff = Math.round(hourlyDiff / 24);
    difference = dailyDiff;
    unit = "days";

    if (dailyDiff > 31) {
      const monthlyDiff = Math.round(dailyDiff / 28);
      difference = monthlyDiff;
      unit = "months";
    }
  }

  return `${difference} ${unit}`;
}
