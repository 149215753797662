import { MessageInline } from "@able/react";
import { HyperLinkButton } from "components/ui/Buttons";

import { InfoTooltip } from "components/InfoTooltip";
import { SectionHeading } from "components/ui/SectionHeading";
import { ableUrl } from "utils/constants";
import { formatters } from "utils";
import { useNavigate } from "react-router-dom";
import { Subscription } from "types";

export const HyrbidActivation = ({ subscription }: { subscription: Subscription }) => {
  const { state, targetState, serviceTier } = subscription;
  const formattedState = formatters.state(state, targetState);
  const formattedTier = formatters.serviceTier(serviceTier);
  const navigate = useNavigate();

  return (
    <>
      <SectionHeading headingContent="Service Status" headingType="h3">
        <InfoTooltip
          id="service-status"
          label="Service status"
          description="You must activate this service before it can be used."
        />
        <div style={{ marginLeft: "auto" }}>
          {targetState ? null : (
            <HyperLinkButton content="Set up" onClick={() => navigate(`/services/create?subId=${subscription.id}`)} />
          )}
        </div>
      </SectionHeading>
      <p style={{ fontSize: 20, marginTop: 0 }}>{formattedState}</p>
      <p>{targetState ? null : `Select "Set up" to activate the service.`}</p>
      <SectionHeading headingContent="Service Tier" headingType="h3">
        <InfoTooltip
          id="service-tier"
          label="Service tier"
          description="The service tier defines the priority and minimum bandwidth of individual services that belong to it."
        />
      </SectionHeading>
      <p style={{ fontSize: 20, marginTop: 0 }}>{formattedTier?.value || "Unknown"}</p>
      <MessageInline
        variant="Information"
        description={
          targetState
            ? "Your service is currently processing and will be available shortly."
            : "You will have the option of choosing a speed tier during setup."
        }
        developmentUrl={ableUrl}
      />
    </>
  );
};
