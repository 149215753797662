import { z } from "zod";

export const recipientFormSchema = z
  .object({
    name: z.string().min(3, "Name must contain at least 3 characters.").max(64),
    mobileNumber: z.string().regex(/^04\d{2}\s\d{3}\s\d{3}$/gm),
    email: z.string().email(),
    confirmEmail: z.string().email(),
    isEmailEnabled: z.boolean(),
    isSmsEnabled: z.boolean(),
  })
  .refine((data) => data.email === data.confirmEmail, {
    path: ["confirmEmail"],
    message: "Emails do not match, please check and try again.",
  });

export type TRecipientFormValues = z.infer<typeof recipientFormSchema>;
