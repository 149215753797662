import { DownloadCSVButton } from "components/ui/Buttons";

import { tooltipFormatter } from "components/Charts/Tooltips/single-sector-utilisation";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { ChartOptions } from "./types";

const chartTitleStyles: Highcharts.CSSObject = {
  fontSize: "14px",
};

const yAxisStyles: Highcharts.CSSObject = {
  fontSize: "16px",
  fontFamily: "Telstra Text Variable",
};

function generateChartOptions(chartOptions: ChartOptions, plotType: string) {
  // Chart button render options that didn't work
  // https://jsfiddle.net/BlackLabel/ka9q62t3/ - Couldn't style the button, button overlapped chart heading on small screens
  // https://jsfiddle.net/BlackLabel/rvd948L6/ - Couldn't style the button.
  // Commented custom button code works for basic html button. Styles can be overridden using styles found in utilisation.scss
  // The tab function will focus the button group instead of the button directly which is NOT accessible.

  const defaultOptions: Highcharts.Options = {
    chart: {
      style: { fontFamily: "Telstra Text Variable" },
      events: {
        exportData: chartOptions.exportData,
        render() {
          this.legend.allItems.forEach((item) => {
            // @ts-ignore
            item.legendItem.label.translate(0, 3);
          });
        },
      },
    },
    credits: { enabled: false },
    title: { text: chartOptions.title.text, align: "left", style: chartTitleStyles },
    lang: { noData: "Select a sector to retrieve data for." },
    plotOptions: { column: { stacking: "normal" } },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter(this) {
        return tooltipFormatter({ context: this, plotType });
      },
    },
    legend: { symbolWidth: 32, symbolHeight: 4, symbolPadding: 10 },
    xAxis: {
      type: "datetime",
      min: chartOptions.xAxis?.min,
      max: chartOptions.xAxis?.max,
      lineWidth: 1,
      lineColor: "#757575",
    },
    yAxis: {
      title: { text: "Throughput Range", style: yAxisStyles },
      lineWidth: 1,
      lineColor: "#757575",
      reversedStacks: false,
      gridLineDashStyle: "Dash",
      gridLineColor: "#707070",
      plotLines: [
        {
          dashStyle: "Dash",
          color: "#000000",
          width: 2,
          zIndex: 4,
          value: chartOptions.yPlotLine,
        },
      ],
    },
    series: chartOptions.series,
    exporting: {
      accessibility: { enabled: true },
      buttons: { contextButton: { enabled: false } },
    },
  };

  return defaultOptions;
}

export default function UtilizationChart({
  plotType,
  chartOptions,
}: {
  plotType: "Downlink" | "Uplink";
  chartOptions: ChartOptions;
}) {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const chart = chartComponentRef.current?.chart;
  const showDownloadButton = chartOptions.series.length;

  const [options, setOptions] = useState<Highcharts.Options>(generateChartOptions(chartOptions, plotType));

  useEffect(() => {
    setOptions(generateChartOptions(chartOptions, plotType));
  }, [chartOptions, plotType]);

  useEffect(() => {
    if (chart) {
      if (chartOptions.loading) {
        chart.showLoading();
      } else chart.hideLoading();
    }
  }, [chart, chartOptions.loading]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {showDownloadButton ? (
          <DownloadCSVButton
            onClick={() =>
              //@ts-ignore
              chart?.downloadCSV()
            }
          />
        ) : null}
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
    </>
  );
}
