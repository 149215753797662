import { Link } from "react-router-dom";
import "./styles.scss";

export const Logo = () => {
  return (
    <Link aria-label="Telstra Dedicated Network" className="logo" to="/">
      <img src="/images/Primary-Blue-RGB.png" alt="Telstra" className="telstra-icon" />
      <div className="tdn-heading" aria-hidden="true">
        Dedicated Networks
      </div>
    </Link>
  );
};
