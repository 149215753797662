import { Tabs } from "components/Tabs";
import { PageHeading } from "components/ui/PageHeading";
import { Helmet } from "react-helmet-async";
import { Outlet, useMatch } from "react-router-dom";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";

export function ThroughputContainer() {
  const matchSectors = useMatch("/network/throughput/sectors");

  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);

  return (
    <div>
      <Helmet>
        <title>Network Throughput - Telstra Dedicated Network</title>
      </Helmet>
      <PageHeading content="Network Monitoring" />
      <Tabs
        defaultSelected={matchSectors ? 1 : 0}
        tabs={[
          {
            label: "Service area",
            to: "service-area",
            content: <Outlet />,
          },
          {
            label: "Sectors",
            to: "sectors",
            content: <Outlet />,
          },
        ]}
      />
    </div>
  );
}
