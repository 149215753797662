import { RadioButton, RadioGroup } from "@able/react";
import { ChangeEvent } from "react";

export const ServiceTierRadioGroup = ({
  state,
  onChange,
}: {
  state: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <RadioGroup variant="Default" groupLabel="Choose a Service Tier:" name="Service tier change">
      <RadioButton
        id="radio-button-high"
        value="high"
        label="High (5 Mbps DL / 5 Mbps UL minimum)"
        checked={state === "high"}
        events={{ onChange }}
      />
      <RadioButton
        id="radio-button-medium"
        value="medium"
        label="Medium (1 Mbps DL / 1 Mbps UL minimum)"
        checked={state === "medium"}
        events={{ onChange }}
      />
      <RadioButton
        id="radio-button-low"
        value="low"
        label="Low (0.5 Mbps DL / 0.5 Mbps UL minimum)"
        checked={state === "low"}
        events={{ onChange }}
      />
      <RadioButton
        id="radio-button-default"
        value="default"
        label="Default (0.2 Mbps DL / best effort UL)"
        checked={state === "default"}
        events={{ onChange }}
      />
    </RadioGroup>
  );
};
