import { ActionButton, Picto, Success104 } from "@able/react";
import { PageHeading } from "components/ui/PageHeading";
import { ableUrl } from "utils/constants";
import { Link } from "react-router-dom";
import styles from "./styles/hybridServiceCreated.module.scss";

type Props = {
  serviceNumber: string;
  tierType: string;
  resetSteps: () => void;
};

function HybridServiceCreated({ serviceNumber, tierType, resetSteps }: Props) {
  return (
    <div className={styles.container}>
      <Picto>
        <Success104 />
      </Picto>
      <PageHeading content={"Hybrid service created"} />
      <p>{`${serviceNumber} has been added as a ${tierType} tier Hybrid service to the list of services.`}</p>
      <ActionButton
        element="Link"
        label="View all services"
        to="/services"
        variant="HighEmphasis"
        developmentUrl={ableUrl}
      />
      <Link className={styles.create_service_link} to={"/services/create"} onClick={resetSteps}>
        Create another Hybrid service
      </Link>
    </div>
  );
}

export default HybridServiceCreated;
