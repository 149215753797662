import { MessageInline, Spacing } from "@able/react";
import { DownloadCSVButton, RoundedAction } from "components/ui/Buttons";

import { tooltipFormatter } from "components/Charts/Tooltips/multi-sector-utilisation";
import { CSSObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useNavigateSearch } from "hooks/useNavigateSearch";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ableUrl } from "utils/constants";
import { MultiSectorUtilisationChart } from "./MultiSectorUtilisationChart";
import { generateMultiBarWithScatterSeries, generatePercentageMultiBarSeries, useSectorMetrics } from "./functions";

import "./page-styles.scss";

const titleStyles: CSSObject = {
  color: "rgb(117, 117, 117)",
  fontSize: "16px",
  fontWeight: "normal",
  letterSpacing: "-0.2px",
  lineHeight: "20px",
};

export function MultiSectorContainer() {
  const navigateSearch = useNavigateSearch();
  const downlinkChartRef = useRef<HighchartsReact.RefObject>(null);
  const uplinkChartRef = useRef<HighchartsReact.RefObject>(null);
  const [downLinkChartType, setDownLinkChartType] = useState<"Mbps" | "%">("Mbps");
  const [upLinkChartType, setUpLinkChartType] = useState<"Mbps" | "%">("Mbps");
  const results = useSectorMetrics(); // Fetch the data.

  // Callback function for when a bar/point is clicked.
  const onBarClick = (target: EventTarget) =>
    //@ts-ignore
    navigateSearch("../single-sector", { sector: target.point.category });

  // Derived state
  const downLinkSeries =
    downLinkChartType === "Mbps"
      ? generateMultiBarWithScatterSeries(results, "dlThroughput")
      : generatePercentageMultiBarSeries(results, "dlThroughput");

  const upLinkSeries =
    upLinkChartType === "Mbps"
      ? generateMultiBarWithScatterSeries(results, "ulThroughput")
      : generatePercentageMultiBarSeries(results, "ulThroughput");

  const throughputExceeded = results.some(({ hasExceededTP }) => hasExceededTP);
  const pending = results.length === 0;

  return (
    <div>
      <Helmet>
        <title>Network Utilisation - Telstra Dedicated Network</title>
      </Helmet>
      <h2>Multiple sectors</h2>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <MessageInline
          variant="Information"
          developmentUrl={ableUrl}
          description={`AAB utilisation of sectors is calculated as a sustained rolling average over 28 days.`}
        />

        {throughputExceeded && (
          <MessageInline
            variant="Warning"
            developmentUrl={ableUrl}
            description={`One or more radio Sectors in your TDN service area are exceeding their
          subscribed AAB capacity, which may impact mobile service operation.
          Contact us for options to increase your AAB subscription rate for affected sectors.`}
          />
        )}
      </div>

      {/* Title section for Downlink with additional buttons. */}
      <Spacing bottom="spacing2x" />

      <div className="flex-container">
        <span>Display in: </span>
        <RoundedAction
          content="Mbps"
          onClick={() => setDownLinkChartType("Mbps")}
          isActive={downLinkChartType === "Mbps"}
        />
        <RoundedAction content="%" onClick={() => setDownLinkChartType("%")} isActive={downLinkChartType === "%"} />
      </div>

      <div className="flex-container">
        <DownloadCSVButton
          onClick={() =>
            //@ts-ignore
            downlinkChartRef.current?.chart.downloadCSV()
          }
        />
      </div>

      {/* Downlink Chart */}
      <MultiSectorUtilisationChart
        isPending={pending}
        chartRef={downlinkChartRef}
        chartOptions={{
          series: downLinkSeries,
          xAxis: {
            title: { text: "Sectors", margin: 12, x: -10, style: titleStyles },
            categories: results.map(({ sectorId }) => sectorId),
          },
          yAxis: {
            title: { text: undefined },
            labels: { format: `{text}${downLinkChartType}` },
          },
          title: "Total daily downlink AAB used and available for use.",
          onBarClick,
          exportData: function (event) {
            event.dataRows.forEach((_row, index, arr) => {
              if (index === 0) return (arr[index] = ["Sector", "Used Mbps", "Subscribed Mbps"]);
              const sectorData = results[index - 1];
              if (sectorData) {
                arr[index][0] = sectorData.sectorId;
                arr[index][2] = sectorData.threshold.toString();
                const lastMertic = sectorData.dlThroughput.at(-1);
                if (lastMertic) arr[index][1] = lastMertic.value.toString();
              }
            });
          },
          tootlipFormatter(this) {
            return tooltipFormatter({
              context: this,
              plotType: "downlink",
              isPercentage: downLinkChartType === "%",
              thresholdArray: results.map(({ threshold }) => threshold),
            });
          },
        }}
      />

      {/* Title section for Uplink with additional buttons. */}
      <Spacing bottom="spacing3x" />

      <div className="flex-container">
        <span>Display in: </span>
        <RoundedAction
          content="Mbps"
          onClick={() => setUpLinkChartType("Mbps")}
          isActive={upLinkChartType === "Mbps"}
        />
        <RoundedAction content="%" onClick={() => setUpLinkChartType("%")} isActive={upLinkChartType === "%"} />
      </div>

      <div style={{ display: "flex" }}>
        <DownloadCSVButton
          onClick={() =>
            //@ts-ignore
            uplinkChartRef.current?.chart.downloadCSV()
          }
        />
      </div>

      {/* Uplink chart */}
      <MultiSectorUtilisationChart
        isPending={pending}
        chartRef={uplinkChartRef}
        chartOptions={{
          series: upLinkSeries,
          xAxis: {
            title: { text: "Sectors", margin: 12, x: -10, style: titleStyles },
            categories: results.map(({ sectorId }) => sectorId),
          },
          yAxis: {
            title: { text: undefined },
            labels: { format: `{text}${upLinkChartType}` },
          },
          title: "Total daily uplink AAB used and available for use.",
          onBarClick,
          exportData: function (event) {
            event.dataRows.forEach((_row, index, arr) => {
              if (index === 0) return (arr[index] = ["Sector", "Used Mbps", "Subscribed Mbps"]);
              const sectorData = results[index - 1];
              if (sectorData) {
                arr[index][0] = sectorData.sectorId;
                arr[index][2] = sectorData.threshold.toString();
                const lastMertic = sectorData.ulThroughput.at(-1);
                if (lastMertic) arr[index][1] = lastMertic.value.toString();
              }
            });
          },
          tootlipFormatter(this) {
            return tooltipFormatter({
              context: this,
              plotType: "uplink",
              isPercentage: upLinkChartType === "%",
              thresholdArray: results.map(({ threshold }) => threshold),
            });
          },
        }}
      />
    </div>
  );
}
