import { tdnLogin } from "services/api/identity-service";
import { callAuthService } from "utils/authService";
import { checkTokens } from "utils/tokens";

export const createInitializeSlice = (set, get) => ({
  loggedIn: false,
  loginError: null,
  initializeApp: async (options) => {
    const callbackPath = "/oauthCallback";

    if (window.location.pathname === callbackPath) {
      // Grab the query parameters from the URL.
      // Use the state param to check for man in the middle attacks.
      const { state, code } = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      const stateCode = sessionStorage.getItem("state");
      const nonceCode = sessionStorage.getItem("nonce");

      // Check the state code received is present and equal to the state code sent.
      if (state && stateCode && state === stateCode) {
        // Login using TDN Identity service.

        return tdnLogin({ code, nonce: nonceCode }, options)
          .then(
            ({ token, refreshToken }) => {
              get().addTokensToStorage(token, refreshToken);
              set((state) => ({ ...state, loggedIn: true, loginError: null }));
            },
            (e) => {
              if (e.code !== "ERR_CANCELED") {
                set((state) => ({ ...state, loggedIn: true, loginError: e }));
              }
            }
          )
          .finally(() => {
            sessionStorage.removeItem("nonce");
            sessionStorage.removeItem("state");
          });
      }
    }

    console.log("Checking for valid tokens.");
    const validTokens = checkTokens();
    if (validTokens) {
      get().setTokenInfo();
      set((state) => ({ ...state, loggedIn: true }));
    } else {
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("refresh_token");
      console.error("Invalid tokens. Acquire new tokens from Auth Service.");
      callAuthService();
    }
  },
});
