import { Icon, Spacing } from "@able/react";
import { Pagination } from "components/Table/Pagination";
import { Table } from "components/Table/Table";
import { TableBody } from "components/Table/TableBody";
import { TableDataCell } from "components/Table/TableDataCell";
import { TableFooter } from "components/Table/TableFooter";
import { TableHeader } from "components/Table/TableHeader";
import { TableHeaderCell } from "components/Table/TableHeaderCell";
import { TableRow } from "components/Table/TableRow";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Subscription } from "types";
import { formatters } from "utils";
import { ableUrl } from "utils/constants";

type TTableContainer = {
  data: Subscription[];
  isLoading: Boolean;
};

type TSortValues = {
  column: keyof TFormattedData | null;
  order: "none" | "ascending" | "descending";
};

type TFormattedData = {
  subId: string;
  name: string;
  serviceNumber: string;
  simId: string;
  description: string;
  serviceTier: string;
  imsi: string;
  status: string;
  subType: string;
};

function IconSelector(state: string) {
  if (state.includes("Pending")) return "InProgress";
  if (state === "Active") return "Success";
  if (state === "Blocked") return "ServiceDown";
  return "DeviceUnknown";
}

// The actual sorting function.
function handleSort(formattedRows: TFormattedData[], sortOptions: TSortValues) {
  const { column, order } = sortOptions;
  if (column) {
    const sorted = [...formattedRows].sort((a: any, b: any) => {
      // This will return either a string value or undefined.
      const valA = a[column]?.toString().toLocaleLowerCase();
      const valB = b[column]?.toString().toLocaleLowerCase();

      if (valA === undefined || valA > valB) return order === "ascending" ? 1 : -1;
      if (valB === undefined || valA < valB) return order === "ascending" ? -1 : 1;
      return 0;
    });

    return sorted;
  }
  return formattedRows;
}

export const TableContainer = ({ data, isLoading }: TTableContainer) => {
  const navigate = useNavigate();
  const [sort, setSort] = useState<TSortValues>({ column: null, order: "none" });
  const [pagination, setPagination] = useState({ currentPage: 1, rowsPerPage: 10 });
  const [formattedRows, setFormattedRows] = useState<TFormattedData[]>([]);

  // Pagination starts at the current page - 1 (normalize array) multiplied by number of rows allowed.
  const sliceStart = (pagination.currentPage - 1) * pagination.rowsPerPage;

  // Pagination end value is either sliceStart + rowsPerPage, or the end of (length of) the array, depends which is lesser.
  const sliceEnd =
    sliceStart + pagination.rowsPerPage < formattedRows.length
      ? sliceStart + pagination.rowsPerPage
      : formattedRows.length;

  const totalPages = Math.ceil(formattedRows.length / pagination.rowsPerPage);

  const tableMessage =
    formattedRows.length > 0
      ? `Showing results ${sliceStart + 1} - ${sliceEnd} of ${formattedRows.length}`
      : "No results to display.";

  /**
   * The data must be formatted whenever sorting is required. This is especially
   * true if the raw data contains nested keys that are required to display.
   * This useEffect will trigger whenever the data changes (refetch, refilter), or
   * whenever the sort properties change. Sorting is performed on FORMATTED DATA!
   */
  useEffect(() => {
    if (!isLoading && data) {
      const formattedRows: TFormattedData[] = data.map((sub) => {
        return {
          subId: sub.id,
          name: sub.name,
          serviceNumber: sub.serviceNumber,
          simId: sub.simId,
          description: sub.description,
          serviceTier: formatters.serviceTier(sub.serviceTier).value,
          imsi: sub.subscriptionId,
          status: formatters.state(sub.state, sub.targetState),
          subType: sub.subscriptionType,
        };
      });

      const sorted = handleSort(formattedRows, sort);
      setFormattedRows(sorted);
      // Reset pagination current Page to 1 to avoid incorrect slicing.
      setPagination((s) => ({ ...s, currentPage: 1 }));
    }
  }, [isLoading, data, sort]);

  // Sets the sorting options, triggers useEffect update.
  function handleSortClick(sortColumn: keyof TFormattedData) {
    setSort({
      column: sortColumn,
      order:
        sort.order === "none" || sort.column !== sortColumn
          ? "ascending"
          : sort.order === "ascending"
            ? "descending"
            : "ascending",
    });
  }

  return (
    <Spacing bottom="spacing10x">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "name" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("name")}
            >
              Your reference
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "serviceNumber" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("serviceNumber")}
            >
              Service Number
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "simId" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("simId")}
            >
              SIM serial
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "description" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("description")}
            >
              Description/location
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "serviceTier" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("serviceTier")}
            >
              Service tier
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "imsi" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("imsi")}
            >
              IMSI
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "status" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("status")}
            >
              Service status
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              sortOrder={sort.column === "subType" ? sort.order : "none"}
              sortingCallback={() => handleSortClick("subType")}
            >
              Service type
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!isLoading &&
            formattedRows.slice(sliceStart, sliceEnd).map((data, idx) => (
              <TableRow key={`row-${idx}`} onClick={() => navigate(`/services/${data.subId}/performance`)}>
                <TableDataCell>{data.name}</TableDataCell>
                <TableDataCell>{data.serviceNumber}</TableDataCell>
                <TableDataCell>
                  <Link style={{ outline: "none" }} to={`/services/${data.subId}/performance`}>
                    {data.simId}
                  </Link>
                </TableDataCell>
                <TableDataCell>{data.description}</TableDataCell>
                <TableDataCell>
                  <>
                    {data.serviceTier === "Processing" ? <Icon icon="InProgress" developmentUrl={ableUrl} /> : null}
                    {data.serviceTier}
                  </>
                </TableDataCell>
                <TableDataCell>{data.imsi}</TableDataCell>
                <TableDataCell>
                  <>
                    <Icon icon={IconSelector(data.status)} developmentUrl={ableUrl} />
                    {data.status}
                  </>
                </TableDataCell>
                <TableDataCell>{data.subType}</TableDataCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TableFooter>
        <p>{tableMessage}</p>
        {totalPages > 1 && (
          <Pagination
            currentPage={pagination.currentPage}
            totalPages={totalPages}
            onPageChange={(page: number) => setPagination((s) => ({ ...s, currentPage: page }))}
          />
        )}
      </TableFooter>
    </Spacing>
  );
};
