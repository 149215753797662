import { useOnRouteChange } from "hooks/useOnRouteChange";
import { useAppStore } from "services/state/store/app-store";
import { useEffect } from "react";
import { AppLayout } from "./AppLayout";
import Loader from "../components/Loader";

export const ProtectedRoutes = () => {
  const initializeApp = useAppStore((state) => state.initializeApp);
  const loginError = useAppStore((state) => state.loginError);
  const loggedIn = useAppStore((state) => state.loggedIn);
  const setAutoFocus = useAppStore((state) => state.setAutoFocus);
  useOnRouteChange(() => setAutoFocus(true));

  useEffect(() => {
    const controller = new AbortController();
    initializeApp({ signal: controller.signal });
    return () => controller.abort();
  }, []);

  if (!loggedIn) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader size={56} />
        <p>Checking credentials...</p>
        {loginError && <p>{loginError.message}</p>}
      </div>
    );
  }

  return <AppLayout />;
};
