import { Breadcrumbs as AbleBreadcrumbs } from "@able/react";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { ableUrl } from "utils/constants";

export const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadCrumbs();

  if (breadcrumbs.length > 0) {
    return (
      <AbleBreadcrumbs
        id="global-breadcrumbs"
        breadcrumbs={breadcrumbs}
        setBackgroundToWhite={true}
        developmentUrl={ableUrl}
      />
    );
  }

  return null;
};
