import axios, { AxiosRequestConfig } from "axios";
import { getTokenFromSessionStorage } from "utils";
import { Subscription } from "types";
import { getBasePath } from "./common";

export async function fetchSubscriptions(tenancyId: string, options?: AxiosRequestConfig) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios({
    method: "GET",
    url: `${basePath}/api/tdn/inventory/v1/subscriptions`,
    params: { tenancyId: tenancyId },
    headers: {
      authorization: `Bearer ${token}`,
    },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

export async function fetchSubscriptionById(subscriptionId: string | undefined | null, options?: AxiosRequestConfig) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios({
    method: "GET",
    url: `${basePath}/api/tdn/inventory/v1/subscriptions/${subscriptionId}`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

export async function fetchInventoryCount(tenancyId: string, options?: AxiosRequestConfig) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios({
    method: "GET",
    url: `${basePath}/api/tdn/inventory/v1/subscriptions/service-tier/count`,
    params: { tenancyId: tenancyId },
    headers: {
      authorization: `Bearer ${token}`,
    },
    ...options,
  });

  const data = response.data?.data;
  return data;
}

type TFieldEntries = Array<TFieldEntry>;

type TFieldEntry = [
  fieldName: string,
  fieldObj: {
    value: string;
    errorMsg?: boolean;
  },
];

export async function patchSubscriptionById(
  subscription: Subscription,
  fieldEntries: TFieldEntries,
  options?: AxiosRequestConfig
) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  let operationsArray = [];

  for (const [fieldName, fieldObj] of fieldEntries) {
    let opObject = {
      op: "add",
      path: "/" + fieldName,
      value: fieldObj.value,
    };
    if (subscription.hasOwnProperty(fieldName)) {
      opObject.op = "replace";
    }
    operationsArray.push(opObject);
  }

  const response = await axios({
    method: "PATCH",
    url: `${basePath}/api/tdn/inventory/v1/subscriptions/${subscription.id}`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: operationsArray,
    ...options,
  });

  const data = response.data?.data;
  return data;
}

export async function activateSubscription(
  subscription: Subscription,
  isServiceAreaRestricted: boolean,
  serviceTier: string,
  options?: AxiosRequestConfig
) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  let body = {
    isServiceAreaRestricted,
    serviceTier,
  };

  let axiosOptions = {
    method: "POST",
    url: `${basePath}/api/tdn/inventory/v1/subscriptions/${subscription.id}/activate`,
    headers: { authorization: `Bearer ${token}` },
    ...options,
    data: body,
  };

  const response = await axios(axiosOptions);

  const data = response.data?.data;
  return data;
}

export async function updateSubscriptionStateById(
  subscription: Subscription,
  eventType: string,
  options?: AxiosRequestConfig
) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  let axiosOptions = {
    method: "PATCH",
    url: `${basePath}/api/tdn/inventory/v1/subscriptions/${subscription.id}/state/${eventType}`,
    headers: { authorization: `Bearer ${token}` },
    ...options,
  };

  if (eventType === "ACTIVATE") {
    axiosOptions.data = [
      {
        op: "add",
        path: "/isServiceAreaRestricted",
        value: "false",
      },
    ];
  }

  const response = await axios(axiosOptions);

  const data = response.data?.data;
  return data;
}

export async function updateServiceTierById(
  subscription: Subscription,
  eventType: string,
  options?: AxiosRequestConfig
) {
  const token = getTokenFromSessionStorage("id_token");
  const basePath = getBasePath();

  const response = await axios({
    method: "PATCH",
    url: `${basePath}/api/tdn/inventory/v1/subscriptions/${subscription.id}/service-tier/${eventType}`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    ...options,
  });

  const data = response.data?.data;
  return data;
}
