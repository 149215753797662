import React, { KeyboardEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Tabs.scss";
import { TTab, TTabList, TTabPanel, TTabs } from "./types";

const TabList = ({ children, className, id = "tablist-id", labelledby = undefined }: TTabList) => {
  return (
    <div role={"tablist"} className={className} id={id} aria-labelledby={labelledby}>
      {children}
    </div>
  );
};

const Tab = ({ label, index, selected, buttonRef, onClick, onKeyDown }: TTab) => {
  return (
    <button
      className="tabbutton"
      role={"tab"}
      type="button"
      id={`tab-${index}`}
      ref={buttonRef}
      tabIndex={selected ? undefined : -1}
      aria-selected={selected ? "true" : "false"}
      aria-controls={`tabpanel-${index}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {label}
    </button>
  );
};

const TabPanel = ({ children, index, showContent }: TTabPanel) => {
  return (
    <div
      role={"tabpanel"}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ display: `${showContent ? null : "none"}` }}
    >
      {children}
    </div>
  );
};

export const Tabs = ({
  tablistClassName = "",
  tabListId = "",
  tablistLabelledby,
  tabs = [],
  defaultSelected = 0,
}: TTabs) => {
  // Javascript here to get the tabs
  const [activeTabIndex, setActiveTabIndex] = useState(defaultSelected);
  const [, setFocusedTabIndex] = useState(defaultSelected);
  const navigate = useNavigate();

  // Create an array of refs so that we can focus each element
  const refArray = tabs.map(() => React.createRef<HTMLButtonElement>());
  const tabCount = refArray.length;

  // Set up key bindings / listeners
  // See CodePen https://www.w3.org/WAI/ARIA/apg/example-index/tabs/tabs-manual.html
  function setFocus(index: number) {
    if (index >= 0 && index <= tabCount - 1) {
      const element = refArray[index];

      if (element.current) {
        element.current.focus();
        setFocusedTabIndex(index);
      }
    }
  }

  function handleKeyDown(event: KeyboardEvent, currentIndex: number) {
    const { key } = event;
    var flag = false;

    switch (key) {
      case "ArrowLeft":
        setFocus(currentIndex - 1);
        flag = true;
        break;

      case "ArrowRight":
        setFocus(currentIndex + 1);
        flag = true;
        break;

      case "Home":
        setFocus(0);
        flag = true;
        break;

      case "End":
        setFocus(tabCount - 1);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  return (
    <div>
      <TabList className={tablistClassName} id={tabListId} labelledby={tablistLabelledby}>
        {tabs.map(({ label, to }, index) => (
          <Tab
            label={label}
            key={index}
            index={index}
            selected={activeTabIndex === index}
            buttonRef={refArray[index]}
            onClick={() => {
              setActiveTabIndex(index);
              if (to) {
                navigate(to);
              }
            }}
            onKeyDown={(e: any) => {
              handleKeyDown(e, index);
            }}
          />
        ))}
      </TabList>
      {tabs.map(({ content }, index) => {
        if (activeTabIndex === index) {
          return (
            <TabPanel index={index} key={index} showContent={activeTabIndex === index}>
              {content}
            </TabPanel>
          );
        } else return null;
      })}
    </div>
  );
};
