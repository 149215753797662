import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchServiceAreaData, fetchTenancyData } from "services/api/account-service";
import { fetchThroughputBySectorID } from "services/api/observability-service";
import { SectorThroughputData, TdnErrorResponse, TKpi } from "types";
import { SectorThroughputFormData as FormData } from "./types";

export const useSectors = () => {
  const { data: serviceArea, isLoading: loadingServiceArea } = useQuery({
    queryKey: ["tenant"],
    queryFn: fetchTenancyData,
    select: (tenantArray) => tenantArray[0]?.serviceArea?.[0]?.serviceAreaId, // Refine to get serviceArea
  });

  const { data: sectors, isLoading: loadingSectors } = useQuery({
    queryKey: ["serviceArea", serviceArea],
    queryFn: () => fetchServiceAreaData(serviceArea),
    select: (serviceAreaData) => serviceAreaData.sectors || [],
    enabled: !!serviceArea,
  });

  const isLoading = loadingServiceArea ?? loadingSectors;

  return { sectors, isLoading };
};

// Fetch all the sectors for a given service area
export const useSectorInfo = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<TKpi[]>([]);

  // Define the query params using the searchParams
  const params = useMemo(
    () => ({
      sectorId: searchParams.get("sectorId") || undefined,
      serviceTier: searchParams.get("serviceTier") || "default",
      period: searchParams.get("period") || "fifteenMin",
      dateTimeFrom: searchParams.get("dateTimeFrom") || dayjs().subtract(1, "minute").subtract(1, "day").toISOString(),
      dateTimeTo: searchParams.get("dateTimeTo") || dayjs().subtract(1, "minute").toISOString(),
    }),
    [searchParams]
  );

  const { mutate, error, isPending, submittedAt } = useMutation<
    SectorThroughputData,
    AxiosError<TdnErrorResponse>,
    FormData & { period: string }
  >({
    mutationFn: (formData) => fetchThroughputBySectorID(formData),
    onSuccess: (data) => setData(data.series),
    onError: () => setData([]),
  });

  const fetchSectorData = useCallback(
    (formData: FormData) => {
      // Calculate the period here
      const dateX = dayjs(formData.dateTimeFrom);
      const dateY = dayjs(formData.dateTimeTo);
      const dateDiff = dateY.diff(dateX, "days", true);

      let period = "fifteenMin";
      if (dateDiff < 1) period = "fifteenMin";
      if (dateDiff > 2) period = "hourly";
      if (dateDiff > 5) period = "daily";
      // if (dateDiff > 8) period = "monthly";

      setSearchParams({ ...formData, period });
      mutate({ ...formData, period });
    },
    [mutate, setSearchParams]
  );

  useEffect(() => {
    if (!data.length && params.sectorId) {
      // @ts-ignore current.sectorId is being treated as undefined despite the check?
      fetchSectorData(params);
    }
  }, [data.length, params.sectorId, fetchSectorData, params]);

  const lastFetched = submittedAt ? dayjs(submittedAt).format("MMMM D YYYY HH:mm a") : undefined;

  // Fetch the first service area from the tenant
  return { data, error, isPending, params, fetchSectorData, lastFetched };
};
