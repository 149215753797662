import { PropsWithChildren } from "react";
import "./TableStyles.scss";

type TTableProps = {} & PropsWithChildren;

export const Table = ({ children }: TTableProps) => {
  return (
    <div className="table-container">
      <table>{children}</table>
    </div>
  );
};
