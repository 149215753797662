import { Icon, MessageInline } from "@able/react";
import { activateSubscription } from "services/api/inventory-service";
import { HyperLinkButton } from "components/ui/Buttons";

import { InfoTooltip } from "components/InfoTooltip";
import { ableUrl } from "utils/constants";
import { formatters, getProperty, isAuthorised } from "utils";
import { BaseSyntheticEvent, useRef, useState } from "react";
import { Subscription } from "types";
import { ServiceTierRadioGroup } from "../ServiceTierRadioGroup";
import "./StandardActivation.scss";
import { queryClient } from "index";

type TServiceActivationProps = {
  subscription: Subscription;
};

export const StandardActivation = ({ subscription }: TServiceActivationProps) => {
  const focusTargetRef = useRef<HTMLHeadingElement | null>(null);
  const [setUpSelected, setSetUpSelected] = useState(false);
  const [selectedServiceTier, setSelectedServiceTier] = useState(subscription.serviceTier.value);

  const { state, targetState, serviceTier } = subscription;

  const activationEnabled = getProperty("ACTIVATION_FEATURE_ENABLED", false);
  const authorised = isAuthorised("ADMIN");
  const formattedState = formatters.state(state, targetState);
  const formattedServiceTier = formatters.serviceTier(serviceTier);
  const serviceTierProcessing = serviceTier?.isTransitioning;
  const isInactive = state === "pendingActivation" && targetState === "";
  const isProcessingActivation = state === "pendingActivation" && targetState === "active";

  // Update the state of the subscription
  function handleActivationRequest() {
    activateSubscription(subscription, false, selectedServiceTier)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["subscriptions"] });

        if (focusTargetRef.current) {
          focusTargetRef.current.focus();
        }
      })
      .catch((e) => console.error(e));
  }

  return (
    <div className="service-activation-container">
      {/* HEADING - Service Status */}
      <div className="title-tooltip-container service-status-heading">
        <h3 ref={focusTargetRef} tabIndex={-1} style={{ outlineColor: "transparent" }}>
          Service status
        </h3>
        <InfoTooltip
          id="service-status-information"
          label="Service Status Information"
          description="You must activate this service before it can be used."
        />
      </div>

      {/* BUTTON GROUPING */}
      {isInactive ? (
        <div className="service-activation-button-group">
          {setUpSelected ? (
            <>
              <HyperLinkButton content="Cancel" onClick={() => setSetUpSelected(false)} />
              <HyperLinkButton content="Activate" onClick={() => handleActivationRequest()} />
            </>
          ) : (
            <HyperLinkButton
              content="Set up"
              disabled={!activationEnabled || !authorised}
              onClick={() => setSetUpSelected(true)}
            />
          )}
        </div>
      ) : null}

      {/* CONTENT - Service Status */}
      <div className="service-status-info">
        {isProcessingActivation && <Icon icon="InProgress" size="24" developmentUrl={ableUrl} />}
        <span className="visually-hidden">The service status is currently,</span>
        <p>{formattedState}</p>
      </div>

      {isInactive ? (
        setUpSelected ? (
          <MessageInline
            variant="Information"
            description="You are about to activate this service. Changes may take up to 24 hours to complete."
            developmentUrl={ableUrl}
          />
        ) : (
          <p>Select "Set up" to activate the service.</p>
        )
      ) : null}

      {isProcessingActivation && (
        <MessageInline
          variant="Attention"
          description="You've requested to activate this service. Changes will be available within 24 hours.
                        Please contact your Telstra representative for more information."
          developmentUrl={ableUrl}
        />
      )}

      {/* END SECTION - Service Status */}
      {/* HEADING - Service Tier */}
      <div className="title-tooltip-container">
        <h3>Add-on service tier</h3>
        <InfoTooltip
          id="service-tier-information"
          label="Add-on service tier Information"
          description="The service tier defines the priority and minimum bandwidth of individual services that belong to it."
        />
      </div>

      {/* CONTENT - Service Tier */}
      <div className="service-status-info">
        {serviceTierProcessing && <Icon icon="InProgress" size="24" developmentUrl={ableUrl} />}
        {isInactive ? (
          <>
            <span className="visually-hidden">The service tier is currently,</span>
            <p>Unassigned</p>
            <br />
          </>
        ) : (
          <>
            <span className="visually-hidden">The service tier is currently,</span>
            <p>{formattedServiceTier.value}</p>
            <p>{formattedServiceTier.unit}</p>
            <br />
          </>
        )}
      </div>

      {isInactive ? (
        setUpSelected ? (
          <>
            <MessageInline
              variant="Warning"
              description="Changing the tier speed may reduce minimum bandwidth of
                            all devices on the same tier if the tier is approaching maximum capacity"
              developmentUrl={ableUrl}
            />
            <div>
              <p style={{ display: "inline-block", paddingRight: "1em" }}>DL = downlink</p>
              <p style={{ display: "inline-block" }}>UL = uplink</p>
              <br />
            </div>
            <ServiceTierRadioGroup
              state={selectedServiceTier}
              onChange={(e: BaseSyntheticEvent) => setSelectedServiceTier(e.target.value)}
            />
          </>
        ) : (
          <>
            <MessageInline
              variant="Information"
              description="You will have the option of choosing a speed tier during setup."
              developmentUrl={ableUrl}
            />
          </>
        )
      ) : null}

      {/* END SECTION - Service Tier */}
    </div>
  );
};
