import { Column, Grid, MessageInline, Select, Spacing } from "@able/react";
import { Chart } from "components/Charts/Chart";
import { NativeDatePicker } from "components/DatePicker/NativeDatePicker";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { MinMaxInfo } from "components/MinMaxInfo";
import { ChangeEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { ableUrl, serviceTierSelectOptions } from "utils/constants";
import { timeRangeToReadable } from "utils/formatters/date-formatters";
import { formatUnits } from "utils/formatters/network-units";
import { tooltipFormatter } from "../tooltip-formatter";
import { useServiceAreaHistory } from "./functions";
import "./ServiceAreaThroughput.scss";
import { ServiceAreaHistoryFormData as FormData } from "./types";

function calculateAnalytics(data: number[]) {
  const max = data.reduce((a, b) => Math.max(a, b), -Infinity);
  const average = data.reduce((a, b) => a + b, 0) / data.length;

  return { max, average };
}

export const ServiceAreaThroughput = () => {
  const { data, error, fetchData, params, isPending, lastFetched } = useServiceAreaHistory();
  const form = useForm<FormData>({ defaultValues: params });

  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);

  // Derived state
  const duration = data && data?.series.length >= 1 && timeRangeToReadable(params.dateTimeFrom, params.dateTimeTo);
  const period = params.period === "fifteenMin" ? "15 min" : params.period;

  return (
    <main>
      <Spacing top="spacing3x" bottom="spacing3x">
        <MessageInline
          variant="Information"
          developmentUrl={ableUrl}
          description="Performance analytics include the activity of this site and may not reflect the actual network capability."
        />
      </Spacing>

      <form>
        <Controller
          name="serviceTier"
          control={form.control}
          render={({ field: { onChange, ...rest } }) => (
            <Select
              id="service-tier-select"
              events={{ onChange: (e: ChangeEvent<HTMLSelectElement>) => onChange(e) }}
              helpText="Filters  charts results by Service Tier."
              label="Select Service Tier"
              size="Default"
              allowEmpty={false}
              developmentUrl={ableUrl}
              options={serviceTierSelectOptions}
              {...rest}
            />
          )}
        />
        <Spacing bottom="spacing3x" />
        <NativeDatePicker
          callback={(data) => {
            form.setValue("dateTimeFrom", data.dateTimeFrom);
            form.setValue("dateTimeTo", data.dateTimeTo);
            fetchData(form.getValues());
          }}
        />
      </form>

      <Spacing bottom="spacing3x" />
      <ErrorMessage error={error} />

      {data && data.series.length > 0 ? ( // Check data object isn't undefined, and some data is present.
        <>
          <h2>Service Area</h2>
          <Grid>
            <Column cols={3} vmd={4} vsm={12} vxs={12}>
              <h3>Average Downlink Throughput</h3>
              <p>Last fetched at {lastFetched}</p>
              <MinMaxInfo
                data={calculateAnalytics(data.series.map(({ throughput }) => throughput.downLink))}
                units={data.series[0].throughput.units || "kbps"}
                descriptionAvg={`Average downlink throughput in a ${period} period over the past ${duration}`}
                descriptionMax={`Highest downlink throughput in a ${period} period over the past ${duration}`}
              />
            </Column>
            <Column cols={9} vmd={8} vsm={12} vxs={12}>
              <Chart
                chartOptions={{
                  title: { text: undefined, align: "left" },
                  paraTitle: `Average downlink throughput in a ${period} period over the past ${duration}`,
                  isLoading: isPending,
                  series: [
                    {
                      type: "column",
                      name: "Downlink throughput",
                      color: "#0364d2",
                      legendSymbol: "lineMarker",
                      pointWidth: 8,
                      data: data.series.map(({ throughput, timestamp }) => [
                        Date.parse(timestamp),
                        Number(throughput.downLink.toFixed(2)),
                      ]),
                      events: { legendItemClick: (e) => e.preventDefault() },
                    },
                  ],
                  xAxis: {
                    type: "datetime",
                    min: new Date(params.dateTimeFrom).getTime(),
                    max: new Date(params.dateTimeTo).getTime(),
                  },
                  yAxis: {
                    title: { text: "Throughput" },
                    lineWidth: 1,
                    lineColor: "#757575",
                    gridLineDashStyle: "Dash",
                    gridLineColor: "#707070",
                    labels: {
                      formatter(ctx) {
                        return formatUnits(ctx.value, data.series[0].throughput.units);
                      },
                    },
                  },
                  tooltip: {
                    useHTML: true,
                    formatter(this) {
                      return tooltipFormatter({ ctx: this, units: data.series[0].throughput.units });
                    },
                  },
                  legend: { symbolWidth: 24 },
                }}
              />
            </Column>
          </Grid>
          <Grid>
            <Column cols={3} vmd={4} vsm={12} vxs={12}>
              <h3>Average Downlink Volume</h3>
              <p>Last fetched at {lastFetched}</p>
              <MinMaxInfo
                data={calculateAnalytics(data.series.map(({ volume }) => volume.downLink))}
                units={data.series[0].volume.units || "bytes"}
                descriptionAvg={`Average downlink volume in a ${period} period over the past ${duration}`}
                descriptionMax={`Highest downlink volume in a ${period} period over the past ${duration}`}
              />
            </Column>
            <Column cols={9} vmd={8} vsm={12} vxs={12}>
              <Chart
                chartOptions={{
                  title: { text: undefined, align: "left" },
                  paraTitle: `Average downlink volume in a ${period} period over the past ${duration}`,
                  isLoading: isPending,
                  series: [
                    {
                      type: "column",
                      name: "Downlink volume",
                      color: "#0364d2",
                      legendSymbol: "lineMarker",
                      pointWidth: 8,
                      data: data.series.map(({ volume, timestamp }) => [
                        Date.parse(timestamp),
                        Number(volume.downLink.toFixed(2)), // KiloBytes
                      ]),
                      events: { legendItemClick: (e) => e.preventDefault() },
                    },
                  ],
                  xAxis: {
                    type: "datetime",
                    min: new Date(params.dateTimeFrom).getTime(),
                    max: new Date(params.dateTimeTo).getTime(),
                  },
                  yAxis: {
                    title: { text: "Volume" },
                    lineWidth: 1,
                    lineColor: "#757575",
                    gridLineDashStyle: "Dash",
                    gridLineColor: "#707070",
                    labels: {
                      formatter(ctx) {
                        return formatUnits(ctx.value, data.series[0].volume.units);
                      },
                    },
                  },
                  tooltip: {
                    useHTML: true,
                    formatter(this) {
                      return tooltipFormatter({ ctx: this, units: data.series[0].volume.units });
                    },
                  },
                  legend: { symbolWidth: 24 },
                }}
              />
            </Column>
          </Grid>
          <Spacing bottom="spacing4x" top="spacing4x" />
        </>
      ) : (
        <>
          {data && data.series.length === 0 && (
            <MessageInline
              variant="Information"
              description="No data available for this selection."
              developmentUrl={ableUrl}
            />
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>{isPending ? "Loading data" : "Submit a query to view Sector Data"}</p>
          </div>
        </>
      )}
    </main>
  );
};
