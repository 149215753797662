import React, { PropsWithChildren } from "react";

export type TSectionHeading = {
  headingContent: string;
  headingProps?: React.HTMLAttributes<HTMLHeadingElement>;
  headingType?: "h2" | "h3" | "h4";
  headingRef?: React.RefObject<any>;
} & PropsWithChildren;

export function SectionHeading({
  headingType = "h2",
  headingProps = {},
  headingContent = "Replace me",
  headingRef,
  children,
}: TSectionHeading) {
  return (
    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
      {React.createElement(headingType, { ...headingProps, ref: headingRef }, headingContent)}
      {children}
    </div>
  );
}
