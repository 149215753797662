import { Column, Grid, MessageInline, Spacing } from "@able/react";
import { PageHeading } from "components/ui/PageHeading";
import "./Dashboard.scss";
import CustomIllustration from "./Illustration";
import { ServiceTierCounts } from "./ServiceTierCounts";

function Dashboard({
  isLoading,
  error,
  tenancyData,
  firstName,
  micaData,
}: {
  isLoading: boolean;
  error: any;
  tenancyData: any;
  firstName: string;
  micaData: any;
}) {
  function getGreeting() {
    let curHr = new Date().getHours();
    if (curHr < 12) return "Morning, " + firstName;
    if (curHr < 18) return "Afternoon, " + firstName;
    if (curHr < 23) return "Evening, " + firstName;
    else return "Hello, " + firstName;
  }

  // Sometimes the account api will return an empty array for the user.
  // TODO: check if the API should be returning a blank object or an error code.
  if (!isLoading && !tenancyData?.[0]) {
    return (
      <MessageInline
        variant="Error"
        description="Fetching Tenancy Data resulted in an error."
        developmentUrl="/assets/ables-sprites.svg"
      />
    );
  }

  return (
    <Spacing top="spacing7x">
      <Grid>
        <Column cols={6}>
          <PageHeading content={getGreeting()} />
          {isLoading && (
            <div>
              <p className="animated-background"></p>
              <p className="animated-background"></p>
              <p className="animated-background"></p>
            </div>
          )}
          {!isLoading && (
            <div>
              {tenancyData?.[0] ? (
                <>
                  <p className="para-light">{tenancyData[0].customerName}</p>
                  <p className="para-light">{tenancyData[0].tenancyName}</p>
                  <p className="para-light">{tenancyData[0].serviceArea[0].serviceAreaName}</p>
                </>
              ) : null}
              {micaData ? <p className="para-bold">Customer Reference: TDN-{micaData.data.micaAccountNumber}</p> : null}
              <p className="para-useful-link">Useful links</p>
            </div>
          )}
          {error && (
            <MessageInline
              variant="Error"
              description="Fetching Tenancy Data resulted in an error."
              developmentUrl="/assets/ables-sprites.svg"
            />
          )}
        </Column>
        <Column cols={6}>
          <CustomIllustration />
        </Column>
        <Column className="grid-item--vxs-padding-bottom" cols={3} vxs={12} vsm={6} vmd={4}>
          <ServiceTierCounts />
        </Column>
      </Grid>
    </Spacing>
  );
}

export default Dashboard;
