import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { ChartOptions, MultiSectorUtilisationChartProps } from "./types";

const chartTitleStyles: Highcharts.CSSObject = {
  fontSize: "14px",
};

function generateChartOptions(chartOptions: ChartOptions) {
  const defaultOptions: Highcharts.Options = {
    chart: {
      style: { fontFamily: "Telstra Text Variable" },
      events: {
        exportData: chartOptions.exportData,
        render: function () {
          this.legend.allItems.forEach((item) => {
            // @ts-ignore
            item.legendItem.label.translate(0, 3);
          });

          // @ts-ignore
          this.seriesGroup.on("click", (e: Highcharts.PointClickEventObject) =>
            // timeout is used to make sure the chart doesn't unmount too quickly.
            setTimeout(() => {
              if (e.target && chartOptions.onBarClick) chartOptions.onBarClick(e.target);
            }, 100)
          );
        },
      },
    },
    legend: {
      symbolWidth: 32,
      symbolHeight: 2,
      symbolRadius: 0,
      squareSymbol: false,
      symbolPadding: 12,
    },
    credits: { enabled: false },
    plotOptions: { series: { stacking: "normal" }, bar: { cursor: "pointer" } },
    title: { text: chartOptions.title, align: "left", style: chartTitleStyles },
    tooltip: { shared: true, useHTML: true, formatter: chartOptions.tootlipFormatter || undefined },
    yAxis: { reversedStacks: false, ...chartOptions.yAxis },
    xAxis: { ...chartOptions.xAxis },
    lang: { noData: "No data" },
    series: chartOptions.series,
  };

  return defaultOptions;
}

export function MultiSectorUtilisationChart({ chartOptions, isPending, chartRef }: MultiSectorUtilisationChartProps) {
  const [options, setOptions] = useState<Highcharts.Options>(generateChartOptions(chartOptions));

  useEffect(() => {
    if (chartRef.current) {
      if (isPending) {
        chartRef.current.chart.showLoading();
      } else chartRef.current.chart.hideLoading();

      setOptions(generateChartOptions(chartOptions));
    }
  }, [chartOptions, isPending, chartRef]);

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
}
